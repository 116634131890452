import { useCategoriesContext } from "@pages/settings/categories/categories-context";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs.tsx";
import { useTranslation } from "react-i18next";
import { CategoryType } from "../../../../../../api-contracts/categories";
import { useProfileContext } from "@context/profile-context.tsx";
import { TabNavbar } from "@components/tab-navbar.tsx";
import { useEffect, useState } from "react";

export const CategoryTypeTabs = () => {
  const { setCategoryTypes } = useCategoriesContext();
  const { t } = useTranslation();
  const { module } = useProfileContext();

  type TabItems = {
    value: CategoryType[];
    label: string;
    disabled: boolean;
  };

  const roomrTabItems: TabItems[] = [
    { value: ["room"], label: t("hotel"), disabled: false },
    { value: ["area"], label: t("surface-area"), disabled: false },
    { value: ["bed", "dormitory"], label: t("hostel"), disabled: false },
  ];

  const camprTabItems: TabItems[] = [
    { value: ["tent"], label: t("tent"), disabled: false },
    { value: ["motorhome"], label: t("motor-home"), disabled: false },
    { value: ["caravan"], label: t("caravan"), disabled: false },
    { value: ["cabin"], label: t("cabin"), disabled: false },
    { value: ["glamping"], label: t("glampingtent"), disabled: false },
    { value: ["area"], label: t("area"), disabled: false },
    { value: ["misc"], label: t("misc"), disabled: false },
  ];

  const handleValueChange = (value: string) => {
    const newValue = value.split("&") as CategoryType[];
    setCategoryTypes(newValue);
  };

  const tabItems = module === "roomr" ? roomrTabItems : camprTabItems;
  const [selectedTab, setSelectedTab] = useState(
    module === "roomr" ? "room" : "tent",
  );

  useEffect(() => {
    const value = module === "roomr" ? "room" : "tent";
    setSelectedTab(value);
    handleValueChange(value);
  }, [module]);

  return (
    <>
      <TabNavbar
        onValueChange={(value) => {
          setSelectedTab(value);
          handleValueChange(value);
        }}
        value={selectedTab}
        items={tabItems}
      />
    </>
  );
};
