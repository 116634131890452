import { createMutation, createQuery } from "react-query-kit";
import {
  DeleteMessageOfTheDayRequest,
  GetOccupantsResponse,
  GetReceptionArrivalsResponse,
  GetReceptionDeparturesResponse,
  MultipleCheckinRequest,
  MultipleCheckinResponse,
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse,
} from "../../../../api-contracts/reception";
import { api } from "./api";
import { currentSite } from "@context/site-context";

export const ARRIVALS_QUERY_KEY = "reception/arrivals";
export const MOTD_QUERY_KEY = "reception/motd";
export const DEPARTURES_QUERY_KEY = "reception/departures";
export const OCCUPANTS_QUERY_KEY = "reception/occupants";

// -- ARRIVALS --
// GET /api/:siteId/reception/arrivals/:date
export const useGetArrivalsByDate = createQuery<
  GetReceptionArrivalsResponse,
  { date: string; type: string | string[] | undefined }
>({
  queryFn: async ({ queryKey: [, { date, type }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    return api.get(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}`,
    );
  },
  primaryKey: ARRIVALS_QUERY_KEY,
});

// POST /api/:siteId/reception/arrivals/check-in/:bookingId
export const usePostCheckInByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/check-in/${bookingId}`,
      {},
    );
  },
});

// POST /api/:siteId/reception/arrivals/check-in
export const usePostMultipleCheckInByBookingId = createMutation<
  MultipleCheckinResponse,
  MultipleCheckinRequest
>({
  mutationFn: async (bookingIds: MultipleCheckinRequest) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/check-in`,
      bookingIds,
    );
  },
});

// POST /api/:siteId/reception/arrivals/undo-check-in/:bookingId
export const usePostUndoCheckInByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/undo-check-in/${bookingId}`,
      {},
    );
  },
});

// -- DEPARTURES --
// GET /api/:siteId/reception/departures/:date
export const useGetDeparturesByDate = createQuery<
  GetReceptionDeparturesResponse,
  { date: string; type: string | string[] | undefined }
>({
  queryFn: async ({ queryKey: [, { date, type }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    return api.get(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}`,
    );
  },
  primaryKey: DEPARTURES_QUERY_KEY,
});

// POST /api/:siteId/reception/departures/check-out/:bookingId
export const usePostCheckOutByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/check-out/${bookingId}`,
      {},
    );
  },
});

// -- MESSAGE OF THE DAY --
// PUT /api/:siteId/reception/motd
export const usePutMotd = createMutation<
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse
>({
  mutationFn: async ({ message, date }) =>
    api.put<SaveMessageOfTheDayResponse>(
      `/api/${currentSite?.id}/${MOTD_QUERY_KEY}`,
      {
        message,
        date,
      },
    ),
});

// DELETE /api/:siteId/reception/motd
export const useDeleteMotd = createMutation<
  DeleteMessageOfTheDayRequest,
  DeleteMessageOfTheDayRequest
>({
  mutationFn: async ({ message, date }) =>
    api.delete(`/api/${currentSite?.id}/${MOTD_QUERY_KEY}/&${message}&${date}`), // TODO Change to only send date as query param
});

// -- OCCUPANTS --
// GET /api/:siteId/reception/occupants/:date
export const useGetOccupantsByDate = createQuery<
  GetOccupantsResponse,
  { date: string; type: string | string[] | undefined }
>({
  queryFn: async ({ queryKey: [, { date, type }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    return api.get(
      `/api/${currentSite?.id}/${OCCUPANTS_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}`,
    );
  },
  primaryKey: OCCUPANTS_QUERY_KEY,
});
