import { useGetCategoryGroups } from "@api/groups";
import { TabNavbar } from "@components/tab-navbar";
import { Button } from "@primitives/button";
import { Checkbox } from "@primitives/checkbox";
import DatePicker from "@primitives/date-picker";
import { Popover } from "@primitives/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import {
  AlignVerticalSpaceAround,
  ArrowRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
  ChevronUp,
  Ellipsis,
  Filter,
  Info,
  Link,
  Minus,
  MoreHorizontal,
  X,
} from "lucide-react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "@primitives/search-bar";
import { cn } from "@shared/utils/css";
import { Collapsible } from "@primitives/collapsible";
import { enGB, sv } from "date-fns/locale";
import {
  addDays,
  differenceInCalendarDays,
  format,
  isBefore,
  isSameDay,
  isWithinInterval,
} from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import {
  BookableStatus,
  GetCategoryGridBookable,
  GetCategoryGridBookableDateEntry,
  GetCategoryGridCategory,
  GetCategoryGridDateEntry,
  PaymentStatus,
} from "../../../../../api-contracts/category-grid";
import { useGetCategoryGrid } from "@api/category-grid";
import { Loading } from "@primitives/loading";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Input } from "@primitives/input";
import { DateRangePicker } from "@primitives/date-range-picker";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { TimePicker } from "@primitives/time-picker";
import { Label } from "@primitives/label";
import { Textarea } from "@primitives/textarea";
import { useBlockBookable, useGetAllocableBookables } from "@api/bookables";
import { queryClient } from "query-client";
import { useToast } from "@hooks/use-toast";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import {
  useCancelReservation,
  useCreateReservation,
  usePatchReservation,
} from "@api/reservations";
import { combine, getDaysBetween } from "@shared/utils/helpers";
import { CategoryGroup } from "../../../../../api-contracts/groups";
import {
  AgeType,
  GetReservation,
} from "../../../../../api-contracts/reservations";
import { ConfirmDialog } from "@components/confirm-dialog";
import { useGetMultiplePricing } from "@api/pricing";

type DurationOption = {
  label: string;
  size: number;
  sizeType: "day" | "month";
};

const durationOptions: DurationOption[] = [
  {
    label: "4-weeks",
    size: 28,
    sizeType: "day",
  },
  {
    label: "3-weeks",
    size: 21,
    sizeType: "day",
  },
  {
    label: "2-weeks",
    size: 14,
    sizeType: "day",
  },
  {
    label: "1-week",
    size: 7,
    sizeType: "day",
  },
  {
    label: "whole-month",
    size: 1,
    sizeType: "month",
  },
];

type CategoriesGroup = {
  id: string;
  name: string;
  categories: GetCategoryGridCategory[];
};

export const bookableStatusToColor = (status?: BookableStatus) => {
  switch (status) {
    case "ongoing":
      return "booking-ongoing";
    case "preliminary":
      return "booking-tentative";
    case "unconfirmed":
      return "booking-unconfirmed";
    case "confirmed":
      return "booking-confirmed";
    case "guaranteed":
      return "booking-guaranteed";
    case "checked-in":
      return "booking-checked-in";
    case "checked-out":
      return "booking-checked-out";
    case "no-show":
      return "status-error";
    case "booked-as-other":
      return "booking-other-category";
    case "out-of-order":
      return "out-of-order";
    case "out-of-service":
      return "out-of-service";
    default:
      return "status-disabled";
  }
};

const paymentStatusToColor = (status?: PaymentStatus) => {
  switch (status) {
    case "partly-paid":
      return "status-warning";
    case "paid":
      return "status-success";
    case "unpaid":
      return "status-error";
    default:
      return undefined;
  }
};

interface Props {
  tab: "hotel" | "area" | "hostel";
}

const CategoryGridPage = ({ tab }: Props) => {
  const [t, i18n] = useTranslation();
  const { module } = useProfileContext();
  const { toast } = useToast();
  const [durationIndex, setDurationIndex] = useState<string>("0");
  const duration = durationOptions[Number(durationIndex)];
  const [from, setFrom] = useState<Date>(new Date());
  const [tableSize, setTableSize] = useState<"small" | "medium" | "large">(
    (localStorage.getItem("category-grid-spacing") as
      | "small"
      | "medium"
      | "large") || "large",
  );
  const [newBookingOpen, setNewBookingOpen] = useState<boolean>(false);
  const [informationOpen, setInformationOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [groupsFilterOpen, setGroupsFilterOpen] = useState<boolean>(false);
  const [cancelReservationOpen, setCancelReservationOpen] =
    useState<boolean>(false);
  const [numGuestsDetailsOpen, setNumGuestsDetailsOpen] =
    useState<boolean>(false);
  let initExpandedIds = [];
  try {
    initExpandedIds = JSON.parse(
      localStorage.getItem(`category-grid-expanded-ids-${tab}`) || "[]",
    );
  } catch (err) {}
  const [expandedIds, setExpandedIds] = useState<string[]>(initExpandedIds);
  const [showBar, setShowBar] = useState<boolean>(
    localStorage.getItem("category-grid-show-bar") === "true",
  );
  const [groupBy, setGroupBy] = useState<CategoryGroup[]>([]);
  const [searchGroups, setSearchGroups] = useState<string>("");
  const [focusedCategory, setFocusedCategory] = useState<{
    category: GetCategoryGridCategory;
    entries: GetCategoryGridDateEntry[];
    numberOfRooms: number;
    startDate: Date;
    endDate: Date;
  }>();
  const [selectedCategories, setSelectedCategories] = useState<
    {
      category: GetCategoryGridCategory;
      entries: GetCategoryGridDateEntry[];
      numberOfRooms: number;
      startDate: Date;
      endDate: Date;
    }[]
  >([]);
  const [focusedBookable, setFocusedBookable] = useState<{
    category: GetCategoryGridCategory;
    bookable: GetCategoryGridBookable;
    entries: GetCategoryGridBookableDateEntry[];
    startDate: Date;
    endDate: Date;
  }>();
  const [selectedBookables, setSelectedBookables] = useState<
    {
      category: GetCategoryGridCategory;
      bookable?: GetCategoryGridBookable;
      entries: GetCategoryGridBookableDateEntry[];
      startDate: Date;
      endDate: Date;
      bookableId?: string;
      adults?: number;
      children?: {
        age?: number;
        bedPreferenceId?: string;
      }[];
      bookingId?: string;
    }[]
  >([]);
  const [hoveredBookingId, setHoveredBookingId] = useState<string>();
  const [reservation, setReservation] = useState<GetReservation>();
  const [continueClicked, setContinueClicked] = useState<boolean>(false);
  const [bookableToBlock, setBookableToBlock] =
    useState<GetCategoryGridBookable>();
  const [blockStartDate, setBlockStartDate] = useState<Date>();
  const [blockEndDate, setBlockEndDate] = useState<Date>();
  const [blockType, setBlockType] = useState<
    "out-of-service" | "out-of-order"
  >();
  const [blockReason, setBlockReason] = useState<string>("");
  const [blockLoading, setBlockLoading] = useState<boolean>(false);
  const [today] = useState(new Date(new Date().toDateString()));
  const [updateReservationLoading, setUpdateReservationLoading] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const to = useMemo(() => {
    const date = new Date(from);
    if (duration.sizeType === "day") {
      date.setDate(date.getDate() + duration.size - 1);
    } else {
      date.setMonth(date.getMonth() + duration.size);
      date.setDate(0);
    }
    return date;
  }, [from, duration]);

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetMultiplePricing({
    variables: {
      checks: selectedBookables.map((a) => {
        const dates = getDaysBetween(a.startDate, a.endDate);
        dates.pop();

        return {
          slots: dates.map((d) => ({
            startDate: format(d, "yyyy-MM-dd"),
            startTime: a.category.startTimes?.[0] || "14:00:00",
          })),
          adults: a.adults,
          teenagers: a.children
            ? a.children.filter((c) => c.age !== undefined && c.age >= 13)
                .length || undefined
            : undefined,
          children: a.children
            ? a.children.filter(
                (c) => c.age === undefined || (c.age > 2 && c.age < 13),
              ).length || undefined
            : undefined,
          infants: a.children
            ? a.children.filter((c) => c.age !== undefined && c.age <= 2)
                .length || undefined
            : undefined,
          categoryId: a.category.id,
        };
      }),
    },
    enabled: selectedBookables.length > 0,
  });

  const {
    data: categoryGrid,
    isLoading: categoryGridLoading,
    isRefetching: categoryGridRefetching,
  } = useGetCategoryGrid({
    variables: {
      startDate: format(from, "yyyy-MM-dd"),
      endDate: format(to, "yyyy-MM-dd"),
      types:
        tab === "hostel"
          ? ["bed", "dormitory"]
          : tab === "hotel"
            ? ["room"]
            : [tab],
    },
  });

  const groupedData: CategoriesGroup[] = useMemo(() => {
    let groups: CategoriesGroup[] = [];
    if (groupBy.length) {
      const groupMap = combine([...groupBy], 1).sort(
        (g1, g2) => g2.length - g1.length,
      );
      groups = groupMap.map((grouping, i) => ({
        name: grouping.map((g) => g.name).join(", "),
        id: String(i),
        categories: categoryGrid?.categories?.filter((c) =>
          grouping.every((g) => !!c.groups?.find((g1) => g1.id == g.id)),
        ) as any,
      }));
    }
    return groups;
  }, [categoryGrid, groupBy]);

  const blockBookable = useBlockBookable();
  const handleCreateReservation = useCreateReservation();
  const handleUpdateReservation = usePatchReservation();
  const handleCancelReservation = useCancelReservation();

  useEffect(() => {
    let initExpandedIds = [];
    try {
      initExpandedIds = JSON.parse(
        localStorage.getItem(`category-grid-expanded-ids-${tab}`) || "[]",
      );
    } catch (err) {}
    setExpandedIds(initExpandedIds);
  }, [tab]);

  const createReservation = async () => {
    if (!reservation) {
      setUpdateReservationLoading(true);
      try {
        const response = await handleCreateReservation.mutateAsync({
          bookings: selectedBookables.map((b) => {
            const dates = getDaysBetween(b.startDate, b.endDate);
            dates.pop();
            const numChildren = b.children
              ? b.children.filter(
                  (c) => c.age === undefined || (c.age > 2 && c.age < 13),
                ).length || undefined
              : undefined;
            const numInfants = b.children
              ? b.children.filter((c) => c.age !== undefined && c.age <= 2)
                  .length || undefined
              : undefined;
            const numTeenagers = b.children
              ? b.children.filter((c) => c.age !== undefined && c.age >= 13)
                  .length || undefined
              : undefined;
            return {
              guests: [
                ...Array.from({ length: b.adults || 0 }, (_, i) => ({
                  ageType: "adult" as AgeType,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                })),
                ...Array.from({ length: numChildren || 0 }, (_, i) => ({
                  ageType: "child" as AgeType,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                })),
                ...Array.from({ length: numTeenagers || 0 }, (_, i) => ({
                  ageType: "teenager" as AgeType,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                })),
                ...Array.from({ length: numInfants || 0 }, (_, i) => ({
                  ageType: "infant" as AgeType,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                })),
              ],
              slots: dates.map((d) => ({
                startDate: format(d, "yyyy-MM-dd"),
                startTime: b.category.startTimes[0] || "14:00:00",
                priceOverrideAmount: null,
                priceAdjustmentPercent: null,
              })),
              categoryId: b.category.id,
              bookableId: b.bookableId || b.bookable?.id || null,
              numAdults: b.adults,
              numChildren,
              numInfants,
              numTeenagers,
            };
          }),
        });
        setReservation(response);
        response.bookings.forEach((r, i) => {
          if (selectedBookables[i]) {
            selectedBookables[i].bookingId = r.id;
            selectedBookables[i].bookableId = r.bookable?.id;
          }
        });
        setSelectedBookables([...selectedBookables]);
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGrid.getKey(),
        });
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
      setUpdateReservationLoading(false);
    }
  };

  const updateReservation = async () => {
    if (reservation) {
      setUpdateReservationLoading(true);
      try {
        const response = await handleUpdateReservation.mutateAsync({
          id: reservation.id,
          patch: {
            bookings: selectedBookables.map((b) => {
              const dates = getDaysBetween(b.startDate, b.endDate);
              dates.pop();
              const numChildren = b.children
                ? b.children.filter(
                    (c) => c.age === undefined || (c.age > 2 && c.age < 13),
                  ).length || undefined
                : undefined;
              const numInfants = b.children
                ? b.children.filter((c) => c.age !== undefined && c.age <= 2)
                    .length || undefined
                : undefined;
              const numTeenagers = b.children
                ? b.children.filter((c) => c.age !== undefined && c.age >= 13)
                    .length || undefined
                : undefined;
              return {
                id: b.bookingId,
                guests: [
                  ...Array.from({ length: b.adults || 0 }, (_, i) => ({
                    ageType: "adult" as AgeType,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                  })),
                  ...Array.from({ length: numChildren || 0 }, (_, i) => ({
                    ageType: "child" as AgeType,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                  })),
                  ...Array.from({ length: numTeenagers || 0 }, (_, i) => ({
                    ageType: "teenager" as AgeType,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                  })),
                  ...Array.from({ length: numInfants || 0 }, (_, i) => ({
                    ageType: "infant" as AgeType,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                  })),
                ],
                slots: dates.map((d) => ({
                  startDate: format(d, "yyyy-MM-dd"),
                  startTime: b.category.startTimes[0] || "14:00:00",
                  priceOverrideAmount: null,
                  priceAdjustmentPercent: null,
                })),
                categoryId: b.category.id,
                bookableId: b.bookableId || b.bookable?.id || null,
                numAdults: b.adults,
                numChildren: b.children
                  ? b.children.filter(
                      (c) => c.age === undefined || (c.age > 2 && c.age < 13),
                    ).length || undefined
                  : undefined,
                numInfants: b.children
                  ? b.children.filter((c) => c.age !== undefined && c.age <= 2)
                      .length || undefined
                  : undefined,
                numTeenagers: b.children
                  ? b.children.filter((c) => c.age !== undefined && c.age >= 13)
                      .length || undefined
                  : undefined,
              };
            }),
          },
        });
        setReservation(response);
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGrid.getKey(),
        });
        navigate(`/${module}/reservations/${response.id}`);
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
      setUpdateReservationLoading(false);
    }
  };

  useEffect(() => {
    if (newBookingOpen && selectedBookables.length && continueClicked) {
      createReservation();
    }

    if (!newBookingOpen) {
      setContinueClicked(false);
      setReservation(undefined);
    }
  }, [newBookingOpen, selectedBookables, continueClicked]);

  useEffect(() => {
    if (
      newBookingOpen &&
      selectedBookables.length &&
      !selectedCategories.length
    ) {
      setContinueClicked(true);
    }
  }, [newBookingOpen, selectedCategories, selectedBookables]);

  useEffect(() => {
    localStorage.setItem("category-grid-spacing", tableSize);
  }, [tableSize]);

  useEffect(() => {
    localStorage.setItem("category-grid-show-bar", String(showBar));
  }, [showBar]);

  useEffect(() => {
    localStorage.setItem(
      `category-grid-expanded-ids-${tab}`,
      JSON.stringify(expandedIds),
    );
  }, [expandedIds]);

  useEffect(() => {
    if (duration.sizeType === "month") {
      const date = new Date(from);
      date.setDate(1);
      setFrom(date);
    }
  }, [duration]);

  const totalPrice = useMemo(() => {
    let price = 0;
    pricing?.prices.forEach((p) => {
      p.slots.forEach((slot) => {
        price += slot.price;
      });
    });
    return price;
  }, [pricing]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      if (selectedCategories.length || selectedBookables.length) {
        setNewBookingOpen(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyDown);

    return () => {
      window.removeEventListener("keyup", handleKeyDown);
    };
  }, [selectedCategories, selectedBookables]);

  const handleMouseDown = (e: MouseEvent) => {
    setFocusedCategory(undefined);
    if (!(e.ctrlKey || e.metaKey)) {
      if (selectedCategories.length || selectedBookables.length) {
        setNewBookingOpen(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseDown);

    return () => {
      window.removeEventListener("mouseup", handleMouseDown);
    };
  }, [selectedCategories, selectedBookables]);

  const {
    data: categoryGroups,
    error: categoryGroupsError,
    isLoading: categoryGroupsIsLoading,
  } = useGetCategoryGroups({});

  const filteredGroups = useMemo(() => {
    if (searchGroups) {
      return categoryGroups?.filter((g) =>
        g.name.toLocaleLowerCase().includes(searchGroups.toLocaleLowerCase()),
      );
    } else {
      return categoryGroups;
    }
  }, [categoryGroups, searchGroups]);

  const goToCurrent = () => {
    if (duration.sizeType === "day") {
      setFrom(new Date());
    } else {
      const date = new Date();
      date.setDate(1);
      setFrom(date);
    }
  };

  const onBlockBookable = async () => {
    if (bookableToBlock && blockStartDate && blockEndDate && blockType) {
      try {
        setBlockLoading(true);
        await blockBookable.mutateAsync({
          startDateTime: format(blockStartDate, "yyyy-MM-dd HH:mm:ss"),
          endDateTime: format(blockEndDate, "yyyy-MM-dd HH:mm:ss"),
          blockType: blockType,
          reason: blockReason ? blockReason : undefined,
          id: bookableToBlock?.id,
          type: "bookable",
        });
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGrid.getKey(),
        });
        toast({
          variant: "success",
          title: t("saved-succesfully", { name: bookableToBlock.name }),
          className: "text-status-success",
        });
        setBlockLoading(false);
        setBookableToBlock(undefined);
        setBlockStartDate(undefined);
        setBlockEndDate(undefined);
        setBlockType(undefined);
        setBlockReason("");
      } catch (err) {
        setBlockLoading(false);
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
    }
  };

  const onContinue = async () => {
    if (!continueClicked) {
      selectedCategories.forEach((c) => {
        for (let i = 0; i < c.numberOfRooms; i++) {
          selectedBookables.push({
            category: c.category,
            entries: c.entries.map((e) => ({
              date: e.date,
              price: e.minPrice,
            })),
            startDate: c.startDate,
            endDate: c.endDate,
            adults: c.category.capacity.adults.min || 1,
          });
        }
      });
      setContinueClicked(true);
    } else if (reservation) {
      await updateReservation();
    }
  };

  const cancelReservation = async () => {
    if (reservation) {
      try {
        await handleCancelReservation.mutateAsync({
          id: reservation.id,
          sendConfirmation: false,
        });
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGrid.getKey(),
        });
        setNewBookingOpen(false);
        setSelectedBookables([]);
        setSelectedCategories([]);
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
    }
  };

  return (
    <>
      <div className="p-4 text-primary-text">
        <TabNavbar
          value={tab}
          items={[
            {
              value: "hotel",
              label: t("hotel"),
              to: `/${module}/${ROUTES.CATEGORY_GRID_HOTEL}`,
            },
            {
              value: "area",
              label: t("area"),
              to: `/${module}/${ROUTES.CATEGORY_GRID_AREA}`,
            },
            {
              value: "hostel",
              label: t("hostel"),
              to: `/${module}/${ROUTES.CATEGORY_GRID_HOSTEL}`,
            },
          ]}
        />

        <div className=" mt-4 rounded-lg border border-main-border-color bg-primary-card-backplate">
          <div className="flex justify-between p-4">
            <h1 className=" text-2xl font-bold">{t("category-grid")}</h1>
            <RouterLink
              to={`/${module}/${ROUTES.UNALLOCATED}/${format(from, "yyyy-MM-dd")}/${format(to, "yyyy-MM-dd")}`}
            >
              <Button variant="primary">
                <ArrowRight size={18} className=" mr-2" />
                {t("bookings-without-allocated-units")}
              </Button>
            </RouterLink>
          </div>

          <div className=" my-4 flex items-center overflow-x-auto px-4">
            <Popover.Root
              open={informationOpen}
              onOpenChange={setInformationOpen}
            >
              <Popover.Trigger asChild>
                <Button variant="secondary">
                  <Info size={18} />
                </Button>
              </Popover.Trigger>
              <Popover.Content
                className="w-auto min-w-[250px] p-0 shadow-xl"
                align="start"
              >
                <div className=" flex items-center justify-between p-4">
                  <h1 className=" text-base font-bold">{t("information")}</h1>
                  <X
                    size={24}
                    className=" cursor-pointer"
                    onClick={() => setInformationOpen(false)}
                  />
                </div>
                <div className=" border-t border-highlighted-backplate p-4">
                  <h3 className=" mb-1 text-xs font-extrabold text-secondary-text">
                    {t("statuses")}
                  </h3>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-ongoing opacity-80" />
                    <p className=" text-xs font-medium">{t("ongoing")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-tentative opacity-80" />
                    <p className=" text-xs font-medium">{t("preliminary")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-unconfirmed opacity-80" />
                    <p className=" text-xs font-medium">{t("unconfirmed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-confirmed opacity-80" />
                    <p className=" text-xs font-medium">{t("confirmed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-guaranteed opacity-80" />
                    <p className=" text-xs font-medium">{t("guaranteed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-other-category opacity-80" />
                    <p className=" text-xs font-medium">
                      {t("booked-as-another-category")}
                    </p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-checked-in opacity-80" />
                    <p className=" text-xs font-medium">{t("checked-in")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-checked-out opacity-80" />
                    <p className=" text-xs font-medium">{t("checked-out")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-status-error opacity-80" />
                    <p className=" text-xs font-medium">{t("no-show")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-out-of-order opacity-80" />
                    <p className=" text-xs font-medium">{t("out-of-order")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-out-of-service opacity-80" />
                    <p className=" text-xs font-medium">
                      {t("out-of-service")}
                    </p>
                  </div>
                  <h3 className=" my-1 text-xs font-extrabold text-secondary-text">
                    {t("other")}
                  </h3>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-accent-12-500" />
                    <p className=" text-xs font-medium">{t("inactive")}</p>
                  </div>
                </div>
              </Popover.Content>
            </Popover.Root>
            <div className=" flex flex-1 items-center justify-center">
              <div className=" flex items-center space-x-2">
                <Select value={durationIndex} onValueChange={setDurationIndex}>
                  <SelectTrigger className="min-w-[170px] text-xs">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {durationOptions.map((d, i) => (
                      <SelectItem
                        key={i}
                        value={String(i)}
                        className=" text-xs"
                      >
                        {t(d.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <div className=" min-w-[200px]">
                  <DatePicker
                    value={from}
                    clearable={false}
                    onValueChange={(date) => {
                      if (date) {
                        if (duration.sizeType === "month") {
                          date.setDate(1);
                        }
                        setFrom(date);
                      }
                    }}
                    calendarProps={{
                      required: true,
                      disabled:
                        duration.sizeType === "month"
                          ? (day: Date) => day.getDate() !== 1
                          : false,
                    }}
                  />
                </div>
                <div>
                  <Minus size={18} />
                </div>
                <div className=" min-w-[200px]">
                  <DatePicker
                    value={to}
                    clearable={false}
                    buttonProps={{ disabled: true }}
                    calendarProps={{ required: true }}
                  />
                </div>
                <Button variant="secondary" onClick={goToCurrent}>
                  {duration.sizeType === "day"
                    ? t("skip-to-today")
                    : t("skip-to-current-month")}
                </Button>
                <div className=" flex flex-nowrap space-x-1">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      const date = new Date(from);
                      if (duration.sizeType === "day") {
                        date.setDate(date.getDate() - duration.size);
                      } else {
                        date.setMonth(date.getMonth() - duration.size);
                      }
                      setFrom(date);
                    }}
                  >
                    <ChevronsLeft size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={duration.sizeType === "month"}
                    onClick={() => {
                      const date = new Date(from);
                      date.setDate(date.getDate() - 1);
                      setFrom(date);
                    }}
                  >
                    <ChevronLeft size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={duration.sizeType === "month"}
                    onClick={() => {
                      const date = new Date(from);
                      date.setDate(date.getDate() + 1);
                      setFrom(date);
                    }}
                  >
                    <ChevronRight size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      const date = new Date(from);
                      if (duration.sizeType === "day") {
                        date.setDate(date.getDate() + duration.size);
                      } else {
                        date.setMonth(date.getMonth() + duration.size);
                      }
                      setFrom(date);
                    }}
                  >
                    <ChevronsRight size={18} />
                  </Button>
                </div>
              </div>
            </div>
            <div className=" flex items-center space-x-2">
              <Button
                variant="secondary"
                onClick={() => {
                  switch (tableSize) {
                    case "large":
                      setTableSize("medium");
                      break;
                    case "medium":
                      setTableSize("small");
                      break;
                    case "small":
                      setTableSize("large");
                      break;
                  }
                }}
              >
                <AlignVerticalSpaceAround size={18} />
              </Button>
              <Popover.Root open={filterOpen} onOpenChange={setFilterOpen}>
                <Popover.Trigger asChild>
                  <Button variant="secondary">
                    <Filter size={18} className="mr-2" />
                    {t("show")}
                  </Button>
                </Popover.Trigger>
                <Popover.Content
                  className="w-auto min-w-[250px] p-0 shadow-xl"
                  align="end"
                >
                  <div className=" flex items-center space-x-2 border-b border-highlighted-backplate p-3">
                    <Checkbox
                      checked={showBar}
                      onCheckedChange={(v) => setShowBar(v as boolean)}
                      id="show-bar"
                    />
                    <label
                      className=" cursor-pointer text-sm font-extrabold"
                      htmlFor="show-bar"
                    >
                      {t("show-bar")}
                    </label>
                  </div>
                  <Popover.Root
                    open={groupsFilterOpen}
                    onOpenChange={setGroupsFilterOpen}
                  >
                    <Popover.Trigger asChild>
                      <div
                        className={cn(
                          " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                          {
                            "bg-status-disabled-100": groupsFilterOpen,
                          },
                        )}
                      >
                        <p className=" text-sm font-extrabold">
                          {t("grouping")}
                        </p>
                        <ChevronRight size={16} />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content
                      className="w-auto min-w-[250px] p-0 shadow-xl"
                      align="start"
                      side="left"
                    >
                      <SearchBar
                        className=" mb-1"
                        placeholder={t("search-groups")}
                        value={searchGroups}
                        onChange={(e) => setSearchGroups(e.target.value)}
                      />
                      <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                        <>
                          {filteredGroups?.length === 0 && (
                            <div className="flex items-center justify-center p-3">
                              <p>{t("no-groups-found")}</p>
                            </div>
                          )}
                          {filteredGroups?.map((g) => (
                            <div
                              key={g.id}
                              className=" flex items-center space-x-2 px-2 py-1"
                            >
                              <Checkbox
                                checked={!!groupBy.find((g1) => g.id === g1.id)}
                                onCheckedChange={(v) => {
                                  if (v) {
                                    setGroupBy([...groupBy, g]);
                                  } else {
                                    setGroupBy(
                                      groupBy.filter((g1) => g1.id !== g.id),
                                    );
                                  }
                                }}
                                id={g.id}
                              />
                              <label
                                className=" cursor-pointer text-sm font-medium"
                                htmlFor={g.id}
                              >
                                {g.name}
                              </label>
                            </div>
                          ))}
                        </>
                      </div>
                      <div
                        className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                        onClick={() =>
                          filteredGroups && setGroupBy([...filteredGroups])
                        }
                      >
                        <p className="text-sm font-extrabold">
                          {t("select-all")}
                        </p>
                      </div>
                    </Popover.Content>
                  </Popover.Root>
                  <div
                    className="flex cursor-pointer items-center justify-center space-x-2 p-3"
                    onClick={() => {
                      setGroupBy([]);
                      setShowBar(false);
                      setFilterOpen(false);
                    }}
                  >
                    <p className="text-sm font-extrabold">{t("reset")}</p>
                  </div>
                </Popover.Content>
              </Popover.Root>
            </div>
          </div>

          {categoryGridLoading || categoryGridRefetching ? (
            <div className=" relative h-[600px] w-full">
              <Loading />
            </div>
          ) : (
            <div className=" relative w-full overflow-x-auto pt-4">
              <div className=" w-fit min-w-full">
                <div className=" flex h-[50px] w-full flex-nowrap">
                  <div
                    className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-b border-r border-t border-highlighted-backplate px-2 text-sm font-normal"
                    onClick={() => {
                      if (
                        expandedIds.length === categoryGrid?.categories.length
                      ) {
                        setExpandedIds([]);
                      } else {
                        setExpandedIds(
                          categoryGrid?.categories.map((c) => c.id) || [],
                        );
                      }
                    }}
                  >
                    {t("category")}
                    <ChevronsUpDown size={16} />
                  </div>

                  {categoryGrid?.meta.map((meta, index) => {
                    const date = new Date(meta.date);
                    return (
                      <div
                        key={meta.date}
                        className={cn(
                          "flex min-w-[50px] flex-1 flex-col items-center justify-center border-b border-t border-highlighted-backplate",
                          {
                            "bg-amber-500-50": isSameDay(today, date),
                            "border-r": index !== categoryGrid?.meta.length - 1,
                          },
                        )}
                      >
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                            {
                              "opacity-40": date < today,
                            },
                          )}
                        >
                          {format(date, "d")}
                        </p>
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-xs font-normal text-secondary-text",
                            {
                              "opacity-40": date < today,
                            },
                          )}
                        >
                          {format(date, "EEE", {
                            locale: i18n.language === "sv-se" ? sv : enGB,
                          })}
                        </p>
                      </div>
                    );
                  })}
                </div>

                {groupedData.length ? (
                  <>
                    {groupedData.map((g) => (
                      <React.Fragment key={g.id}>
                        <div
                          className={cn(
                            " w-full bg-secondary-card-backplate p-2",
                            {
                              "py-1": tableSize === "small",
                              "border-b border-highlighted-backplate":
                                g.categories.length,
                              "mt-2": tableSize === "large",
                            },
                          )}
                        >
                          <p
                            className={cn(" text-sm font-extrabold", {
                              "text-xs": tableSize === "small",
                            })}
                          >
                            {t("grouping")}: {g.name}
                          </p>
                        </div>
                        <Categories
                          data={g.categories}
                          expandedIds={expandedIds}
                          tableSize={tableSize}
                          showBar={showBar}
                          selectedCategories={selectedCategories}
                          focusedCategory={focusedCategory}
                          today={today}
                          selectedBookables={selectedBookables}
                          hoveredBookingId={hoveredBookingId}
                          focusedBookable={focusedBookable}
                          setExpandedIds={setExpandedIds}
                          setFocusedCategory={setFocusedCategory}
                          setSelectedCategories={setSelectedCategories}
                          setNewBookingOpen={setNewBookingOpen}
                          setBookableToBlock={setBookableToBlock}
                          setHoveredBookingId={setHoveredBookingId}
                          setFocusedBookable={setFocusedBookable}
                          setSelectedBookables={setSelectedBookables}
                        />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <Categories
                    data={categoryGrid?.categories}
                    expandedIds={expandedIds}
                    tableSize={tableSize}
                    showBar={showBar}
                    selectedCategories={selectedCategories}
                    focusedCategory={focusedCategory}
                    today={today}
                    selectedBookables={selectedBookables}
                    hoveredBookingId={hoveredBookingId}
                    focusedBookable={focusedBookable}
                    setExpandedIds={setExpandedIds}
                    setFocusedCategory={setFocusedCategory}
                    setSelectedCategories={setSelectedCategories}
                    setNewBookingOpen={setNewBookingOpen}
                    setBookableToBlock={setBookableToBlock}
                    setHoveredBookingId={setHoveredBookingId}
                    setFocusedBookable={setFocusedBookable}
                    setSelectedBookables={setSelectedBookables}
                  />
                )}

                <Collapsible.Root
                  open={numGuestsDetailsOpen}
                  onOpenChange={setNumGuestsDetailsOpen}
                >
                  <div
                    className={cn(" flex h-[40px] w-full flex-nowrap", {
                      "h-[25px]": tableSize === "small",
                    })}
                  >
                    <Collapsible.Trigger>
                      <div
                        className={cn(
                          " flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                          {
                            "text-xs": tableSize === "small",
                          },
                        )}
                      >
                        {t("number-of-guests")}
                        {numGuestsDetailsOpen ? (
                          <ChevronUp size={18} />
                        ) : (
                          <ChevronDown size={18} />
                        )}
                      </div>
                    </Collapsible.Trigger>

                    {categoryGrid?.meta.map((meta, index) => {
                      const date = new Date(meta.date);
                      return (
                        <div
                          key={meta.date}
                          className={cn(
                            "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                            {
                              "bg-primary-card-backplate": date.getDay() === 0,
                              "bg-amber-500-50": isSameDay(today, date),
                              "border-r":
                                index !== categoryGrid?.meta.length - 1,
                            },
                          )}
                        >
                          <p
                            className={cn(
                              "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                              {
                                "opacity-40": date < today,
                                "text-xs": tableSize === "small",
                              },
                            )}
                          >
                            {meta.numGuests}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <Collapsible.Content className=" w-full">
                    <div
                      className={cn(" flex h-[100px] w-full flex-nowrap", {
                        "h-[75px]": tableSize === "small",
                      })}
                    >
                      <div
                        className={cn(
                          " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                          {
                            "text-xs": tableSize === "small",
                          },
                        )}
                      >
                        {t("guest-distribution")}
                      </div>

                      {categoryGrid?.meta.map((meta, index) => {
                        const date = new Date(meta.date);
                        return (
                          <div
                            key={meta.date}
                            className={cn(
                              "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                              {
                                "bg-primary-card-backplate":
                                  date.getDay() === 0,
                                "bg-amber-500-50": isSameDay(today, date),
                                "border-r":
                                  index !== categoryGrid?.meta.length - 1,
                              },
                            )}
                          >
                            <div
                              className={cn(
                                "flex flex-col text-center text-sm font-medium",
                                {
                                  "opacity-40": date < today,
                                  "text-xs": tableSize === "small",
                                },
                              )}
                            >
                              <p>
                                {meta.numAdults} ({t("adults-short")})
                              </p>
                              <p>
                                {meta.numTeenagers} ({t("teenagers-short")})
                              </p>
                              <p>
                                {meta.numChildren} ({t("children-short")})
                              </p>
                              <p>
                                {meta.numInfants} ({t("infants-short")})
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Collapsible.Content>
                </Collapsible.Root>

                <div
                  className={cn(" flex h-[40px] w-full flex-nowrap", {
                    "h-[25px]": tableSize === "small",
                  })}
                >
                  <div
                    className={cn(
                      " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                      {
                        "text-xs": tableSize === "small",
                      },
                    )}
                  >
                    {t("booked-rooms")}
                  </div>

                  {categoryGrid?.meta.map((meta, index) => {
                    const date = new Date(meta.date);
                    return (
                      <div
                        key={meta.date}
                        className={cn(
                          "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                          {
                            "bg-primary-card-backplate": date.getDay() === 0,
                            "bg-amber-500-50": isSameDay(today, date),
                            "border-r": index !== categoryGrid?.meta.length - 1,
                          },
                        )}
                      >
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                            {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                            },
                          )}
                        >
                          {meta.bookedAssets}
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={cn(" flex h-[40px] w-full flex-nowrap", {
                    "h-[25px]": tableSize === "small",
                  })}
                >
                  <div
                    className={cn(
                      " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                      {
                        "text-xs": tableSize === "small",
                      },
                    )}
                  >
                    {t("vacant-rooms")}
                  </div>

                  {categoryGrid?.meta.map((meta, index) => {
                    const date = new Date(meta.date);
                    return (
                      <div
                        key={meta.date}
                        className={cn(
                          "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                          {
                            "bg-primary-card-backplate": date.getDay() === 0,
                            "bg-amber-500-50": isSameDay(today, date),
                            "border-r": index !== categoryGrid?.meta.length - 1,
                          },
                        )}
                      >
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                            {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                            },
                          )}
                        >
                          {meta.vacantAssets}
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={cn(" flex h-[40px] w-full flex-nowrap", {
                    "h-[25px]": tableSize === "small",
                  })}
                >
                  <div
                    className={cn(
                      " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                      {
                        "text-xs": tableSize === "small",
                      },
                    )}
                  >
                    {t("occupancy")}
                  </div>

                  {categoryGrid?.meta.map((meta, index) => {
                    const date = new Date(meta.date);
                    return (
                      <div
                        key={meta.date}
                        className={cn(
                          "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                          {
                            "bg-primary-card-backplate": date.getDay() === 0,
                            "bg-amber-500-50": isSameDay(today, date),
                            "border-r": index !== categoryGrid?.meta.length - 1,
                          },
                        )}
                      >
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                            {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                            },
                          )}
                        >
                          {Math.round(meta.occupancy * 100)}%
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Sheet
        open={newBookingOpen}
        onOpenChange={(open) => {
          if (!open) {
            if (reservation) {
              setCancelReservationOpen(true);
            } else {
              setNewBookingOpen(open);
              setSelectedBookables([]);
              setSelectedCategories([]);
            }
          } else {
            setNewBookingOpen(open);
          }
        }}
      >
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {reservation
                ? `${t("booking")} ${reservation.id}`
                : t("do-you-want-to-start-a-booking?")}
              {reservation && (
                <div className=" rounded bg-booking-ongoing-100 p-2 text-xs font-extrabold text-booking-ongoing">
                  {t("ongoing")}
                </div>
              )}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex flex-1 flex-col overflow-y-auto bg-secondary-card-backplate p-4 text-primary-text">
            {!continueClicked &&
              selectedCategories.map(
                (
                  { category, entries, numberOfRooms, startDate, endDate },
                  index,
                ) => (
                  <div
                    key={index}
                    className=" mt-4 bg-primary-card-backplate p-4"
                  >
                    <div className=" flex justify-between">
                      <p className=" text-base font-bold">
                        {t("chosen-category")}: {category.name}
                      </p>
                      <X
                        size={24}
                        className=" cursor-pointer"
                        onClick={() => {
                          selectedCategories.splice(index, 1);
                          setSelectedCategories([...selectedCategories]);
                        }}
                      />
                    </div>

                    <div className=" mt-4">
                      <p className=" mb-1 text-xs font-medium">
                        {t("arrival-and-departure")}
                      </p>
                      <DateRangePicker
                        initialDateFrom={startDate}
                        initialDateTo={endDate}
                        min={2}
                        onUpdate={({ range }) => {
                          const end = range.to || addDays(range.from, 1);
                          const newEntries = category.entries.filter((e) =>
                            isWithinInterval(new Date(e.date), {
                              start: range.from,
                              end: end,
                            }),
                          );
                          selectedCategories[index].startDate = range.from;
                          selectedCategories[index].endDate = end;
                          selectedCategories[index].entries = newEntries;
                          setSelectedCategories([...selectedCategories]);
                        }}
                      />
                    </div>

                    <div className=" mt-4">
                      <p className=" mb-1 text-xs font-medium">
                        {t("number-of-rooms")}
                      </p>
                      <Input
                        type="number"
                        value={numberOfRooms}
                        onChange={(e) => {
                          const s = e.target.value;
                          let n = Number(s);
                          if (isNaN(n) || n < 0) {
                            setSelectedCategories([...selectedCategories]);
                          } else {
                            selectedCategories[index].numberOfRooms = Math.min(
                              Math.floor(n),
                              99,
                            );
                            setSelectedCategories([...selectedCategories]);
                          }
                        }}
                      />
                    </div>
                  </div>
                ),
              )}
            {selectedBookables.map((b, index) => {
              const {
                category,
                bookable,
                startDate,
                endDate,
                adults,
                children,
              } = b;
              return (
                <div
                  key={index}
                  className=" mt-4 bg-primary-card-backplate p-4"
                >
                  <div className=" flex justify-between">
                    {b.bookable && (
                      <div>
                        <p className=" text-sm font-bold">
                          {t("chosen-room")}: {bookable?.name}
                        </p>
                        <p className=" text-xs font-normal text-secondary-text">
                          {t("category")}: {category.name}
                        </p>
                      </div>
                    )}
                    {!b.bookable && (
                      <div>
                        <p className=" text-sm font-bold">
                          {t("room")} {index + 1}
                        </p>
                        <p className=" text-xs font-normal text-secondary-text">
                          {t("category")}: {category.name}
                        </p>
                      </div>
                    )}
                    <Ellipsis size={18} className=" cursor-pointer" />
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">
                      {t("arrival-and-departure")}
                    </p>
                    <div className=" rounded-lg border border-border-color px-4 py-1 text-sm font-extrabold">
                      {format(startDate, "yyyy-MM-dd")} -{" "}
                      {format(endDate, "yyyy-MM-dd")} (
                      {getDaysBetween(startDate, endDate).length - 1}{" "}
                      {getDaysBetween(startDate, endDate).length - 1 === 1
                        ? t("night")
                        : t("nights")}
                      )
                    </div>
                  </div>

                  {!b.bookable && (
                    <div className=" mt-4">
                      <p className=" mb-1 text-xs font-medium">
                        {t("assign-unit")}
                        <AssignableBookables
                          bookingId={b.bookingId}
                          categoryId={category.id}
                          startDate={startDate}
                          endDate={endDate}
                          value={b.bookableId}
                          onValueChange={(id) => {
                            b.bookableId = id;
                            setSelectedBookables([...selectedBookables]);
                          }}
                        />
                      </p>
                    </div>
                  )}

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">{t("adults")}</p>
                    <Select
                      disabled={!continueClicked}
                      value={adults !== undefined ? String(adults) : undefined}
                      onValueChange={(v) => {
                        b.adults = Number(v);
                        setSelectedBookables([...selectedBookables]);
                      }}
                    >
                      <SelectTrigger className="min-w-[170px] text-xs">
                        <SelectValue placeholder={t("adults")} />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from(
                          { length: category.capacity.adults.max + 1 },
                          (v, k) => k,
                        )
                          .filter((v) => v >= category.capacity.adults.min)
                          .map((v) => (
                            <SelectItem
                              key={v}
                              value={String(v)}
                              className=" text-xs"
                            >
                              {String(v)}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">{t("children")}</p>
                    <Select
                      disabled={!continueClicked}
                      value={children && String(children?.length)}
                      onValueChange={(v) => {
                        b.children = Array.from(
                          { length: Number(v) },
                          (c, k) => k,
                        ).map((c) => ({
                          age: undefined,
                          bedPreference: undefined,
                        }));
                        setSelectedBookables([...selectedBookables]);
                      }}
                    >
                      <SelectTrigger className="min-w-[170px] text-xs">
                        <SelectValue placeholder={t("children")} />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from(
                          {
                            length:
                              category.capacity.children.max +
                              category.capacity.infants.max +
                              category.capacity.teenagers.max +
                              1,
                          },
                          (v, k) => k,
                        ).map((v) => (
                          <SelectItem
                            key={v}
                            value={String(v)}
                            className=" text-xs"
                          >
                            {String(v)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  {children && children?.length > 0 && (
                    <div className=" mt-2 rounded-md border border-highlighted-backplate p-2">
                      {children?.map((child, i) => (
                        <div key={i} className=" mt-2">
                          <p className=" mb-1 text-xs font-medium">
                            {t("child")} {i}
                          </p>
                          <Select
                            disabled={!continueClicked}
                            value={
                              child.age !== undefined
                                ? String(child?.age)
                                : undefined
                            }
                            onValueChange={(v) => {
                              child.age = Number(v);
                              setSelectedBookables([...selectedBookables]);
                            }}
                          >
                            <SelectTrigger className="min-w-[170px] text-xs">
                              <SelectValue placeholder={t("age")} />
                            </SelectTrigger>
                            <SelectContent>
                              {Array.from({ length: 18 }, (v, k) => k).map(
                                (v) => (
                                  <SelectItem
                                    key={v}
                                    value={String(v)}
                                    className=" text-xs"
                                  >
                                    {v}
                                  </SelectItem>
                                ),
                              )}
                            </SelectContent>
                          </Select>
                          <div className="mt-2">
                            <Select disabled={!continueClicked}>
                              <SelectTrigger className="min-w-[170px] text-xs">
                                <SelectValue
                                  placeholder={t("bed-preference")}
                                />
                              </SelectTrigger>
                              <SelectContent></SelectContent>
                            </Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className=" mt-[-16px] flex flex-col items-end bg-secondary-card-backplate p-4">
            <p className=" text-xs font-medium">{t("total")}</p>
            <p className=" text-sm font-extrabold text-secondary-text">
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(totalPrice)}{" "}
              SEK
            </p>
          </div>

          <div className=" flex justify-end space-x-2 ">
            <Button
              variant="outline"
              onClick={() => {
                if (reservation) {
                  setCancelReservationOpen(true);
                } else {
                  setNewBookingOpen(false);
                  setSelectedBookables([]);
                  setSelectedCategories([]);
                }
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              autoFocus
              disabled={
                updateReservationLoading ||
                (!selectedCategories.length && !selectedBookables.length) ||
                (continueClicked && !reservation)
              }
              loading={updateReservationLoading}
              onClick={onContinue}
            >
              {t("continue")}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
      <Sheet
        open={!!bookableToBlock}
        onOpenChange={(open) => {
          if (!open) {
            setBookableToBlock(undefined);
            setBlockStartDate(undefined);
            setBlockEndDate(undefined);
            setBlockType(undefined);
            setBlockReason("");
          } else {
            setBookableToBlock(bookableToBlock);
          }
        }}
      >
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" text-primary-text">
              {t("block-room")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto bg-secondary-card-backplate p-4 text-primary-text">
            <p className=" text-base font-bold">
              {t("chosen-room")}: {bookableToBlock?.name}
            </p>

            <div className=" mt-1 flex flex-nowrap items-end space-x-2">
              <div className=" flex-1">
                <Label className="text-xs">{t("start-date")}*</Label>
                <DatePicker
                  value={blockStartDate}
                  onValueChange={setBlockStartDate}
                />
              </div>
              <TimePicker date={blockStartDate} setDate={setBlockStartDate} />
            </div>

            <div className=" mt-1 flex flex-nowrap items-end space-x-2">
              <div className=" flex-1">
                <Label className="text-xs">{t("end-date")}*</Label>
                <DatePicker
                  value={blockEndDate}
                  onValueChange={setBlockEndDate}
                />
              </div>
              <TimePicker date={blockEndDate} setDate={setBlockEndDate} />
            </div>

            <div className="mt-2">
              <Label className="text-xs">{t("block")}*</Label>
              <Select
                value={blockType}
                onValueChange={(value) =>
                  setBlockType(value as "out-of-service" | "out-of-order")
                }
              >
                <SelectTrigger className="min-w-[170px] text-xs">
                  <SelectValue placeholder={t("choose-block")} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="out-of-service" className=" text-xs">
                    {t("out-of-service")}
                  </SelectItem>

                  <SelectItem value="out-of-order" className=" text-xs">
                    {t("out-of-order")}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="mt-2">
              <Label className="text-xs">{t("reason")}*</Label>
              <Textarea
                value={blockReason}
                onChange={(e) => setBlockReason(e.target.value)}
              />
            </div>
          </div>

          <div className=" flex justify-end space-x-2 ">
            <Button
              variant="outline"
              onClick={() => {
                setBookableToBlock(undefined);
                setBlockStartDate(undefined);
                setBlockEndDate(undefined);
                setBlockType(undefined);
                setBlockReason("");
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={
                !blockReason ||
                !blockStartDate ||
                !blockEndDate ||
                !blockType ||
                isBefore(blockEndDate, blockStartDate)
              }
              onClick={onBlockBookable}
              loading={blockLoading}
            >
              {t("continue")}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
      <ConfirmDialog
        proceedBtnText={t("cancel-reservation")}
        title={t("do-you-really-want-to-cancel-the-reservation?")}
        description={t(
          "the-reservation-is-saved-as-canceled-and-will-no-longer-affect-the-category-or-room-availability",
        )}
        onOpenChange={setCancelReservationOpen}
        isOpen={cancelReservationOpen}
        onProceed={cancelReservation}
      />
    </>
  );
};

interface AssignableBookablesProps {
  bookingId?: string;
  categoryId: string;
  startDate: Date;
  endDate: Date;
  value?: string;
  onValueChange?: (v: string) => void;
}

const AssignableBookables = ({
  bookingId,
  categoryId,
  startDate,
  endDate,
  value,
  onValueChange = () => {},
}: AssignableBookablesProps) => {
  const [t] = useTranslation();
  const {
    data: bookablesData,
    isLoading: bookablesLoading,
    isRefetching: bookablesRefetching,
  } = useGetAllocableBookables({
    variables: {
      bookingId: bookingId,
      categoryId,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    },
  });

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className="min-w-[170px] text-xs">
        <SelectValue placeholder={t("assign-unit")} />
      </SelectTrigger>
      <SelectContent>
        {bookablesData?.bookables.map((b) => (
          <SelectItem key={b.id} value={b.id}>
            {b.name}
          </SelectItem>
        ))}
        {bookablesData?.bookables.length === 0 && (
          <p className="p-2 text-center text-sm font-normal text-secondary-text">
            {t("no-units-available")}
          </p>
        )}
      </SelectContent>
    </Select>
  );
};

interface CategoriesProps {
  data?: GetCategoryGridCategory[];
  expandedIds: string[];
  tableSize: "small" | "medium" | "large";
  showBar: boolean;
  selectedCategories: {
    category: GetCategoryGridCategory;
    entries: GetCategoryGridDateEntry[];
    numberOfRooms: number;
    startDate: Date;
    endDate: Date;
  }[];
  focusedCategory?: {
    category: GetCategoryGridCategory;
    entries: GetCategoryGridDateEntry[];
    numberOfRooms: number;
    startDate: Date;
    endDate: Date;
  };
  today: Date;
  selectedBookables: {
    category: GetCategoryGridCategory;
    bookable?: GetCategoryGridBookable;
    entries: GetCategoryGridBookableDateEntry[];
    startDate: Date;
    endDate: Date;
    bookableId?: string;
    adults?: number;
    children?: {
      age?: number;
      bedPreferenceId?: string;
    }[];
  }[];
  hoveredBookingId?: string;
  focusedBookable?: {
    category: GetCategoryGridCategory;
    bookable: GetCategoryGridBookable;
    entries: GetCategoryGridBookableDateEntry[];
    startDate: Date;
    endDate: Date;
  };
  setExpandedIds: (v: string[]) => void;
  setFocusedCategory: (v?: {
    category: GetCategoryGridCategory;
    entries: GetCategoryGridDateEntry[];
    numberOfRooms: number;
    startDate: Date;
    endDate: Date;
  }) => void;
  setSelectedCategories: (
    v: {
      category: GetCategoryGridCategory;
      entries: GetCategoryGridDateEntry[];
      numberOfRooms: number;
      startDate: Date;
      endDate: Date;
    }[],
  ) => void;
  setNewBookingOpen: (v: boolean) => void;
  setBookableToBlock: (v?: GetCategoryGridBookable) => void;
  setHoveredBookingId: (id?: string) => void;
  setFocusedBookable: (v?: {
    category: GetCategoryGridCategory;
    bookable: GetCategoryGridBookable;
    entries: GetCategoryGridBookableDateEntry[];
    startDate: Date;
    endDate: Date;
  }) => void;
  setSelectedBookables: (
    v: {
      category: GetCategoryGridCategory;
      bookable?: GetCategoryGridBookable;
      entries: GetCategoryGridBookableDateEntry[];
      startDate: Date;
      endDate: Date;
      bookableId?: string;
      adults?: number;
      children?: {
        age?: number;
        bedPreferenceId?: string;
      }[];
    }[],
  ) => void;
}

const Categories = ({
  data,
  expandedIds,
  tableSize,
  showBar,
  selectedCategories,
  focusedCategory,
  today,
  selectedBookables,
  hoveredBookingId,
  focusedBookable,
  setExpandedIds,
  setFocusedCategory,
  setSelectedCategories,
  setNewBookingOpen,
  setBookableToBlock,
  setHoveredBookingId,
  setFocusedBookable,
  setSelectedBookables,
}: CategoriesProps) => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();

  const onCategoryMouseDown = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      date: Date,
      entry: GetCategoryGridDateEntry,
      c: GetCategoryGridCategory,
    ) => {
      if (isBefore(date, today)) {
        return;
      }
      const startDate = new Date(entry.date);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
      const category = {
        category: c,
        entries: [entry],
        numberOfRooms: 1,
        startDate,
        endDate,
      };
      setFocusedCategory(category);
      if (e.ctrlKey || e.metaKey) {
        setSelectedCategories([...selectedCategories, category]);
      } else {
        setSelectedCategories([category]);
      }
      e.preventDefault();
    },
    [today, selectedCategories],
  );

  const onCategoryMouseUp = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      entry: GetCategoryGridDateEntry,
      c: GetCategoryGridCategory,
    ) => {
      if (c.id === focusedCategory?.category.id) {
        const endDate = new Date(entry.date);
        if (isBefore(endDate, focusedCategory.startDate)) {
          return;
        }
        endDate.setDate(endDate.getDate() + 1);
        focusedCategory.endDate = endDate;
        const newEntries = focusedCategory.category.entries.filter((e) =>
          isWithinInterval(new Date(e.date), {
            start: focusedCategory.startDate,
            end: new Date(entry.date),
          }),
        );
        focusedCategory.entries = newEntries;
        setSelectedCategories([...selectedCategories]);
        setFocusedCategory(undefined);
        if (!(e.ctrlKey || e.metaKey)) {
          setNewBookingOpen(true);
        }
      }
    },
    [today, selectedCategories, focusedCategory],
  );

  const onCategoryMouseOver = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      entry: GetCategoryGridDateEntry,
      c: GetCategoryGridCategory,
    ) => {
      if (c.id === focusedCategory?.category.id) {
        const endDate = new Date(entry.date);
        if (isBefore(endDate, focusedCategory.startDate)) {
          return;
        }
        endDate.setDate(endDate.getDate() + 1);
        focusedCategory.endDate = endDate;
        const newEntries = focusedCategory.category.entries.filter((e) =>
          isWithinInterval(new Date(e.date), {
            start: focusedCategory.startDate,
            end: new Date(entry.date),
          }),
        );
        focusedCategory.entries = newEntries;
        setSelectedCategories([...selectedCategories]);
        e.preventDefault();
      }
    },
    [today, selectedCategories, focusedCategory],
  );

  const onBookableMouseDown = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      date: Date,
      entry: GetCategoryGridBookableDateEntry,
      a: GetCategoryGridBookable,
      c: GetCategoryGridCategory,
    ) => {
      if (isBefore(date, today)) {
        return;
      }
      const startDate = new Date(entry.date);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
      const bookable = {
        category: c,
        bookable: a,
        entries: [entry],
        startDate,
        endDate,
      };
      setFocusedBookable(bookable);
      if (e.ctrlKey || e.metaKey) {
        setSelectedBookables([
          ...selectedBookables,
          { ...bookable, adults: c.capacity.adults.min || 1 },
        ]);
      } else {
        setSelectedBookables([
          { ...bookable, adults: c.capacity.adults.min || 1 },
        ]);
      }
      e.preventDefault();
    },
    [today, selectedBookables],
  );

  const onBookableMouseUp = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      entry: GetCategoryGridBookableDateEntry,
      a: GetCategoryGridBookable,
    ) => {
      if (a.id === focusedBookable?.bookable.id) {
        const endDate = new Date(entry.date);
        if (isBefore(endDate, focusedBookable.startDate)) {
          return;
        }
        endDate.setDate(endDate.getDate() + 1);
        const newEntries = focusedBookable.bookable.entries.filter((e) =>
          isWithinInterval(new Date(e.date), {
            start: focusedBookable.startDate,
            end: new Date(entry.date),
          }),
        );
        if (newEntries.find((e) => !!e.status)) {
          return;
        }
        focusedBookable.endDate = endDate;
        focusedBookable.entries = newEntries;
        setSelectedBookables([
          ...selectedBookables.map((b) =>
            focusedBookable.bookable.id === b.bookable?.id
              ? { ...b, ...focusedBookable }
              : b,
          ),
        ]);
        setFocusedBookable(undefined);
        if (!(e.ctrlKey || e.metaKey)) {
          setNewBookingOpen(true);
        }
      }
    },
    [today, selectedBookables, focusedBookable],
  );

  const onBookableMouseOver = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      entry: GetCategoryGridBookableDateEntry,
      a: GetCategoryGridBookable,
    ) => {
      if (a.id === focusedBookable?.bookable.id) {
        const endDate = new Date(entry.date);
        if (isBefore(endDate, focusedBookable.startDate)) {
          return;
        }
        endDate.setDate(endDate.getDate() + 1);
        const newEntries = focusedBookable.bookable.entries.filter((e) =>
          isWithinInterval(new Date(e.date), {
            start: focusedBookable.startDate,
            end: new Date(entry.date),
          }),
        );
        if (newEntries.find((e) => !!e.status)) {
          return;
        }
        focusedBookable.endDate = endDate;
        focusedBookable.entries = newEntries;
        setSelectedBookables([
          ...selectedBookables.map((b) =>
            focusedBookable.bookable.id === b.bookable?.id
              ? { ...b, ...focusedBookable }
              : b,
          ),
        ]);
        e.preventDefault();
      }
    },
    [today, selectedBookables, focusedBookable],
  );

  return (
    <>
      {!data?.length ? (
        <div
          className={cn("flex w-full items-center justify-center p-2", {
            "py-1": tableSize === "small",
          })}
        >
          <p
            className={cn(" text-sm font-normal text-secondary-text", {
              "text-xs": tableSize === "small",
              "mt-2": tableSize === "large",
            })}
          >
            {t("no-categories-found")}
          </p>
        </div>
      ) : null}
      {data?.length
        ? data.map((c) => (
            <Collapsible.Root
              className="  w-full min-w-fit"
              key={c.id}
              open={expandedIds.includes(c.id)}
              onOpenChange={(open) => {
                if (open) {
                  setExpandedIds([...expandedIds, c.id]);
                } else {
                  setExpandedIds(expandedIds.filter((id) => c.id !== id));
                }
              }}
            >
              <div
                className={cn(
                  "flex h-[40px] w-full flex-nowrap border-highlighted-backplate",
                  {
                    "h-[25px]": tableSize === "small",
                    "my-2 border-t": tableSize === "large",
                    "mb-0": expandedIds.includes(c.id) || showBar,
                  },
                )}
              >
                <Collapsible.Trigger>
                  <div
                    className={cn(
                      " flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-b border-r border-highlighted-backplate bg-secondary-card-backplate px-2 text-sm font-normal",
                      {
                        "text-xs": tableSize === "small",
                      },
                    )}
                  >
                    {c.name}
                    {expandedIds.includes(c.id) ? (
                      <ChevronUp size={18} />
                    ) : (
                      <ChevronDown size={18} />
                    )}
                  </div>
                </Collapsible.Trigger>

                {c.entries.map((entry, index) => {
                  const date = new Date(entry.date);
                  return (
                    <div
                      key={entry.date}
                      onMouseDown={(e) =>
                        onCategoryMouseDown(e, date, entry, c)
                      }
                      onMouseUp={(e) => onCategoryMouseUp(e, entry, c)}
                      onMouseOver={(e) => onCategoryMouseOver(e, entry, c)}
                      className={cn(
                        " flex min-w-[50px] flex-1 flex-col items-center justify-center border-b border-highlighted-backplate bg-secondary-card-backplate",
                        {
                          "cursor-pointer": isBefore(today, date),
                          "bg-primary-card-backplate": date.getDay() === 0,
                          "bg-amber-500-50": isSameDay(today, date),
                          "border-r": index !== c.entries.length - 1,
                          "border-l":
                            selectedCategories &&
                            selectedCategories.find(
                              (c1) =>
                                c1.category.id === c.id &&
                                !!c1.entries.find(
                                  (e) =>
                                    e.date === entry.date &&
                                    isSameDay(c1.startDate, entry.date),
                                ),
                            ),
                          "border-status-success bg-status-success-100":
                            selectedCategories &&
                            selectedCategories.find(
                              (c1) =>
                                c1.category.id === c.id &&
                                !!c1.entries.find((e) => e.date === entry.date),
                            ),
                        },
                      )}
                    >
                      <p
                        className={cn(
                          "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium",
                          {
                            "opacity-40": date < today,
                            "text-xs": tableSize === "small",
                          },
                        )}
                      >
                        {entry.vacantAssets}
                      </p>
                    </div>
                  );
                })}
              </div>

              {showBar ? (
                <div
                  className={cn(
                    "flex h-[40px] w-full flex-nowrap bg-amber-500-25",
                    {
                      "h-[25px]": tableSize === "small",
                      "mb-2": tableSize === "large",
                      "mb-0": expandedIds.includes(c.id),
                    },
                  )}
                >
                  <div
                    className={cn(
                      " flex h-full min-w-[170px] max-w-[170px]  items-center justify-between border-b border-r border-highlighted-backplate px-2 text-sm font-normal",
                      {
                        "text-xs": tableSize === "small",
                      },
                    )}
                  >
                    {t("bar-from")}
                    <p className=" text-xs text-secondary-text">SEK</p>
                  </div>

                  {c.entries.map((entry, index) => {
                    const date = new Date(entry.date);
                    return (
                      <div
                        key={entry.date}
                        onMouseDown={(e) =>
                          onCategoryMouseDown(e, date, entry, c)
                        }
                        onMouseUp={(e) => onCategoryMouseUp(e, entry, c)}
                        onMouseOver={(e) => onCategoryMouseOver(e, entry, c)}
                        className={cn(
                          "flex min-w-[50px] flex-1 flex-col items-center justify-center border-b  border-highlighted-backplate",
                          {
                            "cursor-pointer": isBefore(today, date),
                            "bg-primary-card-backplate": date.getDay() === 0,
                            "bg-amber-500-50": isSameDay(today, date),
                            "border-r": index !== c.entries.length - 1,
                            "border-l":
                              selectedCategories &&
                              selectedCategories.find(
                                (c1) =>
                                  c1.category.id === c.id &&
                                  !!c1.entries.find(
                                    (e) =>
                                      e.date === entry.date &&
                                      isSameDay(c1.startDate, entry.date),
                                  ),
                              ),
                            "border-status-success bg-status-success-100":
                              selectedCategories &&
                              selectedCategories.find(
                                (c1) =>
                                  c1.category.id === c.id &&
                                  !!c1.entries.find(
                                    (e) => e.date === entry.date,
                                  ),
                              ),
                          },
                        )}
                      >
                        <p
                          className={cn(
                            "max-w-[46px] overflow-hidden text-ellipsis text-nowrap text-xs font-medium",
                            {
                              "opacity-40": date < today,
                            },
                          )}
                        >
                          {new Intl.NumberFormat(
                            i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                            { maximumFractionDigits: 2 },
                          ).format(entry.minPrice)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : undefined}

              <Collapsible.Content className=" w-full min-w-fit">
                {c.bookables.map((a) => (
                  <div
                    key={a.id}
                    className={cn(
                      "box-border flex h-[40px] w-full  flex-nowrap",
                      {
                        "h-[25px]": tableSize === "small",
                      },
                    )}
                  >
                    <div
                      className={cn(
                        " flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-b border-r  border-highlighted-backplate bg-primary-card-backplate px-2 text-sm font-normal",
                        {
                          "text-xs": tableSize === "small",
                        },
                      )}
                    >
                      <div>
                        <p>{a.name} </p>
                      </div>
                      <div className=" flex flex-nowrap space-x-1">
                        {a.connections?.length ? (
                          <TooltipProvider delayDuration={0}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Link size={14} />
                              </TooltipTrigger>
                              <TooltipContent
                                align="start"
                                side="bottom"
                                className="min-w-[160px] p-0"
                              >
                                <div>
                                  <p className=" border-b border-highlighted-backplate p-2 text-xs font-bold text-primary-text">
                                    {t("connecting-rooms")}
                                  </p>
                                  {a.connections.map((c) => (
                                    <div
                                      key={c.id}
                                      className=" flex flex-nowrap items-center space-x-2 p-2 text-sm font-medium text-primary-text"
                                    >
                                      <Link size={14} />
                                      <p>{c.name}</p>
                                    </div>
                                  ))}
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ) : undefined}
                        <DropdownMenu.Root>
                          <DropdownMenuTrigger>
                            {" "}
                            <MoreHorizontal size={12} />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="start">
                            <DropdownMenuItem
                              onClick={() => setBookableToBlock(a)}
                            >
                              {t("block-room")}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu.Root>
                      </div>
                    </div>

                    {a.entries.map((entry, index) => {
                      const date = new Date(entry.date);
                      return (
                        <div
                          key={entry.date}
                          className={cn(
                            "relative flex min-w-[50px] flex-1 flex-col items-center justify-center border-b border-highlighted-backplate bg-primary-card-backplate",
                            {
                              "bg-amber-500-50": isSameDay(today, date),
                              "border-r": index !== c.entries.length - 1,
                              "border-l":
                                selectedBookables &&
                                selectedBookables.find(
                                  (b1) =>
                                    b1.bookable?.id === a.id &&
                                    !!b1.entries.find(
                                      (e) =>
                                        e.date === entry.date &&
                                        isSameDay(b1.startDate, entry.date),
                                    ),
                                ),
                              "border-t border-status-success bg-status-success-100":
                                selectedBookables &&
                                selectedBookables.find(
                                  (b1) =>
                                    b1.bookable?.id === a.id &&
                                    !!b1.entries.find(
                                      (e) => e.date === entry.date,
                                    ),
                                ),
                            },
                          )}
                        >
                          <TooltipProvider delayDuration={0}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                {entry.status ? (
                                  <div
                                    className={cn("h-full w-full", {
                                      "border-b border-t border-primary-text py-[2px]":
                                        entry.booking?.id &&
                                        entry.booking?.id === hoveredBookingId,
                                      "rounded-r-sm border-r":
                                        entry.booking?.id ===
                                          entry.booking?.id &&
                                        entry.booking?.id ===
                                          hoveredBookingId &&
                                        a.entries[index + 1]?.booking?.id !==
                                          hoveredBookingId,
                                      "rounded-l-sm border-l":
                                        entry.booking?.id ===
                                          entry.booking?.id &&
                                        entry.booking?.id ===
                                          hoveredBookingId &&
                                        a.entries[index - 1]?.booking?.id !==
                                          hoveredBookingId,
                                    })}
                                  >
                                    <div
                                      onMouseEnter={() => {
                                        setHoveredBookingId(entry.booking?.id);
                                      }}
                                      onMouseLeave={() => {
                                        setHoveredBookingId(undefined);
                                      }}
                                      onClick={() =>
                                        entry.booking?.reservationId &&
                                        navigate(
                                          `/${module}/${ROUTES.RESERVATIONS}/${entry.booking.reservationId}`,
                                        )
                                      }
                                      className={cn(
                                        "absolute right-[-1px] top-[3px] z-10 mr-[1px] h-[33px] w-[calc(100%+1px)] opacity-80",
                                        `bg-${bookableStatusToColor(entry.status)}`,
                                        {
                                          "cursor-pointer":
                                            !!entry.booking?.reservationId,
                                          " ml-[2px] mr-[0px] rounded-l":
                                            a.entries[index - 1]?.status !==
                                              entry.status ||
                                            a.entries[index - 1]?.booking
                                              ?.id !== entry.booking?.id,
                                          " mr-[4px] rounded-r":
                                            a.entries[index + 1]?.status !==
                                              entry.status ||
                                            a.entries[index + 1]?.booking
                                              ?.id !== entry.booking?.id,
                                          " w-[calc(100%-2px)]":
                                            a.entries[index - 1]?.status !==
                                              entry.status ||
                                            a.entries[index - 1]?.booking
                                              ?.id !== entry.booking?.id ||
                                            a.entries[index + 1]?.status !==
                                              entry.status ||
                                            a.entries[index + 1]?.booking
                                              ?.id !== entry.booking?.id,
                                          " mr-[4px] w-[calc(100%-6px)]":
                                            (a.entries[index - 1]?.status !==
                                              entry.status ||
                                              a.entries[index - 1]?.booking
                                                ?.id !== entry.booking?.id) &&
                                            (a.entries[index + 1]?.status !==
                                              entry.status ||
                                              a.entries[index + 1]?.booking
                                                ?.id !== entry.booking?.id),
                                          "opacity-100":
                                            entry.booking?.id &&
                                            entry.booking?.id ===
                                              hoveredBookingId,
                                          "h-[18px]": tableSize === "small",
                                          "z-20":
                                            (entry.status === "out-of-order" ||
                                              entry.status ===
                                                "out-of-service") &&
                                            a.entries[index - 1]?.status !==
                                              entry.status &&
                                            a.entries[index + 1]?.status ===
                                              entry.status,
                                        },
                                      )}
                                    >
                                      {entry.status === "out-of-order" &&
                                        a.entries[index - 1]?.status !==
                                          entry.status &&
                                        a.entries[index + 1]?.status ===
                                          entry.status && (
                                          <p
                                            className={cn(
                                              " absolute m-2 text-nowrap text-xs font-medium text-teritary-text",
                                              {
                                                "my-[2px]":
                                                  tableSize === "small",
                                              },
                                            )}
                                          >
                                            {t("out-of-order")}
                                          </p>
                                        )}
                                      {entry.status === "out-of-service" &&
                                        a.entries[index - 1]?.status !==
                                          entry.status &&
                                        a.entries[index + 1]?.status ===
                                          entry.status && (
                                          <p
                                            className={cn(
                                              " absolute m-2 text-nowrap text-xs font-medium text-teritary-text",
                                              {
                                                "my-[2px]":
                                                  tableSize === "small",
                                              },
                                            )}
                                          >
                                            {t("out-of-service")}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={cn(" h-full w-full", {
                                      "cursor-pointer": isBefore(today, date),
                                      "bg-status-success-100":
                                        selectedBookables &&
                                        selectedBookables.find(
                                          (b) =>
                                            b.bookable?.id === a.id &&
                                            !!b.entries.find(
                                              (e) => e.date === entry.date,
                                            ),
                                        ),
                                    })}
                                    onMouseDown={(e) =>
                                      onBookableMouseDown(e, date, entry, a, c)
                                    }
                                    onMouseUp={(e) =>
                                      onBookableMouseUp(e, entry, a)
                                    }
                                    onMouseOver={(e) =>
                                      onBookableMouseOver(e, entry, a)
                                    }
                                  />
                                )}
                              </TooltipTrigger>
                              {entry.booking?.startDate ? (
                                <TooltipContent
                                  align="start"
                                  side="bottom"
                                  className="min-w-[250px] p-0"
                                >
                                  <div className=" mt-3 flex items-center justify-between px-3">
                                    <p className=" text-lg font-extrabold text-primary-text">
                                      {a.name}
                                    </p>

                                    <p className=" flex items-center justify-center rounded-s bg-secondary-card-backplate p-1 text-xs font-extrabold">
                                      {c.name}
                                    </p>
                                  </div>

                                  <div className="mt-2 border-b border-highlighted-backplate px-3 pb-4">
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("name")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {entry.booking.primaryGuestLastName},{" "}
                                        {entry.booking.primaryGuestFirstName}
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("date")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {format(
                                          new Date(entry.booking.startDate),
                                          "d MMM",
                                          {
                                            locale:
                                              i18n.language === "sv-se"
                                                ? sv
                                                : enGB,
                                          },
                                        )}{" "}
                                        -{" "}
                                        {format(
                                          new Date(entry.booking.endDate),
                                          "d MMM",
                                          {
                                            locale:
                                              i18n.language === "sv-se"
                                                ? sv
                                                : enGB,
                                          },
                                        )}{" "}
                                        (
                                        {differenceInCalendarDays(
                                          new Date(entry.booking.endDate),
                                          new Date(entry.booking.startDate),
                                        )}{" "}
                                        {t("nights").toLowerCase()})
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("number-of-guests")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {entry.booking.numAdults ? (
                                          <>
                                            {entry.booking.numAdults}(
                                            {t("adults-short")}){" "}
                                          </>
                                        ) : undefined}
                                        {entry.booking.numTeenagers ? (
                                          <>
                                            {entry.booking.numTeenagers}(
                                            {t("teenagers-short")}){" "}
                                          </>
                                        ) : undefined}
                                        {entry.booking.numChildren ? (
                                          <>
                                            {entry.booking.numChildren}(
                                            {t("children-short")}){" "}
                                          </>
                                        ) : undefined}
                                        {entry.booking.numInfants ? (
                                          <>
                                            {entry.booking.numInfants}(
                                            {t("infants-short")}){" "}
                                          </>
                                        ) : undefined}
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("price")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {new Intl.NumberFormat(
                                          i18n.language === "sv-se"
                                            ? "sv-SE"
                                            : "en-GB",
                                          { maximumFractionDigits: 2 },
                                        ).format(
                                          entry.booking.bookablePrice,
                                        )}{" "}
                                        <span className=" text-[10px] font-normal">
                                          SEK
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="mt-4 px-3 pb-3">
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("booking-no")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {entry.booking.id}
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("reservation-no")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {entry.booking.reservationId}
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("channel")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {entry.booking.channelName}
                                      </p>
                                    </div>
                                    <div className="flex flex-nowrap py-[2px]">
                                      <p className=" w-[100px] text-xs font-normal">
                                        {t("total")}
                                      </p>
                                      <p className=" flex-1 text-xs font-extrabold">
                                        {new Intl.NumberFormat(
                                          i18n.language === "sv-se"
                                            ? "sv-SE"
                                            : "en-GB",
                                          { maximumFractionDigits: 2 },
                                        ).format(entry.booking.totalPrice)}{" "}
                                        <span className=" text-[10px] font-normal">
                                          SEK
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="mb-4 flex space-x-2 px-3">
                                    <p
                                      className={cn(
                                        "rounded-s p-[6px] text-xs font-extrabold",
                                        ` bg-${bookableStatusToColor(entry.status)}-100  text-${bookableStatusToColor(entry.status)}`,
                                      )}
                                    >
                                      {t(entry.status as string)}
                                    </p>
                                    <p
                                      className={cn(
                                        "rounded-s p-[6px] text-xs font-extrabold",
                                        ` bg-${paymentStatusToColor(entry.booking.paymentStatus)}-100  text-${paymentStatusToColor(entry.booking.paymentStatus)}`,
                                      )}
                                    >
                                      {t(entry.booking.paymentStatus as string)}
                                    </p>
                                  </div>
                                </TooltipContent>
                              ) : undefined}
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </Collapsible.Content>
            </Collapsible.Root>
          ))
        : null}
    </>
  );
};

export default CategoryGridPage;
