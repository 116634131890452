import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import {
  CategoryTypeEnum,
  ReceptionContextProvider,
} from "../reception-context";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { useTranslation } from "react-i18next";
import { OccupantsCardHeader } from "./components/occupants-card-header";
import { OccupantsTableContainer } from "./components/occupants-table-container";

const OccupantsPage = ({ tab }: { tab: CategoryTypeEnum }) => {
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const tabItems = [
    {
      value: "all",
      label: t("all"),
      to: `/${module}/${ROUTES.OCCUPANTS}`,
    },
    {
      value: "hotel",
      label: t("hotel"),
      to: `/${module}/${ROUTES.OCCUPANTS_HOTEL}`,
    },
    {
      value: "area",
      label: t("area"),
      to: `/${module}/${ROUTES.OCCUPANTS_AREA}`,
    },
    {
      value: "hostel",
      label: t("hostel"),
      to: `/${module}/${ROUTES.OCCUPANTS_HOSTEL}`,
    },
  ];

  return (
    <ReceptionContextProvider categoryType={tab} page="occupants">
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <CategoryTabs tab={tab} tabItems={tabItems} />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <OccupantsCardHeader />
          <OccupantsTableContainer />
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};
export default OccupantsPage;
