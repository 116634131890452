import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { ReceptionTable } from "../../components/reception-table";
import { TableHeader } from "../../components/table-header";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { useTranslation } from "react-i18next";
import { formatGuests, formatPrice } from "../../reception-utils";
import { format } from "date-fns";
import { Button } from "@primitives/button";
import { Lock } from "lucide-react";
import { Loading } from "@primitives/loading";
import { usePostCheckOutByBookingId } from "@api/reception";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import { BookingState } from "../../../../../../api-contracts/reservations";

import { Checkbox } from "@primitives/checkbox";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";

export const DeparturesTableContainer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { selectedBookings, date } = useReceptionContext();

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedToday = today.toISOString().split("T")[0];
  const formattedDate = date.toISOString().split("T")[0];
  const isToday = formattedDate === formattedToday;

  const {
    departures,
    columnVisibility,
    setColumnVisibility,
    searchTerm,
    isLoading,
    categoryType,
  } = useReceptionContext();

  const { mutateAsync: checkOut } = usePostCheckOutByBookingId();

  const onCheckOut = (bookingId: string) => {
    try {
      checkOut(bookingId);
      queryClient.invalidateQueries();

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const toggleAllRowSelection = () => {
    console.log("TOGGLE ALL ROWS");
  };
  const handleRowClick = (booking: ReceptionBooking) => {
    navigate(`/${module}/reservations/${booking.reservationId}`);
  };

  const columnHelper = createColumnHelper<ReceptionBooking>();

  const departuresColumnDefinition = [
    columnHelper.display({
      id: "select",
      size: 5,
      enableHiding: false,
      header: () => {
        const isAllRowsSelected = selectedBookings.length === departures.length;
        const hasAnyPaymentRemaining = departures.some(
          (row) => Number(row.paymentRemaining) > 0,
        );
        const noDepartures = departures.length === 0;

        if (noDepartures || !isToday || hasAnyPaymentRemaining) return null;
        return (
          <Checkbox
            className={`h-4 w-4 ${isAllRowsSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isAllRowsSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              toggleAllRowSelection();
            }}
          />
        );
      },
      cell: ({ row }) => {
        const isRowSelected = selectedBookings.some(
          (selectedRow) =>
            selectedRow.reservationId === row.original.reservationId,
        );

        const hasPaymentRemaining = Number(row.original.paymentRemaining) > 0;
        if (!isToday || hasPaymentRemaining) return null;

        return (
          <Checkbox
            className={`h-4 w-4 ${isRowSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isRowSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              console.log("TOGGLE!");
            }}
          />
        );
      },
    }),
    columnHelper.accessor("reservationId", {
      header: t("reservation"),
      id: "reservationId",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.name", {
      header: t("first-name"),
      id: "firstName",
      cell: ({ getValue }) => {
        const firstName = getValue();
        return (
          <HighlightText
            text={firstName ? firstName : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.surname", {
      header: t("surname"),
      id: "surname",
      cell: ({ getValue }) => {
        const surname = getValue();
        return (
          <HighlightText
            text={surname ? surname : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor(
      (row) => {
        const { adults, teenagers, children, infants } = row;

        return formatGuests(
          { adults, teenagers, children, infants },
          i18n.languages[0],
        );
      },
      {
        header: t("guests"),
        id: "guests",
        meta: {
          initialVisibility: true,
        },
      },
    ),
    columnHelper.accessor("arrivalDate", {
      header: t("arrival-date"),
      id: "arrivalDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: false,
      },
    }),
    columnHelper.accessor("departureDate", {
      header: t("departure-date"),
      id: "departureDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("numberOfNights", {
      header: t("number-of-nights"),
      id: "numberOfNights",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("bookable.categoryName", {
      header: t("category"),
      id: "roomCategory",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("bookable.name", {
      header: categoryType === CategoryTypeEnum.HOTEL ? t("room") : t("unit"),
      id: "room",
      cell: ({ row }) => {
        const { lockedBookable, bookable } = row.original;
        return (
          <div className="flex w-full items-center justify-between">
            <p className="mr-2">{bookable.name}</p>
            {lockedBookable && (
              <Lock strokeWidth={1} size={16} className="ml-auto" />
            )}
          </div>
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("paymentRemaining", {
      header: t("to-be-paid"),
      id: "paymentRemaining",
      cell: ({ getValue }) => formatPrice(getValue()),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
      meta: {
        initialVisibility: true,
      },
      cell: ({ getValue }) => {
        const stateLabels: Partial<Record<BookingState, string>> = {
          planned: t("not-checked-in"),
          "checked-in": t("checked-in"),
          "checked-out": t("checked-out"),
          "no-show": t("no-show"),
        };

        const stateClasses: Partial<Record<BookingState, string>> = {
          planned: "bg-status-warning-100 text-status-warning",
          "checked-in": "bg-status-success-100 text-status-success",
          "checked-out": "bg-status-disabled-100 text-status-disabled",
          "no-show": "bg-status-error-100 text-status-error",
        };

        const stateValue = getValue();
        const label = stateLabels[stateValue];
        const stateClassName = stateClasses[stateValue];
        return (
          <div
            className={`${stateClassName} flex items-center justify-center whitespace-nowrap rounded-[4px] px-2 py-1 font-extrabold`}
          >
            <p>{label}</p>
          </div>
        );
      },
    }),
    columnHelper.display({
      header: t("action"),
      id: "action",
      enableHiding: false,
      cell: ({ row }) => {
        //TODO - Fix dialogs
        const stateValue: BookingState = row.original.state;
        const departureDate = new Date(row.original.departureDate);

        const isToday =
          departureDate.getDate() === today.getDate() &&
          departureDate.getMonth() === today.getMonth() &&
          departureDate.getFullYear() === today.getFullYear();

        const isCheckedOut = stateValue === "checked-out";
        const isCheckedIn = stateValue === "checked-in";

        return (
          <div>
            {isToday && isCheckedIn && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onCheckOut(row.original.bookingId);
                }}
                size={"sm"}
              >
                {t("check-out")}
              </Button>
            )}
            {isToday && isCheckedOut && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  console.log("UNDO CHECK-OUT FOR:", row.original.bookingId);
                }}
                size={"sm"}
              >
                {t("undo-check-out")}
              </Button>
            )}
          </div>
        );
      },
    }),
  ];

  const departuresTable = useReactTable({
    data: departures,
    columns: departuresColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const cols = departuresTable.getAllLeafColumns();
  if (isLoading || !departures) return <Loading />;
  return (
    <div>
      <div className="p-4">
        <TableHeader columns={cols} tableType="departures" />
      </div>
      <ReceptionTable table={departuresTable} onRowClick={handleRowClick} />
    </div>
  );
};
