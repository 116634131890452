import { useGetAllocableBookables } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import { AlertDialog } from "@primitives/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { format } from "date-fns";
import React from "react";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Loading } from "@primitives/loading";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { PatchBookingRequest } from "../../../../../../api-contracts/reservations";

interface AllocateDialogProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onCheckIn?: (bookingId: string) => void;
  onPatchBooking: (
    booking: ReceptionBooking,
    patchData: PatchBookingRequest,
  ) => void;
  onOpenChange: React.Dispatch<SetStateAction<boolean>>;
  proceedBtnText: string;
  onDiscard?: () => void;
  booking: ReceptionBooking;
}
export const AllocateRoomDialog: FC<AllocateDialogProps> = ({
  isOpen = false,
  title = "Allocate asset",
  description = "Choose asset",
  onCheckIn,
  onPatchBooking,
  onOpenChange,
  proceedBtnText,
  onDiscard,
  booking,
}) => {
  const { t } = useTranslation();
  const { categoryType } = useReceptionContext();
  const [keepPrice, setKeepPrice] = useState<boolean>(false);

  const initialCategoryId = booking.bookable.categoryId;
  const initialBookableId = booking.bookable.id;
  const initialBookableName = booking.bookable.name;

  const [categoryId, setCategoryId] = useState<string>(initialCategoryId);
  const [bookable, setBookable] = useState<
    { id: string; name: string } | undefined
  >({
    id: initialBookableId,
    name: initialBookableName,
  });

  const mapCategoryTypeToQuery = (categoryType: string) => {
    switch (categoryType) {
      case CategoryTypeEnum.ALL:
        return ["room", "area", "bed", "dormitory"];
      case CategoryTypeEnum.HOTEL:
        return ["room"];
      case CategoryTypeEnum.AREA:
        return ["area"];
      case CategoryTypeEnum.HOSTEL:
        return ["bed", "dormitory"];
      default:
        return ["room", "area", "bed", "dormitory"];
    }
  };

  const categoryTypeQuery = mapCategoryTypeToQuery(categoryType);
  const { data: categoriesData, isLoading: categoriesIsLoading } =
    useGetCategories({
      variables: { categoryTypes: categoryTypeQuery as any },
    });

  const { data: bookablesData, isLoading: bookablesIsLoading } =
    useGetAllocableBookables({
      variables: {
        categoryId: categoryId,
        startDate: format(booking.arrivalDate, "yyyy-MM-dd"),
        endDate: format(booking.departureDate, "yyyy-MM-dd"),
      },
    });

  const initialCategory = categoriesData?.find(
    (category) => String(category.id) === String(initialCategoryId),
  );
  const initialCategoryPrice = initialCategory?.minimumRate || 0;

  const sortedCategories = categoriesData
    ? [...categoriesData].sort((a, b) => {
        const priceDiffA = a.minimumRate - initialCategoryPrice;
        const priceDiffB = b.minimumRate - initialCategoryPrice;
        return priceDiffA - priceDiffB;
      })
    : [];

  const combinedBookables = useMemo(() => {
    if (String(categoryId) === String(initialCategoryId)) {
      const isBookableInData = bookablesData?.bookables.some(
        (b) => b.id === initialBookableId,
      );

      if (!isBookableInData) {
        return [
          { id: initialBookableId, name: initialBookableName },
          ...(bookablesData?.bookables || []),
        ];
      }
    }

    return bookablesData?.bookables || [];
  }, [
    categoryId,
    initialCategoryId,
    bookablesData,
    initialBookableId,
    initialBookableName,
  ]);
  const handleProceed = async () => {
    const hasCategoryChanged = String(categoryId) !== String(initialCategoryId);
    const hasBookableChanged =
      String(bookable?.id) !== String(initialBookableId);
    try {
      if (hasCategoryChanged || hasBookableChanged) {
        const patchData: PatchBookingRequest = keepPrice
          ? {
              categoryId: categoryId.toString(),
              ...(bookable?.id && { bookableId: bookable.id }),
              slots: [
                {
                  priceOverrideAmount: initialCategoryPrice,
                  priceAdjustmentPercent: null,
                  startDate: format(booking.arrivalDate, "yyyy-MM-dd"),
                  startTime: "14:00",
                },
              ],
            }
          : {
              categoryId: categoryId.toString(),
              ...(bookable?.id && { bookableId: bookable.id }),
            };

        await onPatchBooking(booking, patchData);
      }

      if (onCheckIn) {
        await onCheckIn(booking.bookingId);
      }
    } catch (error) {
      console.error("Error occurred during patch or check-in process:", error);

      return;
    }
  };

  useEffect(() => {
    const hasNoChanges = String(categoryId) === String(initialCategoryId);

    setBookable(
      hasNoChanges
        ? { id: initialBookableId, name: initialBookableName }
        : undefined,
    );
  }, [categoryId, initialCategoryId, initialBookableId, initialBookableName]);

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
          <AlertDialog.Description>{description}</AlertDialog.Description>
        </AlertDialog.Header>
        <div className="flex flex-col justify-between">
          <p>{t("category")}</p>
          <Select
            onValueChange={(value: string) => setCategoryId(value.toString())}
            value={String(categoryId)}
          >
            <SelectTrigger className="relative">
              <>
                <SelectValue />
                {categoriesIsLoading && (
                  <div className="flex">
                    <Loading />
                  </div>
                )}
              </>
            </SelectTrigger>
            <SelectContent>
              <div className="mb-1 flex p-2">
                <SwitchWithLabel
                  checked={keepPrice}
                  onCheckedChange={setKeepPrice}
                  label={t("keep-price")}
                />
              </div>

              {sortedCategories?.map((category) => {
                const isInitialCategory =
                  String(category.id) === String(initialCategoryId);

                const priceDiff = keepPrice
                  ? 0
                  : category.minimumRate - initialCategoryPrice;

                const priceDiffClassName =
                  priceDiff > 0
                    ? "bg-status-success-100 text-status-success"
                    : priceDiff < 0
                      ? "bg-status-warning-100 text-status-warning"
                      : "bg-status-disabled-100 text-status-disabled";

                return (
                  <SelectItem key={category.id} value={category.id}>
                    <div className="flex w-full items-center">
                      <span>{category.short}</span>
                      <span
                        className={`absolute right-10 rounded-[4px] px-2 text-xs font-extrabold ${priceDiffClassName}`}
                      >
                        {isInitialCategory || priceDiff === 0
                          ? `+/- 0 SEK`
                          : priceDiff > 0
                            ? `+ ${priceDiff} SEK`
                            : `- ${Math.abs(priceDiff)} SEK`}
                      </span>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>

          <p className="mt-2">{t("unit")}</p>
          <Select
            value={bookable?.id}
            onValueChange={(value: string) =>
              setBookable(
                combinedBookables.find((b) => b.id === value) || bookable,
              )
            }
          >
            <SelectTrigger>
              <>
                <SelectValue placeholder={t("choose-unit")} />
                {bookablesIsLoading && (
                  <div>
                    <Loading />
                  </div>
                )}
              </>
            </SelectTrigger>
            <SelectContent>
              {combinedBookables.length === 0 ? (
                <p className="p-2 text-center text-sm font-normal text-secondary-text">
                  {t("no-units-available")}
                </p>
              ) : (
                combinedBookables.map((b) => (
                  <SelectItem key={b.id} value={b.id}>
                    {b.name}
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
        </div>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>
          {onDiscard && (
            <AlertDialog.Cancel onClick={() => onDiscard()}>
              {t("discard")}
            </AlertDialog.Cancel>
          )}
          <AlertDialog.Action
            disabled={bookable === undefined}
            onClick={handleProceed}
          >
            {proceedBtnText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
