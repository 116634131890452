import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Note } from "../../../../../../api-contracts/notes";
import { Button } from "@primitives/button";
import { Textarea } from "@primitives/textarea";
import { useCreateNote, useUpdateNote } from "@api/notes";
import { useToast } from "@hooks/use-toast";
import { format } from "date-fns";

interface Props {
  value: Note[];
  onValueChange?: (value: Note[]) => void;
  disabled?: boolean;
  noteType: "reservation" | "booking";
}

const NotesSelect = ({ value, onValueChange, disabled, noteType }: Props) => {
  const [open, setOpen] = React.useState<boolean>();
  const [t] = useTranslation();
  const [message, setMessage] = React.useState<string>("");
  const [selectedNote, setSelectedNote] = React.useState<Note>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const updateNote = useUpdateNote();
  const createNote = useCreateNote();

  useEffect(() => {
    setMessage(selectedNote?.message || "");
  }, [selectedNote]);

  const handleCreate = async () => {
    try {
      setCreateLoading(true);
      const note = await createNote.mutateAsync({
        message,
      });
      onValueChange?.([...value, note]);
      setMessage("");
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setUpdateLoading(true);
      const note = await updateNote.mutateAsync({
        patch: { message },
        id: selectedNote!.id,
      });
      const newValue = value.filter((n) => n.id !== selectedNote!.id);
      onValueChange?.([...newValue, note]);
      setSelectedNote(undefined);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setUpdateLoading(false);
  };

  const handleDelete = (note: Note) => {
    onValueChange?.(value.filter((n) => n.id !== note.id));
    if (selectedNote?.id === note.id) {
      setSelectedNote(undefined);
    }
  };

  return (
    <PopoverRoot open={!!open} onOpenChange={(o) => setOpen(o)}>
      <PopoverTrigger asChild disabled={disabled} className=" flex-1">
        <div className="flex h-full cursor-pointer items-center justify-between rounded-lg bg-solid-backplate px-4 py-2">
          <div>
            <p className=" text-sm font-medium">{t("notes")}</p>
          </div>
          <div className=" flex items-center space-x-4">
            <p className=" bg-amber-500-10 p-2 text-xs font-extrabold  text-accent-amber-700">
              {value.length}
            </p>
            {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <div className="px-4 pb-4">
          <div>
            {value.map((n) => (
              <div
                key={n.id}
                className="border-b border-highlighted-backplate py-4"
              >
                <p className=" text-xs font-medium text-secondary-text">
                  {t(noteType)}
                </p>
                <p className=" text-sm font-medium">{n.message}</p>
                <p className=" text-xs font-normal italic text-secondary-text">
                  {t("note-created-{{at}}-by-{{by}}", {
                    at: format(n.createdAt, "yyyy-MM-dd, HH:mm"),
                    by: `${n.createdBy.name} ${n.createdBy.surname}`,
                  })}
                </p>
                <div className=" mt-2 flex space-x-2">
                  <Button variant="outline" onClick={() => setSelectedNote(n)}>
                    {t("edit")}
                  </Button>
                  <Button variant="outline" onClick={() => handleDelete(n)}>
                    {t("remove")}
                  </Button>
                </div>
              </div>
            ))}
            {!value.length && (
              <div className="border-b border-highlighted-backplate py-4">
                <p className=" text-center text-sm font-medium text-secondary-text  ">
                  {t("no-notes-added")}
                </p>
              </div>
            )}
          </div>
          <div className="mt-4">
            <Textarea
              placeholder={t("add-note")}
              disabled={disabled}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className=" mt-4 flex items-center space-x-2">
              <Button
                variant="primary"
                size="sm"
                loading={createLoading || updateLoading}
                disabled={!message || disabled}
                onClick={selectedNote ? handleUpdate : handleCreate}
              >
                {selectedNote ? t("save") : t("add")}
              </Button>
              {selectedNote ? (
                <Button
                  variant="outline"
                  size="sm"
                  disabled={disabled}
                  onClick={() => setSelectedNote(undefined)}
                >
                  {t("add-new")}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default NotesSelect;
