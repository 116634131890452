import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@primitives/dialog";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { cn } from "@utils/css.ts";
import { Button } from "@primitives/button";
import {
  AssetOverview,
  AssetType,
} from "../../../../../../api-contracts/assets";
import { useGetAssetsByCategory } from "@api/assets";
import { X } from "lucide-react";
import { useCreateCombination, useGetCombinations } from "@api/combinations";
import { useToast } from "@hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { queryClient } from "query-client";

export const CreateDormitoryDialog: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
}> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<AssetType>("bed");
  const [selection, setSelection] = useState<Omit<AssetOverview, "images">[]>(
    [],
  );
  const { toast } = useToast();
  const navigate = useNavigate();
  const { module } = useProfileContext();

  const createComb = useCreateCombination();

  const {
    data: assetsData,
    isLoading: assetsLoading,
    isRefetching: assetsRefetching,
  } = useGetAssetsByCategory({
    variables: {
      showByCategory: false,
      types: [tab],
    },
  });

  const assetClicked = (asset: Omit<AssetOverview, "images">) => {
    const existsAsset = selection.find((s) => asset.id === s.id);
    if (existsAsset) {
      setSelection(selection.filter((s) => s.id !== asset.id));
    } else {
      setSelection([...selection, asset]);
    }
  };

  const createCombination = async () => {
    try {
      const response = await createComb.mutateAsync({
        assetIds: selection.map((s) => s.id),
        categoryIds: [],
        name: selection.map((s) => s.name).join(" + "),
        allowChainLink: false,
        size: selection
          .map((s) => s.size)
          .reduce((prev, curr) => (prev ? prev : 0) + (curr ? curr : 0), 0),
        width: null,
        length: null,
      });

      setSelection([]);
      navigate(`/${module}/dormitories/${response.id}`);
      queryClient.invalidateQueries({
        queryKey: useGetCombinations.getKey(),
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" block h-screen !max-h-full sm:!h-[580px] sm:!w-[500px] sm:!max-w-full">
          <div className=" flex h-full flex-col">
            <h2 className=" text-lg font-extrabold text-primary-text">
              {t("create-new-combination")}
            </h2>
            <p className=" text-sm font-normal text-secondary-text">
              {t(
                "select-two-or-more-bookable-items-from-the-list-below-to-create-a-combination",
              )}
            </p>

            <Tabs
              className=" mb-4 mt-4"
              value={tab}
              onValueChange={setTab as any}
            >
              <TabsList className="bg-secondary-card-backplate p-1">
                <TabsTrigger
                  value="bed"
                  className={cn(
                    "w-24 text-sm font-light data-[state=active]:bg-solid-backplate data-[state=active]:text-primary-text",
                  )}
                >
                  {t("bed")}
                </TabsTrigger>
              </TabsList>
            </Tabs>

            <div className=" h-[350px] overflow-y-auto border border-border-color shadow-sm">
              {assetsData?.map((a) => (
                <Button
                  key={a.id}
                  variant="ghost"
                  className=" flex w-full items-center justify-between py-1"
                  onClick={() =>
                    assetClicked(a as Omit<AssetOverview, "images">)
                  }
                >
                  <div className=" ml-4">
                    <p className=" text-sm font-medium text-primary-text">
                      {a.name}
                    </p>
                  </div>
                  <div className=" mr-2 flex flex-nowrap items-center">
                    {selection.find((s) => a.id === s.id) ? (
                      <>
                        <X size="16px" className="mr-2 text-secondary-text" />{" "}
                        {t("selected")}
                      </>
                    ) : (
                      <>{t("select")}</>
                    )}
                  </div>
                </Button>
              ))}
            </div>

            <div className=" mt-4 flex justify-end space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={selection.length < 2}
                onClick={createCombination}
              >
                {t("combine")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
