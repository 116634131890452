import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { Button } from "@primitives/button";
import { Label } from "@primitives/label";
import { Input } from "@primitives/input";
import { ChangeEventHandler, useMemo, useState } from "react";
import { GetBooking } from "../../../../../../api-contracts/reservations";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  booking: GetBooking;
  onBookingChange: (booking: GetBooking) => void;
}

export const DiscountDialog = ({
  open,
  onOpenChange,
  booking,
  onBookingChange,
}: Props) => {
  const { t } = useTranslation();
  const [discountMode, setDiscountMode] = useState<
    "total-price" | "discount-percentage"
  >("total-price");
  const [discountDistribution, setDiscountDistribution] = useState<
    "keep-daily-price-differences" | "same-daily-price"
  >("keep-daily-price-differences");
  const fullPrice = useMemo(
    () => booking.slots.reduce((acc, slot) => acc + slot.fullPrice, 0),
    [booking.slots],
  );
  const total = useMemo(
    () => booking.slots.reduce((acc, slot) => acc + slot.price, 0),
    [booking.slots],
  );
  const [discountPercentage, setDiscountPercentage] = useState<number>(
    (1 - total / fullPrice) * 100,
  );
  const [totalPrice, setTotalPrice] = useState<number>(total);

  const handleTotalPriceChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value;
    const n = Number(v);
    if (isNaN(n) || n < 0) {
      setTotalPrice(totalPrice);
    } else {
      setTotalPrice(n);
    }
  };

  const handleDiscountPercentageChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const v = e.target.value;
    const n = Number(v);
    if (isNaN(n) || n < 0 || n > 100) {
      setDiscountPercentage(discountPercentage);
    } else {
      setDiscountPercentage(n);
    }
  };

  const handleApplyDiscount = () => {
    const newBooking = { ...booking };

    if (discountMode === "discount-percentage") {
      newBooking.slots = newBooking.slots.map((s) => ({
        ...s,
        priceAdjustmentPercent: discountPercentage,
        priceOverrideAmount: null,
        price: s.fullPrice * (1 - 0.01 * discountPercentage),
      }));
    } else {
      if (discountDistribution === "same-daily-price") {
        newBooking.slots = newBooking.slots.map((s) => ({
          ...s,
          priceAdjustmentPercent: null,
          priceOverrideAmount: totalPrice / newBooking.slots.length,
          price: totalPrice / newBooking.slots.length,
        }));
      } else {
        const fullPrice = booking.slots.reduce(
          (acc, s) => (acc += s.fullPrice),
          0,
        );
        const priceReduction = fullPrice - totalPrice;
        const priceReductionPerSlot = priceReduction / newBooking.slots.length;
        newBooking.slots = newBooking.slots.map((s) => ({
          ...s,
          priceAdjustmentPercent: null,
          priceOverrideAmount: s.fullPrice - priceReductionPerSlot,
          price: s.fullPrice - priceReductionPerSlot,
        }));
      }
    }

    onBookingChange(newBooking);
    onOpenChange(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[500px] max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("change-price")}
            </DialogTitle>

            <div className="mt-4">
              <div>
                <RadioGroup
                  value={discountMode}
                  onValueChange={(v) =>
                    setDiscountMode(v as "total-price" | "discount-percentage")
                  }
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="total-price" id="total-price" />
                    <Label htmlFor="total-price">
                      {t("enter-total-price")}
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value="discount-percentage"
                      id="discount-percentage"
                    />
                    <Label htmlFor="discount-percentage">
                      {t("enter-percentage-price-reduction")}
                    </Label>
                  </div>
                </RadioGroup>
              </div>

              <div className=" mt-4">
                {discountMode === "total-price" && (
                  <div className=" relative flex items-center justify-end">
                    <Input
                      value={String(totalPrice)}
                      onChange={handleTotalPriceChange}
                    />
                    <p className="absolute w-10 text-xs text-secondary-text">
                      SEK
                    </p>
                  </div>
                )}
                {discountMode === "discount-percentage" && (
                  <div className=" relative flex items-center justify-end">
                    <Input
                      value={String(discountPercentage)}
                      onChange={handleDiscountPercentageChange}
                    />
                    <p className="absolute w-10 text-xs text-secondary-text">
                      %
                    </p>
                  </div>
                )}
              </div>

              {discountMode === "total-price" && (
                <div className=" mt-4">
                  <RadioGroup
                    value={discountDistribution}
                    onValueChange={(v) =>
                      setDiscountDistribution(
                        v as
                          | "keep-daily-price-differences"
                          | "same-daily-price",
                      )
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="keep-daily-price-differences"
                        id="keep-daily-price-differences"
                      />
                      <Label htmlFor="keep-daily-price-differences">
                        {t("keep-daily-price-differences")}
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="same-daily-price"
                        id="same-daily-price"
                      />
                      <Label htmlFor="same-daily-price">
                        {t("same-daily-price")}
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
              )}

              <div className=" mt-4 flex justify-end space-x-2">
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                  {t("cancel")}
                </Button>
                <Button variant="primary" onClick={handleApplyDiscount}>
                  {t("apply")}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DiscountDialog;
