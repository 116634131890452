import { Size } from "@shared/types/common";
import { AgeType } from "../../../../api-contracts/reservations";

export function isError(error: unknown): error is Error {
  return (
    typeof error === "object" &&
    error !== null &&
    "name" in error &&
    "message" in error &&
    "stack" in error
  );
}

export function isResponse(response: unknown): response is Response {
  return (
    typeof response === "object" &&
    response !== null &&
    "status" in response &&
    "statusText" in response &&
    "headers" in response &&
    "ok" in response
  );
}

export const isNumber = (n: unknown): n is number =>
  typeof n === "number" && isFinite(n);

export const resizePropotionally = (
  baseWidth: number,
  baseHeight: number,
  toWidth?: number,
  toHeight?: number,
): Size => {
  const ratio = baseWidth / baseHeight;

  // If both width and height are specified, resize unpropotionally
  if (isNumber(toWidth) && isNumber(toHeight)) {
    return { width: toWidth, height: toHeight };
  }

  const width =
    isNumber(toWidth) && toWidth !== baseWidth
      ? toWidth
      : isNumber(toHeight)
        ? toHeight * ratio
        : baseWidth;
  const height =
    isNumber(toHeight) && toHeight !== baseHeight
      ? toHeight
      : isNumber(toWidth)
        ? toWidth / ratio
        : baseHeight;

  return { width, height };
};

export function isFormField(element: Node): boolean {
  if (!(element instanceof HTMLElement)) {
    return false;
  }

  const name = element.nodeName.toLowerCase();
  const type = (element.getAttribute("type") || "").toLowerCase();
  return (
    name === "select" ||
    name === "textarea" ||
    (name === "input" &&
      type !== "submit" &&
      type !== "reset" &&
      type !== "checkbox" &&
      type !== "radio" &&
      type !== "file") ||
    element.isContentEditable
  );
}

/**
 * Returns a new object with only the specified keys.
 */
export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = obj[key];
      return acc;
    },
    {} as Pick<T, K>,
  );
}

/**
 * Returns a new object with the specified keys omitted.
 */
export function omit<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const copy = { ...obj };
  keys.forEach((key) => delete copy[key]);
  return copy;
}

/**
 * Returns a matrix of the permutations of the passed array.
 */
export function permute<T>(permutation: T[]) {
  var length = permutation.length,
    result = [permutation.slice()],
    c = new Array(length).fill(0),
    i = 1,
    k,
    p;

  while (i < length) {
    if (c[i] < i) {
      k = i % 2 && c[i];
      p = permutation[i];
      permutation[i] = permutation[k];
      permutation[k] = p;
      ++c[i];
      i = 1;
      result.push(permutation.slice());
    } else {
      c[i] = 0;
      ++i;
    }
  }
  return result;
}

/**
 * Returns a matrix of the combinations of the passed array.
 */
export function combine<T>(a: T[], min: number) {
  var fn = function (n: number, src: T[], got: T[], all: T[][]) {
    if (n == 0) {
      if (got.length > 0) {
        all[all.length] = got;
      }
      return;
    }
    for (var j = 0; j < src.length; j++) {
      fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
    }
    return;
  };
  var all: T[][] = [];
  for (var i = min; i < a.length; i++) {
    fn(i, a, [], all);
  }
  all.push(a);
  return all;
}

/**
 * Returns list of dates between start and end.
 */
export const getDaysBetween = function (start: Date, end: Date) {
  const arr = [];
  for (
    const dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const getAgeType = (age: number): AgeType => {
  if (age >= 0 && age <= 2) {
    return "infant";
  } else if (age >= 3 && age <= 13) {
    return "child";
  } else if (age >= 14 && age <= 18) {
    return "teenager";
  } else if (age >= 19) {
    return "adult";
  } else {
    throw new Error("Invalid age");
  }
};

export function intersects<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.some((item) => arr2.includes(item));
}
