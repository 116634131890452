import React, { memo, useCallback, useEffect, useState } from "react";
import { isBefore, isPast, isToday, startOfDay } from "date-fns";
import { generateWeekdaysShort } from "../price-calendar.utils";
import { YearCalendarDayCell } from "./year-calendar-cell";
import {
  CalendarDay,
  CalendarMonth,
  CalendarYear,
} from "../../../../../../api-contracts/price-calendar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import { TooltipCard } from "./tooltip-card";
import { useTranslation } from "react-i18next";
import { Layouts, usePriceCalendarContext } from "../price-calendar-context";
import { NoteAlertDialog } from "./note-alert-dialog";
import { Loading } from "@primitives/loading";
import { MonthCalendar } from "./month-layout-calendar";

export const YearLayoutCalendar = memo(() => {
  const {
    selectedDays,
    setSelectedDays,
    availablePriceRules,
    currentCalendar,
    nextCalendar,
    previousCalendar,
    isLoading,
    layout,
    month,
    nextNextCalendar,
  } = usePriceCalendarContext();

  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);
  const [cellToOpen, setCellToOpen] = useState<CalendarDay | null>(null);

  const { i18n } = useTranslation();
  const today = startOfDay(new Date());
  const weekDayShort = generateWeekdaysShort(i18n.language);

  const onSelect = useCallback(
    (day: CalendarDay) => {
      if (isPast(day.date) && !isToday(day.date)) {
        return;
      }
      setSelectedDays((prevSelectedDays) => {
        const isSelected = prevSelectedDays.some(
          (selectedDay) => selectedDay.date === day.date,
        );

        if (isSelected) {
          return prevSelectedDays.filter(
            (selectedDay) => selectedDay.date !== day.date,
          );
        } else {
          return [...prevSelectedDays, day];
        }
      });
    },
    [setSelectedDays],
  );

  useEffect(() => {
    const updatedSelectedDays = selectedDays.map((selectedDay) => {
      let updatedDay = null;

      if (currentCalendar) {
        for (const month of currentCalendar.months) {
          for (const week of month.weeks) {
            const day = week.find(
              (day) => day && day.date === selectedDay.date,
            );
            if (day) {
              updatedDay = day;
              break;
            }
          }
          if (updatedDay) break;
        }
      }

      return updatedDay || selectedDay;
    });

    if (JSON.stringify(updatedSelectedDays) !== JSON.stringify(selectedDays)) {
      setSelectedDays(updatedSelectedDays);
    }
  }, [currentCalendar, selectedDays, setSelectedDays]);

  const handleOpenNoteDialog = useCallback((day: CalendarDay) => {
    setCellToOpen(day);
    setIsNoteOpen(true);
  }, []);

  const handleCloseNoteDialog = useCallback(() => {
    setIsNoteOpen(false);
  }, []);

  useEffect(() => {
    if (!cellToOpen) return;

    const findDayInCalendar = (calendar: CalendarYear | undefined) => {
      return calendar?.months
        ?.flatMap((month) => month.weeks.flatMap((week) => week))
        .find((day) => day?.date === cellToOpen.date);
    };

    let updatedDay = null;

    if (layout === Layouts.Month) {
      updatedDay =
        findDayInCalendar(currentCalendar) ||
        findDayInCalendar(previousCalendar) ||
        findDayInCalendar(nextCalendar) ||
        findDayInCalendar(nextNextCalendar);
    } else {
      updatedDay = findDayInCalendar(currentCalendar);
    }

    if (updatedDay) {
      setCellToOpen(updatedDay);
    }
  }, [
    currentCalendar,
    previousCalendar,
    nextCalendar,
    nextNextCalendar,
    cellToOpen,
    layout,
  ]);

  const renderVerticalLayout = () => {
    if (!currentCalendar) return;

    return (
      <div className="table overflow-auto pb-4">
        <div className="table-header-group ">
          <div className="table-row ">
            <div className="table-cell border-r border-highlighted-backplate"></div>
            {currentCalendar.months.map((month, monthIndex) => (
              <div
                key={`${monthIndex}-${month}`}
                className={`table-cell h-10  align-middle`}
              >
                <p className="ml-1 p-1 text-sm font-medium text-secondary-text">
                  {month.name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-row-group">
          {Array.from({
            length: currentCalendar.months.reduce(
              (max, month) => Math.max(max, month.weeks.length),
              0,
            ),
          }).map((_, weekIndex) => (
            <React.Fragment key={weekIndex}>
              {weekDayShort.map((dayName, dayIndex) => {
                const isRowEmpty = currentCalendar.months.every(
                  (month) =>
                    !month.weeks[weekIndex] ||
                    !month.weeks[weekIndex][dayIndex],
                );
                const isSunday = dayIndex === 6;

                if (!isRowEmpty) {
                  return (
                    <div key={`${weekIndex}-${dayIndex}`} className="table-row">
                      <div
                        className={`table-cell w-16 border-r border-t border-highlighted-backplate align-middle ${isSunday && "bg-accent-amber-500/10"}`}
                      >
                        <p className="mx-2 align-middle text-sm font-extrabold text-secondary-text">
                          {dayName}
                        </p>
                      </div>
                      {currentCalendar.months.map((month, monthIndex) => {
                        const day = month.weeks[weekIndex]
                          ? month.weeks[weekIndex][dayIndex]
                          : null;

                        const isSelected = selectedDays.some(
                          (selectedDay) => selectedDay.date === day?.date,
                        );
                        const dateToCheck = startOfDay(day ? day.date : "");

                        const isPastDate = isBefore(dateToCheck, today);
                        return (
                          <div
                            onClick={() => day && onSelect(day)}
                            key={`${monthIndex}-${weekIndex}-${dayIndex}`}
                            className={`table-cell min-w-20 max-w-20 border-r border-t border-highlighted-backplate ${
                              isSunday && isSelected
                                ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                                : isSunday
                                  ? "bg-accent-amber-500/10 hover:bg-accent-amber-500/20"
                                  : !day
                                    ? "bg-secondary-card-backplate"
                                    : isSelected
                                      ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                                      : isPastDate
                                        ? "bg-gray-10/20"
                                        : "bg-solid-backplate hover:bg-hover"
                            }`}
                          >
                            {day && (
                              <>
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger asChild>
                                      <div>
                                        <YearCalendarDayCell
                                          isSelected={isSelected}
                                          day={day}
                                          today={today}
                                          priceRules={availablePriceRules}
                                        />
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent
                                      onClick={(e) => e.stopPropagation()}
                                      className={`z-10 rounded-lg bg-solid-backplate p-2 shadow-md ${isPastDate && "pointer-events-none"}`}
                                    >
                                      <TooltipCard
                                        day={day}
                                        openNoteDialog={() =>
                                          handleOpenNoteDialog(day)
                                        }
                                      />
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                return;
              })}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const renderHorizontalLayout = () => {
    if (!currentCalendar) return;
    const maxWeeksLength = currentCalendar.months.reduce(
      (max, month) => Math.max(max, month.weeks.length),
      0,
    );

    return (
      <div className=" table h-full w-full overflow-x-scroll pb-4">
        <div className="table-header-group">
          <div className="table-row">
            <div className="table-cell border-r border-highlighted-backplate"></div>
            {Array.from({ length: maxWeeksLength }).map((_, weekIndex) =>
              weekDayShort.map((dayName, dayIndex) => {
                const isRowEmpty = currentCalendar.months.every(
                  (month) =>
                    !month.weeks[weekIndex] ||
                    !month.weeks[weekIndex][dayIndex],
                );
                if (!isRowEmpty)
                  return (
                    <div
                      key={`${weekIndex}-${dayIndex}`}
                      className="table-cell h-10 min-w-36 border-r border-highlighted-backplate align-middle"
                    >
                      <p className="ml-2 p-1 text-sm font-extrabold text-secondary-text">
                        {dayName}
                      </p>
                    </div>
                  );
              }),
            )}
          </div>
        </div>
        <div className="table-row-group">
          {currentCalendar.months.map((month, monthIndex) => (
            <React.Fragment key={monthIndex}>
              <div className="table-row">
                <div className="table-cell min-w-20 border-r border-t border-highlighted-backplate align-middle">
                  <p className="ml-1 p-1 text-sm font-medium text-secondary-text">
                    {month.name}
                  </p>
                </div>
                {Array.from({
                  length: currentCalendar.months.reduce(
                    (max, month) => Math.max(max, month.weeks.length),
                    0,
                  ),
                }).map((_, weekIndex) =>
                  weekDayShort.map((_, dayIndex) => {
                    const day = month.weeks[weekIndex]
                      ? month.weeks[weekIndex][dayIndex]
                      : null;

                    const isSelected = selectedDays.some(
                      (selectedDay) => selectedDay.date === day?.date,
                    );
                    const dateToCheck = startOfDay(day ? day.date : "");

                    const isPastDate = isBefore(dateToCheck, today);
                    const isSunday = dayIndex === 6;

                    const isRowEmpty = currentCalendar.months.every(
                      (month) =>
                        !month.weeks[weekIndex] ||
                        !month.weeks[weekIndex][dayIndex],
                    );
                    if (!isRowEmpty) {
                      return (
                        <div
                          onClick={() => day && onSelect(day)}
                          key={`${monthIndex}-${weekIndex}-${dayIndex}`}
                          className={`table-cell w-24  border-r border-t border-highlighted-backplate ${
                            isSunday && isSelected
                              ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                              : isSunday
                                ? "bg-accent-amber-500/10 hover:bg-accent-amber-500/20"
                                : !day
                                  ? "bg-secondary-card-backplate"
                                  : isSelected
                                    ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                                    : isPastDate
                                      ? "bg-gray-10/20"
                                      : "bg-solid-backplate hover:bg-hover"
                          } `}
                        >
                          {day && (
                            <>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <div className="h-full w-full">
                                      <YearCalendarDayCell
                                        isSelected={isSelected}
                                        day={day}
                                        today={today}
                                        priceRules={availablePriceRules}
                                      />
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent
                                    onClick={(e) => e.stopPropagation()}
                                    className={`z-10 rounded-lg bg-solid-backplate p-2 shadow-md ${isPastDate && "pointer-events-none"}`}
                                  >
                                    <TooltipCard
                                      day={day}
                                      openNoteDialog={() =>
                                        handleOpenNoteDialog(day)
                                      }
                                    />
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </>
                          )}
                        </div>
                      );
                    }
                  }),
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const extendWithPreviousOrNextMonth = (
    currentMonth: CalendarMonth | null,
    previousMonth: CalendarMonth | null,
    nextMonth: CalendarMonth | null,
  ): CalendarMonth | null => {
    if (!currentMonth || !currentMonth.weeks || currentMonth.weeks.length === 0)
      return null;

    let weeks = currentMonth.weeks;
    let flatDays = weeks.flat();

    if (weeks[0].some((day) => day === null) && previousMonth?.weeks.length) {
      const previousLastWeek =
        previousMonth.weeks[previousMonth.weeks.length - 1];
      weeks[0] = weeks[0].map((day, index) =>
        day === null ? previousLastWeek[index] : day,
      );
    }

    if (
      weeks[weeks.length - 1].some((day) => day === null) &&
      nextMonth?.weeks.length
    ) {
      const nextFirstWeek = nextMonth.weeks[0];
      weeks[weeks.length - 1] = weeks[weeks.length - 1].map((day, index) =>
        day === null ? nextFirstWeek[index] : day,
      );
    }

    if (flatDays.length < 42) {
      const daysNeeded = 42 - flatDays.length;

      if (nextMonth?.weeks.length) {
        const nextMonthDays = nextMonth.weeks
          .flat()
          .filter(
            (day) =>
              day &&
              !flatDays.some((existingDay) => existingDay?.date === day.date),
          );
        flatDays = flatDays.concat(nextMonthDays.slice(0, daysNeeded));
      }

      while (flatDays.length < 42 && nextMonth?.weeks.length) {
        const additionalDays = nextMonth.weeks
          .flat()
          .slice(0, 42 - flatDays.length);
        flatDays = flatDays.concat(additionalDays);
      }
    }

    const extendedWeeks = [];
    for (let i = 0; i < 42; i += 7) {
      extendedWeeks.push(flatDays.slice(i, i + 7));
    }

    return { ...currentMonth, weeks: extendedWeeks };
  };

  const renderMonthLayout = () => {
    if (!currentCalendar && !nextCalendar && !previousCalendar) return;

    const weekDayShort = generateWeekdaysShort(i18n.language, 3);

    const currentMonth = currentCalendar?.months?.[month] || null;
    const nextMonth =
      month === 11
        ? nextCalendar?.months?.[0] || null
        : currentCalendar?.months?.[month + 1] || null;
    const previousMonth =
      month === 0
        ? previousCalendar?.months?.[11] || null
        : currentCalendar?.months?.[month - 1] || null;

    if (!currentMonth || !previousMonth || !nextMonth) {
      console.error("Month data is missing.");
      return;
    }

    const extendedCurrentMonth = extendWithPreviousOrNextMonth(
      currentMonth,
      previousMonth,
      nextMonth,
    );

    const nextNextMonth =
      month === 11
        ? nextCalendar?.months?.[1] || null
        : month === 10
          ? nextCalendar?.months?.[0] || null
          : currentCalendar?.months?.[month + 2] || null;

    if (!extendedCurrentMonth || !nextMonth || !nextNextMonth) {
      console.error("Extended month data is missing.");
      return;
    }

    const extendedNextMonth = extendWithPreviousOrNextMonth(
      nextMonth,
      currentMonth,
      nextNextMonth,
    );

    return (
      <div className="flex h-full">
        <div className="mr-4 w-1/2">
          {extendedCurrentMonth && (
            <MonthCalendar
              currentMonthIndex={month}
              month={extendedCurrentMonth}
              weekDayShort={weekDayShort}
              onSelect={onSelect}
              openNoteDialog={handleOpenNoteDialog}
            />
          )}
        </div>

        <div className="ml-4 w-1/2">
          {extendedNextMonth && (
            <MonthCalendar
              currentMonthIndex={month === 11 ? 0 : month + 1}
              month={extendedNextMonth}
              weekDayShort={weekDayShort}
              onSelect={(day) => onSelect(day)}
              openNoteDialog={handleOpenNoteDialog}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen flex-col overflow-auto pb-4">
      {currentCalendar && !isLoading ? (
        <>
          {layout === Layouts.Vertical && renderVerticalLayout()}
          {layout === Layouts.Horizontal && renderHorizontalLayout()}
          {layout === Layouts.Month && renderMonthLayout()}
        </>
      ) : (
        <Loading />
      )}

      {cellToOpen && (
        <NoteAlertDialog
          open={isNoteOpen}
          day={cellToOpen}
          close={handleCloseNoteDialog}
        />
      )}
    </div>
  );
});
