import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useEffect, useMemo, useState } from "react";
import { GetReservation } from "../../../../../../api-contracts/reservations";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useGetFolio, useGetFolios, usePayFolios } from "@api/payments";
import { Folio, FolioEntry } from "../../../../../../api-contracts/payments";
import { Checkbox } from "@primitives/checkbox";
import { useToast } from "@hooks/use-toast";
import { useGetReservation } from "@api/reservations";
import { queryClient } from "query-client";
import { cloneDeep } from "lodash";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  reservation: GetReservation;
  onProceed: () => void;
  onSave: () => Promise<void>;
  dismissChanges: () => void;
  changesPending: boolean;
}

export const DebitReservationDialog = ({
  open,
  onOpenChange,
  reservation,
  onProceed,
  changesPending,
  onSave,
  dismissChanges,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [folioState, setFolioState] = useState<Folio[]>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const payFolios = usePayFolios();

  const {
    data: foliosData,
    isLoading: foliosIsLoading,
    isRefetching: foliosIsRefetching,
  } = useGetFolios({
    variables: {
      ids: reservation.bookings.map((b) => b.folioId),
    },
    enabled: open,
  });

  useEffect(() => {
    setFolioState(cloneDeep(foliosData));
  }, [foliosData]);

  const columns: ColumnDef<any>[] = [
    {
      id: "type",
      header: t("type"),
      accessorKey: "type",
    },
    {
      id: "date",
      header: t("date"),
      cell: (row: any) => format(row.row.original.date, "yyyy-MM-dd"),
      accessorFn: (row: any) => new Date(row.date),
    },
    {
      id: "price",
      header: t("price"),
      accessorKey: "price",
      cell: (row: any) =>
        new Intl.NumberFormat(i18n.language === "sv-se" ? "sv-SE" : "en-GB", {
          maximumFractionDigits: 2,
        }).format(row.row.original.price) + " SEK",
    },
    {
      id: "guest",
      header: t("guest"),
      accessorKey: "guest",
    },
    {
      id: "paid",
      header: t("debited"),
      accessorKey: "paid",
      cell: (row: any) => (
        <Checkbox
          checked={row.row.original.paid}
          onCheckedChange={(checked) =>
            folioState &&
            setFolioState(
              folioState.map((folio) => ({
                id: folio.id,
                entries: folio.entries.map((e) =>
                  e.id === row.row.original.id ? { ...e, paid: !!checked } : e,
                ),
              })),
            )
          }
        />
      ),
    },
  ];

  const data = useMemo(() => {
    return (
      folioState
        ?.map(
          (f) =>
            f.entries.map((fe) => ({
              folioId: f.id,
              id: fe.id,
              type: fe.type,
              price: fe.total,
              guest: `${reservation.holder?.title?.name ? reservation.holder?.title?.name + " " : ""} ${reservation.holder?.name} ${reservation.holder?.surname}`,
              date: fe.createdAt,
              paid: fe.paid,
            })) || [],
        )
        .flat() || []
    );
  }, [folioState]);

  const table = useReactTable({
    data: data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const handleSave = async () => {
    if (folioState) {
      setSaveLoading(true);
      try {
        const folios = data.reduce((group, entry) => {
          const { folioId } = entry;
          group[folioId] = group[folioId] ?? [];
          group[folioId].push(entry);
          return group;
        }, {} as any);

        await payFolios.mutateAsync({
          folios: Object.entries(folios).map(([folioId, entries]) => ({
            id: folioId,
            entries: (entries as FolioEntry[]).map((e) => ({
              id: e.id,
              paid: e.paid,
            })),
          })),
        });
        queryClient.invalidateQueries({
          queryKey: useGetReservation.getKey(),
        });
        queryClient.invalidateQueries({
          queryKey: useGetFolios.getKey(),
        });
        queryClient.invalidateQueries({
          queryKey: useGetFolio.getKey(),
        });
        toast({
          variant: "success",
          title: t("saved-succesfully", {
            name: t("folio"),
          }),
          className: "text-status-success",
        });
        onOpenChange(false);
        onProceed();
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
      setSaveLoading(false);
    }
  };

  const handleSaveReservation = async () => {
    setSaveLoading(true);
    await onSave();
    setSaveLoading(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[700px] max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("debiting")}
            </DialogTitle>

            {changesPending && (
              <div className=" mt-4">
                <p className=" text-xs font-normal text-secondary-text">
                  {t(
                    "you-have-pending-changes-that-need-to-be-saved-or-dismissed-before-handling-folio",
                  )}
                </p>

                <div className=" mt-4 flex justify-end space-x-2">
                  <Button variant="outline" onClick={dismissChanges}>
                    {t("dismiss-changes")}
                  </Button>
                  <Button
                    onClick={handleSaveReservation}
                    loading={saveLoading}
                    variant="primary"
                  >
                    {t("save")}
                  </Button>
                </div>
              </div>
            )}

            {!changesPending && (
              <div className="mt-4">
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </TableHead>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>

                  <TableBody>
                    {table.getRowModel().rows?.length ? (
                      table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          className=" cursor-pointer text-primary-text"
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="h-24 text-center text-primary-text"
                        >
                          {t("no-entries-found")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <div className=" mt-4 flex justify-end space-x-2">
                  <Button variant="outline" onClick={() => onOpenChange(false)}>
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    loading={saveLoading}
                    onClick={handleSave}
                  >
                    {t("save")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DebitReservationDialog;
