import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import {
  CategoryTypeEnum,
  ReceptionContextProvider,
} from "../reception-context";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { useTranslation } from "react-i18next";
import { DeparturesCardHeader } from "./components/departures-card-header";
import { DeparturesTableContainer } from "./components/departures-table-container";

const DeparturesPage = ({ tab }: { tab: CategoryTypeEnum }) => {
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const tabItems = [
    {
      value: "all",
      label: t("all"),
      to: `/${module}/${ROUTES.DEPARTURES}`,
    },
    {
      value: "hotel",
      label: t("hotel"),
      to: `/${module}/${ROUTES.DEPARTURES_HOTEL}`,
    },
    {
      value: "area",
      label: t("area"),
      to: `/${module}/${ROUTES.DEPARTURES_AREA}`,
    },
    {
      value: "hostel",
      label: t("hostel"),
      to: `/${module}/${ROUTES.DEPARTURES_HOSTEL}`,
    },
  ];

  return (
    <ReceptionContextProvider categoryType={tab} page="departures">
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <CategoryTabs tab={tab} tabItems={tabItems} />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <DeparturesCardHeader />
          <DeparturesTableContainer />
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};

export default DeparturesPage;
