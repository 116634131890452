import {
  useGetArrivalsByDate,
  useGetDeparturesByDate,
  useGetOccupantsByDate,
} from "@api/reception";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MessageOfTheDay,
  ReceptionBooking,
} from "../../../../api-contracts/reception";
import { format } from "date-fns";
import { VisibilityState } from "@tanstack/react-table";
import { BookingState } from "../../../../api-contracts/reservations";

export enum CategoryTypeEnum {
  ALL = "all",
  HOTEL = "hotel",
  AREA = "area",
  HOSTEL = "hostel",
}

interface ReceptionContextProviderProps {
  children: ReactNode;
  categoryType: CategoryTypeEnum;
  page: "arrivals" | "departures" | "occupants";
}

interface DataContextType {
  arrivals: ReceptionBooking[];
  departures: ReceptionBooking[];
  occupants: ReceptionBooking[];
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  messageOfTheDay: MessageOfTheDay | undefined;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  columnVisibility: VisibilityState;
  setColumnVisibility: Dispatch<SetStateAction<VisibilityState>>;
  arrivalsVisibleState: BookingState[];
  setArrivalsVisibleState: Dispatch<SetStateAction<BookingState[]>>;
  departuresVisibleState: BookingState[];
  setDeparturesVisibleState: Dispatch<SetStateAction<BookingState[]>>;
  occupantsVisibleState: BookingState[];
  setOccupantsVisibleState: Dispatch<SetStateAction<BookingState[]>>;
  categoryType: CategoryTypeEnum;
  selectedBookings: ReceptionBooking[];
  setSelectedBookings: Dispatch<SetStateAction<ReceptionBooking[]>>;
}
const initialContextValue: DataContextType = {
  searchTerm: "",
  setSearchTerm: () => null,
  arrivals: [],
  departures: [],
  occupants: [],
  isLoading: true,
  messageOfTheDay: undefined,
  date: new Date(),
  setDate: () => null,
  columnVisibility: {},
  setColumnVisibility: () => null,
  arrivalsVisibleState: [],
  setArrivalsVisibleState: () => {},
  departuresVisibleState: [],
  setDeparturesVisibleState: () => {},
  occupantsVisibleState: [],
  setOccupantsVisibleState: () => {},
  categoryType: CategoryTypeEnum.ALL,
  selectedBookings: [],
  setSelectedBookings: () => null,
};
export const ReceptionContext =
  createContext<DataContextType>(initialContextValue);

export const useReceptionContext = (): DataContextType => {
  const context = useContext(ReceptionContext);

  if (context === undefined) {
    throw new Error(
      "useReceptionContext must be used within a ReceptionContextProvider",
    );
  }
  return context;
};

export const ReceptionContextProvider: FC<ReceptionContextProviderProps> = ({
  children,
  categoryType,
  page,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [arrivalsVisibleState, setArrivalsVisibleState] = useState<
    BookingState[]
  >(["planned", "checked-in", "checked-out", "no-show"]);
  const [departuresVisibleState, setDeparturesVisibleState] = useState<
    BookingState[]
  >(["checked-in", "checked-out"]);
  const [occupantsVisibleState, setOccupantsVisibleState] = useState<
    BookingState[]
  >(["checked-in", "checked-out"]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    () => JSON.parse(localStorage.getItem("receptionColumnVisibility") || "{}"),
  );
  const [selectedBookings, setSelectedBookings] = useState<ReceptionBooking[]>(
    [],
  );
  const hasFetched = useRef(false);

  const mapCategoryTypeToQuery = (categoryType: CategoryTypeEnum) => {
    switch (categoryType) {
      case CategoryTypeEnum.ALL:
        return undefined;
      case CategoryTypeEnum.HOTEL:
        return "room";
      case CategoryTypeEnum.AREA:
        return "area";
      case CategoryTypeEnum.HOSTEL:
        return ["bed", "dormitory"];
      default:
        return undefined;
    }
  };

  const {
    data: arrivalsData,
    isLoading: arrivalsIsLoading,
    refetch: refetchArrivals,
  } = useGetArrivalsByDate({
    variables: {
      date: format(date ? date : new Date(), "yyyy-MM-dd"),
      type: mapCategoryTypeToQuery(categoryType),
    },
    enabled: page === "arrivals",
  });

  const {
    data: departuresData,
    isLoading: departuresIsLoading,
    refetch: refetchDepartures,
  } = useGetDeparturesByDate({
    variables: {
      date: format(date ? date : new Date(), "yyyy-MM-dd"),
      type: mapCategoryTypeToQuery(categoryType),
    },
    enabled: page === "departures",
  });

  const {
    data: occupantsData,
    isLoading: occupantsIsLoading,
    refetch: refetchOccupants,
  } = useGetOccupantsByDate({
    variables: {
      date: format(date ? date : new Date(), "yyyy-MM-dd"),
      type: mapCategoryTypeToQuery(categoryType),
    },
    enabled: page === "occupants",
  });

  useEffect(() => {
    if (hasFetched.current) {
      if (page === "arrivals") {
        refetchArrivals();
      }
      if (page === "departures") {
        refetchDepartures();
      }
      if (page === "occupants") {
        refetchOccupants();
      }
    } else {
      hasFetched.current = true;
    }
  }, [date, page]);
  useEffect(() => {
    localStorage.setItem(
      "receptionColumnVisibility",
      JSON.stringify(columnVisibility),
    );
  }, [columnVisibility]);

  const filterBySearchTerm = (
    booking: ReceptionBooking,
    searchTerm: string,
  ) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      booking.bookingId.toLowerCase().includes(lowerCaseSearchTerm) ||
      booking.holder?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      booking.holder?.surname?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  };

  const filterByVisibleState = (
    booking: ReceptionBooking,
    visibleStates: BookingState[],
  ) => {
    return visibleStates.includes(booking.state as BookingState);
  };

  const displayedArrivalsData = useMemo(() => {
    return arrivalsData?.arrivals
      ? arrivalsData.arrivals
          .filter((arrival: ReceptionBooking) =>
            filterBySearchTerm(arrival, searchTerm),
          )
          .filter((arrival: ReceptionBooking) =>
            filterByVisibleState(arrival, arrivalsVisibleState),
          )
      : [];
  }, [arrivalsData, searchTerm, arrivalsVisibleState]);

  const displayedDeparturesData = useMemo(() => {
    return departuresData?.departures
      ? departuresData.departures
          .filter((departure: ReceptionBooking) =>
            filterBySearchTerm(departure, searchTerm),
          )
          .filter((departure: ReceptionBooking) =>
            filterByVisibleState(departure, departuresVisibleState),
          )
      : [];
  }, [departuresData, searchTerm, departuresVisibleState]);

  const displayedOccupantsData = useMemo(() => {
    return occupantsData?.occupants
      ? occupantsData.occupants
          .filter((occupants: ReceptionBooking) =>
            filterBySearchTerm(occupants, searchTerm),
          )
          .filter((occupants: ReceptionBooking) =>
            filterByVisibleState(occupants, occupantsVisibleState),
          )
      : [];
  }, [occupantsData, searchTerm, occupantsVisibleState]);

  const messageOfTheDay = (() => {
    switch (page) {
      case "arrivals":
        return arrivalsData?.messageOfTheDay;
      case "departures":
        return departuresData?.messageOfTheDay;
      case "occupants":
        return occupantsData?.messageOfTheDay;
      default:
        return arrivalsData?.messageOfTheDay;
    }
  })();

  const value: DataContextType = {
    arrivals: displayedArrivalsData,
    departures: displayedDeparturesData,
    occupants: displayedOccupantsData,
    messageOfTheDay,
    isLoading: arrivalsIsLoading,
    searchTerm,
    setSearchTerm,
    setDate,
    date,
    columnVisibility,
    setColumnVisibility,
    arrivalsVisibleState,
    setArrivalsVisibleState,
    departuresVisibleState,
    setDeparturesVisibleState,
    occupantsVisibleState,
    setOccupantsVisibleState,
    categoryType,
    selectedBookings,
    setSelectedBookings,
  };
  return (
    <ReceptionContext.Provider value={value}>
      {children}
    </ReceptionContext.Provider>
  );
};
