import { PopoverRoot } from "@primitives/popover";
import {
  GetReservation,
  ReservationState,
} from "../../../../../../api-contracts/reservations";
import { useState } from "react";
import { PopoverAnchor, PopoverContent } from "@radix-ui/react-popover";
import { ChevronDown, ChevronUp, Info } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { useTranslation } from "react-i18next";
import { reservationStateToColor } from ".";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";

interface Props {
  reservation: GetReservation;
  value: ReservationState;
  onValueChange: (value: ReservationState) => void;
}

const states: ReservationState[] = [
  "confirmed",
  "unconfirmed",
  "preliminary",
  "guaranteed",
  "lost",
];

const ReservationStateSelect = ({
  reservation,
  value,
  onValueChange,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleStateSelect = (state: ReservationState) => {
    onValueChange(state);
    setOpen(false);
  };

  return (
    <PopoverRoot
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
      }}
    >
      <PopoverAnchor asChild>
        <div
          onClick={() => setOpen(true)}
          className={` flex cursor-pointer items-center space-x-2 rounded-lg p-2 bg-${reservationStateToColor(value)}-100 text-${reservationStateToColor(value)}`}
        >
          <p className={`text-lg font-extrabold`}>{t(value as string)}</p>
          {(reservation.state.note || reservation.state.reason) && (
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info
                    size={18}
                    className={`text-${reservationStateToColor(reservation.state.value)}`}
                  />
                </TooltipTrigger>
                <TooltipContent
                  className=" min-w-80 p-4 text-primary-text"
                  align="start"
                >
                  {reservation.state.reason && (
                    <>
                      <h4 className=" text-lg font-extrabold">{t("reason")}</h4>
                      <p className=" mb-4 text-sm font-normal text-secondary-text">
                        {t(reservation.state.reason)}
                      </p>
                    </>
                  )}
                  {reservation.state.note && (
                    <>
                      <h4 className=" text-lg font-extrabold">{t("note")}</h4>
                      <p className=" text-sm font-normal text-secondary-text">
                        {reservation.state.note}
                      </p>
                    </>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {!open && <ChevronDown size={18} />}
          {open && <ChevronUp size={18} />}
        </div>
      </PopoverAnchor>
      <PopoverContent
        align="start"
        avoidCollisions={false}
        className="p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Command>
          <CommandList className="max-h-none">
            <CommandGroup>
              {states?.map((state) => (
                <CommandItem
                  key={state}
                  value={state}
                  onSelect={() => handleStateSelect(state)}
                >
                  <div
                    className={`rounded-lg p-2 bg-${reservationStateToColor(state)}-100  w-full `}
                  >
                    <p
                      className={` text-lg font-extrabold text-${reservationStateToColor(state)}`}
                    >
                      {t(state as string)}
                    </p>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default ReservationStateSelect;
