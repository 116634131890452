import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cn } from "@utils/css";
import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import { Checkbox } from "@primitives/checkbox";
import { useGetCategories } from "@api/categories";
import { MultiSelect } from "@primitives/multi-select";
import {
  useCreateAsset,
  useGetAsset,
  useGetAssetsByCategory,
  useUpdateAsset,
} from "@api/assets";
import {
  Asset,
  AssetOverview,
  AssetType,
} from "../../../../../../api-contracts/assets";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { SearchBar } from "@primitives/search-bar";
import { Minus, Plus, Search, X } from "lucide-react";
import { useGetLocations } from "@api/locations";
import { useUpdateBookable } from "@api/bookables";
import { queryClient } from "query-client";
import { useToast } from "@hooks/use-toast";
import { Switch } from "@primitives/switch";
import { Textarea } from "@primitives/textarea";
import { MediaLibrary } from "@components/media-library";
import { NavigationArrows } from "@components/navigation-arrows";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { useGetFixturesAndItems } from "@api/fixtures-and-items";
import { ConfirmDialog } from "@components/confirm-dialog";
import { ConfirmNavigationDialog } from "@components/confirm-navigation-dialog";
import { cloneDeep, isEqual } from "lodash";
import { useGetAssetGroups } from "@api/groups";
import { useProfileContext } from "@context/profile-context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { CATEGORIES_PERMISSION } from "@shared/types/navigation";

const initialAsset = {
  id: "new",
  active: true,
  types: ["bed" as AssetType],
  allowChainLink: true,
  name: "",
  location: {
    id: null,
    name: null,
  },
  size: 0,
  length: null,
  width: null,
  categories: [],
  combinations: [],
  connections: [],
  bookables: [],
  images: [],
  groups: [],
};

const BedDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [bookableTab, setBookableTab] = useState<string>("0");
  const [asset, setAsset] = useState<Asset>(initialAsset);
  const [loadedAsset, setLoadedAsset] = useState<Asset>(initialAsset);
  const [itemSidebarOpen, setItemSidebarOpen] = useState<boolean>(false);
  const [itemSearchTerm, setItemSearchTerm] = useState<string>();
  const [isItemSearchExpanded, setIsItemSearchExpanded] = useState(false);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isOpenDeactivateDialog, setIsOpenDeactivateDialog] =
    useState<boolean>(false);
  const [active, setActive] = useState<boolean>(initialAsset.active);
  const { toast } = useToast();
  const { module, useHasPermission } = useProfileContext();
  const newRoom = id === "new";
  const isDuplicate = location.pathname.includes("duplicate");
  const isEdit = searchParams.has("edit");

  const createAsset = useCreateAsset();
  const updateAsset = useUpdateAsset();
  const updateBookable = useUpdateBookable();

  const currentBookable = asset.bookables[Number(bookableTab)];

  const {
    data: assetData,
    isLoading: assetLoading,
    isRefetching: assetRefetching,
  } = useGetAsset({
    variables: {
      id: id as string,
    },
    enabled: id !== "new",
  });

  const {
    data: groupsData,
    isLoading: groupsLoading,
    isRefetching: groupsRefetching,
  } = useGetAssetGroups({});

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    isRefetching: categoriesRefetching,
  } = useGetCategories({ variables: { categoryTypes: ["bed"] } });

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isRefetching: locationsRefetching,
  } = useGetLocations({});

  const {
    data: assetsData,
    isLoading: assetsLoading,
    isRefetching: assetsRefetching,
  } = useGetAssetsByCategory({
    variables: {
      showByCategory: false,
      types: [],
    },
  });

  const {
    data: fixturesAndItemsData,
    isLoading: fixturesAndItemsLoading,
    isRefetching: fixturesAndItemsRefetching,
  } = useGetFixturesAndItems({});

  const fixtures = useMemo(() => {
    if (itemSearchTerm) {
      return fixturesAndItemsData?.fixtureGroupsWithFixtures.map((g) => ({
        ...g,
        fixtures: g.name.toLowerCase().includes(itemSearchTerm.toLowerCase())
          ? g.fixtures
          : g.fixtures.filter((f) =>
              f.name.toLowerCase().includes(itemSearchTerm.toLowerCase()),
            ),
      }));
    } else {
      return fixturesAndItemsData?.fixtureGroupsWithFixtures;
    }
  }, [itemSearchTerm, fixturesAndItemsData]);

  useEffect(() => {
    setIsEditing(newRoom || isDuplicate || isEdit);
  }, [isDuplicate, newRoom, isEdit]);

  useEffect(() => {
    let newAsset = { ...asset, ...assetData };

    if (categoriesData) {
      newAsset = {
        ...newAsset,
        categories: categoriesData?.filter((c1) =>
          newAsset.categories.find((c2) => c1.id === c2.id),
        ),
      };
    }

    if (assetsData) {
      newAsset = {
        ...newAsset,
        connections: assetsData?.filter((c1) =>
          newAsset.connections.find((c2) => c1.id === c2.id),
        ),
      };
    }

    if (groupsData) {
      newAsset = {
        ...newAsset,
        bookables: newAsset.bookables.map((b) => ({
          ...b,
        })),
      };
    }

    setActive(newAsset.active);
    setAsset({ ...newAsset, name: isDuplicate ? "" : newAsset.name });
    setLoadedAsset({
      ...cloneDeep(newAsset),
      bookables: cloneDeep(newAsset.bookables),
      name: isDuplicate ? "" : newAsset.name,
    });
  }, [assetData, categoriesData, groupsData, isDuplicate]);

  const checkIfValuesChanged = () => {
    setSaveIsEnabled(
      !isEqual(asset, loadedAsset) || active !== loadedAsset.active,
    );
  };

  useEffect(() => {
    checkIfValuesChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, active]);

  const handleEditMode = () => {
    if (saveIsEnabled && isEditing) {
      setIsOpenEditDialog(true);
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  const handleDiscardChanges = () => {
    setIsEditing(false);
    setAsset(loadedAsset);
    setActive(loadedAsset.active);
  };

  const handleToggleActive = async () => {
    if (active) {
      setIsOpenDeactivateDialog(true);
    } else {
      setActive((prev) => !prev);
      setIsOpenDeactivateDialog(false);
    }
  };

  const onSave = async () => {
    try {
      if (!newRoom && !isDuplicate) {
        await updateAsset.mutateAsync({
          patch: {
            active: active,
            allowChainLink: asset.allowChainLink,
            name: asset.name,
            locationId: asset.location?.id || undefined,
            categoryIds: asset.categories.map((c) => c.id),
            connectingIds: asset.connections.map((c) => c.id),
            imageIds: asset.images.map((i) => i.id),
          },
          id: asset.id,
        });
        await Promise.all(
          asset.bookables.map(async (b) => {
            await updateBookable.mutateAsync({
              patch: {
                internalCost: b.internalCost || 0,
                available: b.available,
                fixtures: b.fixtures.map((f) => {
                  return { id: f.id, quantity: f.quantity };
                }),
                description: {
                  internal: b.description.internal || "",
                  external: b.description.external || "",
                  public: b.description.public || "",
                },
              },
              id: b.id,
            });
          }),
        );
      } else {
        const response = await createAsset.mutateAsync({
          types: ["bed"],
          active: active,
          allowChainLink: asset.allowChainLink,
          name: asset.name,
          size: asset.size,
          length: null,
          width: null,
          locationId: asset.location?.id as string,
          categoryIds: asset.categories.map((c) => c.id),
          connectingIds: asset.connections.map((c) => c.id),
        });
        navigate(`/${module}/beds/${response.id}`, { replace: true });
      }

      queryClient.invalidateQueries({
        queryKey: useGetAsset.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: asset.name }),
        className: "text-status-success",
        variant: "success",
      });
      setIsEditing(false);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex flex-row items-center justify-between pb-4">
          <NavigationArrows rootPath={`/${module}/beds`} />
          <div className=" flex flex-row items-center space-x-2">
            <Button
              disabled={isDuplicate || newRoom || isEditing}
              variant={"outline"}
              className="ml-1"
              onClick={() => navigate(`/${module}/beds/${id}/duplicate`)}
            >
              {t("duplicate")}
            </Button>
            <Button onClick={handleEditMode} variant={"outline"}>
              {isEditing ? `${t("cancel")} ` : `${t("edit")}`}
            </Button>
            <Button
              className=" bg-primary-color"
              disabled={!saveIsEnabled || !isEditing}
              onClick={onSave}
            >
              {t("save")}
            </Button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex space-x-4">
            <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" text-base font-bold text-primary-text">
                {t("bed")}
              </h3>
              <div className=" mt-4 flex flex-nowrap items-center justify-between">
                <p className=" text-sm font-medium text-primary-text">
                  {t("status")}
                </p>
                <SwitchWithLabel
                  disabled={!isEditing}
                  checked={active}
                  onCheckedChange={handleToggleActive}
                  label={active ? t("active") : t("inactive")}
                />
              </div>
              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("bed-name")}
              </p>
              <Input
                disabled={!isEditing}
                value={asset?.name}
                onChange={(e) => setAsset({ ...asset, name: e.target.value })}
              />
              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("location")}
              </p>
              <div>
                <Select
                  disabled={!isEditing}
                  value={asset.location.id || undefined}
                  onValueChange={(v) =>
                    setAsset({
                      ...asset,
                      location: {
                        id: v,
                        name:
                          locationsData?.find((l) => l.id === v)?.name || null,
                      },
                    })
                  }
                >
                  <SelectTrigger className="min-w-[170px] text-xs">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {locationsData?.map((d, i) => (
                      <SelectItem key={d.id} value={d.id} className=" text-xs">
                        {t(d.name)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" text-base font-bold text-primary-text">
                {t("media")}
              </h3>

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("connected-images")}
              </p>
              {asset.images.length === 0 ? (
                <div className=" flex h-[220px] items-center justify-center bg-secondary-card-backplate p-4">
                  <p className=" text-center text-sm text-secondary-text">
                    {t(
                      "there-are-no-pictures-or-video-connected-to-this-asset-right-now",
                    )}
                  </p>
                </div>
              ) : (
                <div className=" flex h-[220px] flex-wrap overflow-y-auto border border-highlighted-backplate p-2">
                  {asset.images.map((img) => (
                    <img
                      key={img.id}
                      className=" h-1/2 w-1/3 object-cover pb-2 pr-2"
                      src={img.url}
                    />
                  ))}
                </div>
              )}

              <div className="mt-4">
                <Button
                  disabled={!isEditing}
                  variant="outline"
                  onClick={() => setMediaLibraryOpen(true)}
                >
                  {t("edit")}
                </Button>
              </div>
            </div>
            <div className=" w-2/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" text-base font-bold text-primary-text">
                {t("bed-capacity")} TODO
              </h3>

              <p>TODO</p>
            </div>
          </div>

          <div className=" mt-4 flex space-x-4">
            {useHasPermission([CATEGORIES_PERMISSION]) && (
              <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
                <h3 className=" mb-4 text-base font-bold text-primary-text">
                  {t("category")}
                </h3>
                <MultiSelect
                  placeholder={t("choose-categories")}
                  options={categoriesData || []}
                  value={asset.categories}
                  onChange={(value) =>
                    setAsset({ ...asset, categories: value as any })
                  }
                  getName={(opt) => opt.name}
                  disabled={!isEditing}
                />

                <p className=" mt-4 text-sm font-medium text-primary-text">
                  {t("selected-categories")}
                </p>
                {asset.categories?.length === 0 ? (
                  <div className="flex h-[220px] items-center justify-center bg-secondary-card-backplate p-4">
                    <p className=" text-center text-sm text-secondary-text">
                      {t("there-are-no-categories-associated-with-this-asset")}
                    </p>
                  </div>
                ) : (
                  <div className="flex h-[220px] flex-col space-y-2 overflow-y-auto border border-highlighted-backplate p-2">
                    {asset.categories?.map((c) => (
                      <div
                        key={c.id}
                        className={cn(
                          "group flex h-[34px] min-h-[34px] flex-nowrap items-center justify-between rounded-sm bg-primary-card-backplate",
                          {
                            "hover:bg-secondary-card-backplate": isEditing,
                            "opacity-60": !isEditing,
                          },
                        )}
                      >
                        <p className=" ml-2 text-xs font-extrabold text-primary-text">
                          {c.name}
                        </p>
                        {isEditing && (
                          <X
                            className=" mr-1 hidden cursor-pointer group-hover:block"
                            size={18}
                            onClick={() =>
                              setAsset({
                                ...asset,
                                categories: asset.categories.filter(
                                  (c1) => c !== c1,
                                ),
                              })
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" mb-4 text-base font-bold text-primary-text">
                {t("grouping")}
              </h3>

              <MultiSelect
                placeholder={t("choose-a-group")}
                options={groupsData || []}
                value={asset.groups}
                onChange={(value) => {
                  asset.groups = value as any;
                  setAsset({ ...asset });
                }}
                getName={(opt) => opt.name}
                disabled={!isEditing}
              />

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("selected-groups")}
              </p>
              {asset.groups?.length === 0 ? (
                <div className="flex h-[220px] items-center justify-center bg-secondary-card-backplate p-4">
                  <p className=" text-center text-sm text-secondary-text">
                    {t("there-are-no-groups-connected-to-this-asset")}
                  </p>
                </div>
              ) : (
                <div className="flex h-[220px] flex-col space-y-2 overflow-y-auto border border-highlighted-backplate p-2">
                  {asset.groups?.map((g) => (
                    <div
                      key={g.id}
                      className={cn(
                        "group flex h-[34px] min-h-[34px] flex-nowrap items-center justify-between rounded-sm bg-primary-card-backplate",
                        {
                          "hover:bg-secondary-card-backplate": isEditing,
                          "opacity-60": !isEditing,
                        },
                      )}
                    >
                      <p className=" ml-2 text-xs font-extrabold text-primary-text">
                        {g.name}
                      </p>
                      {isEditing && (
                        <X
                          className=" mr-1 hidden cursor-pointer group-hover:block"
                          size={18}
                          onClick={() =>
                            setAsset({
                              ...asset,
                              groups: asset.groups.filter((g1) => g !== g1),
                            })
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" mb-4 text-base font-bold text-primary-text">
                {t("connecting-assets")}
              </h3>
              <MultiSelect
                placeholder={t("select-asset")}
                options={
                  (assetsData?.filter(
                    (a) => a.id !== asset.id,
                  ) as AssetOverview[]) || []
                }
                value={asset.connections}
                onChange={(value) =>
                  setAsset({ ...asset, connections: value as any })
                }
                getName={(opt) => opt.name}
                disabled={!isEditing}
              />

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("selected-assets")}
              </p>
              {asset.connections?.length === 0 ? (
                <div className="flex h-[220px] items-center justify-center bg-secondary-card-backplate p-4">
                  <p className=" text-center text-sm text-secondary-text">
                    {t("there-are-no-other-assets-connected-to-this-asset")}
                  </p>
                </div>
              ) : (
                <div className="flex h-[220px] flex-col space-y-2 overflow-y-auto border border-highlighted-backplate p-2">
                  {asset.connections?.map((c) => (
                    <div
                      key={c.id}
                      className={cn(
                        "group flex h-[34px] min-h-[34px] flex-nowrap items-center justify-between rounded-sm bg-primary-card-backplate",
                        {
                          "hover:bg-secondary-card-backplate": isEditing,
                          "opacity-60": !isEditing,
                        },
                      )}
                    >
                      <p className=" ml-2 text-xs font-extrabold text-primary-text">
                        {c.name}
                      </p>
                      {isEditing && (
                        <X
                          className=" mr-1 hidden cursor-pointer group-hover:block"
                          size={18}
                          onClick={() =>
                            setAsset({
                              ...asset,
                              connections: asset.connections.filter(
                                (c1) => c !== c1,
                              ),
                            })
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div className=" mt-4 flex items-center space-x-2">
                <Checkbox
                  id="test"
                  checked={asset.allowChainLink}
                  onCheckedChange={(value) =>
                    setAsset({ ...asset, allowChainLink: value as boolean })
                  }
                  disabled={!isEditing}
                />
                <label
                  className=" text-sm font-extrabold text-primary-text"
                  htmlFor="test"
                >
                  {t("allow-chaining-of-connected-assets")}
                </label>
              </div>
            </div>
            <div className=" w-1/4 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
              <h3 className=" text-base font-bold text-primary-text">
                {t("combinations")}
              </h3>

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("current-combinations")}
              </p>
              {asset.combinations?.length === 0 ? (
                <div className="flex h-[276px] items-center justify-center bg-secondary-card-backplate p-4">
                  <p className=" text-center text-sm text-secondary-text">
                    {t("this-asset-is-not-part-of-any-combination")}
                  </p>
                </div>
              ) : (
                <div className="flex h-[276px] flex-col space-y-2 overflow-y-auto border border-highlighted-backplate py-2">
                  {asset.combinations?.map((c) => (
                    <div
                      key={c.id}
                      className="flex h-[45px] min-h-[45px] flex-col justify-center rounded-sm border-b border-border-color px-2"
                    >
                      <p className=" ml-2 text-xs font-extrabold text-primary-text">
                        {c.name}
                      </p>
                      <p className=" ml-2 text-xs font-normal text-secondary-text">
                        {t("parts")}: {c.assets.map((a) => a.name).join(" + ")}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className=" mt-4 border-b border-main-border-color" />

          <div className="mt-4">
            <h3 className=" text-lg font-bold text-primary-text">
              {t("category-specific-settings")}
            </h3>

            {asset.bookables.length ? (
              <Tabs
                className=" mb-4"
                value={bookableTab}
                onValueChange={setBookableTab}
              >
                <TabsList>
                  {asset.bookables.map((b, i) => (
                    <TabsTrigger key={b.id} value={String(i)}>
                      {b.category.name}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            ) : (
              <div>
                <div className="mt-4 flex h-[220px] items-center justify-center bg-secondary-card-backplate p-4">
                  <div>
                    <p className=" text-center text-sm text-secondary-text">
                      {t("the-asset-is-not-linked-to-a-category")}
                    </p>
                    <p className=" text-center text-sm text-secondary-text">
                      {t(
                        "select-category-above-and-save-to-set-category-specific-settings",
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {currentBookable ? (
              <>
                <div className=" mt-6 flex space-x-4">
                  <div className=" flex min-h-full w-1/3 flex-col">
                    <div className=" rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
                      <h3 className=" text-base font-bold text-primary-text">
                        {t("cost")}
                      </h3>
                      <p className=" mt-4 text-sm font-medium text-primary-text">
                        {t("internal-cost")}
                      </p>
                      <div className=" relative flex items-center justify-end">
                        <Input
                          value={
                            currentBookable.internalCost !== null
                              ? currentBookable.internalCost
                              : undefined
                          }
                          onChange={(e) => {
                            const s = e.target.value;
                            const n = Number(s);
                            if (isNaN(n) || n < 0) {
                              if (!currentBookable.internalCost) {
                                currentBookable.internalCost = 0;
                              }
                              setAsset({ ...asset });
                            } else {
                              currentBookable.internalCost = n;
                              setAsset({ ...asset });
                            }
                          }}
                          disabled={!isEditing}
                        />
                        <p className="absolute mr-2 w-10 text-xs text-secondary-text">
                          SEK
                        </p>
                      </div>
                      <p className=" mt-1 text-xs font-normal text-secondary-text">
                        {t("excluding-vat")}
                      </p>
                    </div>

                    <div className=" mt-4 flex flex-grow flex-col rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
                      <h3 className=" text-base font-bold text-primary-text">
                        {t("inventory")}
                      </h3>

                      <p className=" mt-4 text-sm font-medium text-primary-text">
                        {t("connected-fixtures-&-items")}
                      </p>

                      {currentBookable?.fixtures?.length === 0 ? (
                        <div className=" flex h-[220px] flex-grow items-center justify-center bg-secondary-card-backplate p-2">
                          <p className=" text-center text-sm text-secondary-text">
                            {t("there-are-no-items-associated-with-this-asset")}
                          </p>
                        </div>
                      ) : (
                        <div className=" flex h-[220px] flex-grow flex-col space-y-2 overflow-y-auto border border-highlighted-backplate p-2">
                          {currentBookable.fixtures?.map((f) => (
                            <div
                              key={f.id}
                              className={cn(
                                " group flex h-[44px] min-h-[44px] items-center justify-between rounded-sm bg-primary-card-backplate",
                                {
                                  "hover:bg-secondary-card-backplate":
                                    isEditing,
                                  "opacity-60": !isEditing,
                                },
                              )}
                            >
                              <p className=" ml-2 text-xs font-extrabold text-primary-text">
                                {f.name}
                              </p>
                              <div className=" mr-2 flex flex-nowrap items-center space-x-2">
                                <div className=" flex h-[30px] flex-nowrap items-center space-x-2 rounded border border-highlighted-backplate bg-primary-card-backplate p-[6px]">
                                  <Minus
                                    size={18}
                                    className={cn({
                                      "cursor-pointer": isEditing,
                                      "cursor-default text-status-disabled":
                                        f.quantity <= 1,
                                    })}
                                    onClick={() => {
                                      if (isEditing) {
                                        f.quantity -= 1;
                                        if (f.quantity <= 0) {
                                          f.quantity = 1;
                                        }
                                        setAsset({
                                          ...asset,
                                        });
                                      }
                                    }}
                                  />
                                  <input
                                    disabled={!isEditing}
                                    value={f.quantity}
                                    className=" w-4 min-w-4 border-none bg-primary-card-backplate p-0 text-center text-xs font-extrabold focus:outline-none"
                                    onChange={(e) => {
                                      const s = e.target.value;
                                      const n = Number(s);
                                      if (isNaN(n) || n > 100 || n < 0) {
                                        setAsset({ ...asset });
                                      } else {
                                        f.quantity = n;
                                        setAsset({ ...asset });
                                      }
                                    }}
                                  />
                                  <Plus
                                    size={18}
                                    className={cn({
                                      "cursor-pointer": isEditing,
                                      "cursor-default text-status-disabled":
                                        f.quantity === 99,
                                    })}
                                    onClick={() => {
                                      if (isEditing) {
                                        f.quantity += 1;
                                        if (f.quantity >= 100) {
                                          f.quantity = 99;
                                        }
                                        setAsset({
                                          ...asset,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <X
                                  className={cn(" invisible mr-1", {
                                    "cursor-pointer group-hover:visible":
                                      isEditing,
                                  })}
                                  size={18}
                                  onClick={() => {
                                    if (isEditing) {
                                      currentBookable.fixtures =
                                        currentBookable.fixtures.filter(
                                          (f1) => f.id !== f1.id,
                                        );
                                      setAsset({
                                        ...asset,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="mt-4">
                        <Button
                          variant="outline"
                          onClick={() => setItemSidebarOpen(true)}
                          disabled={!isEditing}
                        >
                          {t("edit")}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className=" flex min-h-full w-1/3 flex-col rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
                    <h3 className=" text-base font-bold text-primary-text">
                      {t("cleaning")} TODO
                    </h3>

                    <p className=" mt-4 text-sm font-medium text-primary-text">
                      {t("cleaning-duration")}
                    </p>
                    <div className=" relative flex items-center justify-end">
                      <Input placeholder={t("stay")} disabled={!isEditing} />
                      <p className="absolute mr-2 w-10 text-xs text-secondary-text">
                        {t("min-short")}
                      </p>
                    </div>
                    <div className=" relative mt-2 flex items-center justify-end">
                      <Input
                        placeholder={t("departure")}
                        disabled={!isEditing}
                      />
                      <p className="absolute mr-2 w-10 text-xs text-secondary-text">
                        {t("min-short")}
                      </p>
                    </div>

                    <p className=" mt-4 text-sm font-medium text-primary-text">
                      {t("connected-cleaning-duties")}
                    </p>
                    <div className="flex h-[266px] flex-grow items-center justify-center bg-secondary-card-backplate p-4">
                      <p className=" text-center text-sm text-secondary-text">
                        {t(
                          "there-are-no-cleaning-duties-connected-to-this-asset",
                        )}
                      </p>
                    </div>

                    <div className="mt-4">
                      <Button variant="outline" disabled={!isEditing}>
                        {t("edit")}
                      </Button>
                    </div>
                  </div>

                  <div className=" w-1/3 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm">
                    <h3 className=" text-base font-bold text-primary-text">
                      {t("connected-channels")}
                    </h3>

                    <div className=" mt-4 border border-highlighted-backplate p-2">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className=" text-sm font-medium text-primary-text">
                            {t("available-internally")}
                          </p>
                          <p className=" text-xs font-normal text-secondary-text">
                            {t("the-asset-remains-bookable-internally")}
                          </p>
                        </div>
                        <Switch
                          disabled={
                            !isEditing || currentBookable.available.internal
                          }
                          checked={currentBookable.available.internal}
                          onCheckedChange={(v) => {
                            currentBookable.available.internal = v;
                            setAsset({ ...asset });
                          }}
                        />
                      </div>
                      {currentBookable.available.internal && (
                        <Textarea
                          disabled={!isEditing}
                          className="mt-2 text-primary-text"
                          rows={5}
                          value={currentBookable.description.internal || ""}
                          onChange={(e) => {
                            currentBookable.description.internal =
                              e.target.value;
                            setAsset({ ...asset });
                          }}
                        />
                      )}
                    </div>

                    <div className=" mt-4 border border-highlighted-backplate p-2">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className=" text-sm font-medium text-primary-text">
                            {t("available-online")}
                          </p>
                          <p className=" text-xs font-normal text-secondary-text">
                            {t("the-asset-remains-bookable-on-wbe")}
                          </p>
                        </div>
                        <Switch
                          disabled={!isEditing}
                          checked={currentBookable.available.public}
                          onCheckedChange={(v) => {
                            currentBookable.available.public = v;
                            setAsset({ ...asset });
                          }}
                        />
                      </div>
                      {currentBookable.available.public && (
                        <Textarea
                          disabled={!isEditing}
                          className="mt-2 text-primary-text"
                          rows={5}
                          value={currentBookable.description.public || ""}
                          onChange={(e) => {
                            currentBookable.description.public = e.target.value;
                            setAsset({ ...asset });
                          }}
                        />
                      )}
                    </div>

                    <div className=" mt-4 border border-highlighted-backplate p-2">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className=" text-sm font-medium text-primary-text">
                            {t("available-externally")}
                          </p>
                          <p className=" text-xs font-normal text-secondary-text">
                            {t(
                              "the-asset-remains-bookable-on-external-booking-channels",
                            )}
                          </p>
                        </div>
                        <Switch
                          disabled={!isEditing}
                          checked={currentBookable.available.external}
                          onCheckedChange={(v) => {
                            currentBookable.available.external = v;
                            setAsset({ ...asset });
                          }}
                        />
                      </div>
                      {currentBookable.available.external && (
                        <Textarea
                          disabled={!isEditing}
                          className="mt-2 text-primary-text"
                          rows={5}
                          value={currentBookable.description.external || ""}
                          onChange={(e) => {
                            currentBookable.description.external =
                              e.target.value;
                            setAsset({ ...asset });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Sheet
        open={itemSidebarOpen}
        onOpenChange={(open) => setItemSidebarOpen(open)}
      >
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle>{t("add-fixtures-&-items")}</SheetTitle>
          </SheetHeader>

          <div className="flex space-x-2 border-b pb-4">
            {isItemSearchExpanded || itemSearchTerm ? (
              <SearchBar
                value={itemSearchTerm}
                onChange={(e) => setItemSearchTerm(e.target.value)}
                className="h-10 w-[320px] rounded-md bg-primary-button-backplate px-4 py-2"
                onBlur={() =>
                  setIsItemSearchExpanded((prevState) => !prevState)
                }
                autoFocus
              />
            ) : (
              <Button
                variant="secondary"
                className="border px-3"
                onClick={() =>
                  setIsItemSearchExpanded((prevState) => !prevState)
                }
              >
                <Search size={17} />
              </Button>
            )}
          </div>

          <div>
            <h3 className=" text-sm font-medium text-primary-text">
              {t("fixtures-&-items")}
            </h3>
          </div>

          <div className="flex-grow overflow-y-auto border-b border-border-color shadow-inner">
            <div>
              {fixtures?.map((g) =>
                g.fixtures.length ? (
                  <div key={g.id}>
                    <div className="flex justify-between  p-2 pb-2 pt-8">
                      <p className="text-sm font-normal text-secondary-text">
                        {g.name}
                      </p>
                    </div>
                    <div>
                      {g.fixtures.map((f) => (
                        <div
                          key={f.id}
                          className="flex items-center justify-between p-2"
                        >
                          <div>
                            <p className=" text-sm font-medium text-primary-text">
                              {f.name}
                            </p>
                          </div>

                          {currentBookable?.fixtures?.some(
                            (f1) => f1.id === f.id,
                          ) ? (
                            <Button
                              variant="secondary"
                              className=" bg-secondary-card-backplate"
                              onClick={() => {
                                currentBookable.fixtures =
                                  currentBookable.fixtures.filter(
                                    (f1) => f1.id !== f.id,
                                  );
                                setAsset({
                                  ...asset,
                                });
                              }}
                            >
                              <X size={18} className="mr-2" />
                              {t("added")}
                            </Button>
                          ) : (
                            <Button
                              variant="outline"
                              onClick={() => {
                                currentBookable.fixtures = [
                                  ...currentBookable.fixtures,
                                  { ...f, quantity: 1 }, // TODO: Maybe this is the way to do it? Leaving it for you to fix
                                ];
                                setAsset({
                                  ...asset,
                                });
                              }}
                            >
                              {t("add")}
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null,
              )}
            </div>
          </div>

          <div className=" flex justify-end ">
            <Button onClick={() => setItemSidebarOpen(false)}>
              {t("done")}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
      <ConfirmNavigationDialog
        title={t("changes-not-saved")}
        description={t("you-have-unsaved-changes-that-will-be-lost")}
        shouldWarn={saveIsEnabled && !isDuplicate && !newRoom}
        onProceed={onSave}
      />
      <ConfirmDialog
        proceedBtnText={t("yes")}
        title={t("do-you-really-want-to-deactivate?")}
        description={t(
          "if-you-deactivate-the-asset-will-no-longer-be-bookable-are-you-sure-you-want-to-deactivate",
        )}
        onOpenChange={setIsOpenDeactivateDialog}
        isOpen={isOpenDeactivateDialog}
        onProceed={() => setActive((prev) => !prev)}
      />
      <ConfirmDialog
        proceedBtnText={t("save")}
        title={t("changes-not-saved")}
        description={t("do-you-wish-to-save-or-discard-the-changes?")}
        onOpenChange={setIsOpenEditDialog}
        isOpen={isOpenEditDialog}
        onProceed={onSave}
        onDiscard={handleDiscardChanges}
      />
      {mediaLibraryOpen && (
        <MediaLibrary
          open={mediaLibraryOpen}
          onOpenChange={setMediaLibraryOpen}
          tag="bookable"
          onImagesSelected={(imgs) => {
            setAsset({
              ...asset,
              images: imgs.map((i) => ({ ...i, variant: "thumbnail" })),
            });
            setMediaLibraryOpen(false);
          }}
          initialSelection={asset.images.map((i) => ({
            ...i,
            id: String(i.id),
          }))}
        />
      )}
    </>
  );
};

export default BedDetailsPage;
