import { useTranslation } from "react-i18next";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { format } from "date-fns";
import { GetReservation } from "../../../../../../api-contracts/reservations";
import { Changeset, IChange, Operation } from "json-diff-ts";
import { cn } from "@shared/utils/css";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  reservation: GetReservation;
}

export const ReservationHistoryDrawer = ({
  open,
  onOpenChange,
  reservation,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("history")}
            </SheetTitle>
          </SheetHeader>

          <div className=" h-full overflow-y-auto">
            <Accordion type="single" collapsible>
              {reservation.audit.map((audit, i) => (
                <AccordionItem
                  key={audit.at}
                  value={audit.at}
                  className=" border-none"
                >
                  <AccordionTrigger className=" h-14 py-0 ">
                    <div className=" flex w-full items-center justify-between">
                      <div className=" relative flex h-full flex-nowrap items-center">
                        <div
                          className={cn(
                            " absolute left-[93px] w-[2px] bg-secondary-text",
                            {
                              " top-1/2 h-[28px]":
                                i === 0 && reservation.audit.length > 1,
                              " h-[56px]":
                                i > 0 &&
                                i < reservation.audit.length - 1 &&
                                reservation.audit.length > 1,
                              "bottom-1/2 h-[28px] ":
                                i === reservation.audit.length - 1 &&
                                reservation.audit.length > 1,
                            },
                          )}
                        />
                        <div className="w-[90px] text-xs font-medium">
                          {format(audit.at, "yyyy-MM-dd")}
                        </div>
                        <div className=" h-2 w-2 rounded-full bg-primary-text" />
                        <div className=" mx-2 text-xs font-medium">
                          {format(audit.at, "HH:mm")}
                        </div>
                      </div>
                      <div className=" flex items-center space-x-2">
                        <div className=" mx-2 text-xs font-normal text-secondary-text">
                          {audit.user.name}
                        </div>
                        <div className=" bg-secondary-card-backplate p-2 text-xs font-extrabold">
                          {t("changed")}
                        </div>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className=" rounded-md border-2 border-primary-text p-4 text-xs font-normal ">
                      {(audit.diff as Changeset).map((change) => (
                        <Change
                          key={change.key + change.type}
                          change={change}
                          level={0}
                        />
                      ))}
                      {!(audit.diff as Changeset).length && (
                        <p className=" text-sm font-normal">
                          {t("saved-with-no-changes")}
                        </p>
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
            {!reservation.audit?.length && (
              <div className=" p-2 text-sm font-normal">
                <p>{t("no-history-found")}</p>
              </div>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

interface ChangeProps {
  change: IChange;
  level: number;
}

const Change = ({ change, level }: ChangeProps) => {
  if (change.type === Operation.REMOVE) {
    return (
      <div style={{ marginLeft: `${level * 10}px` }}>
        <strong>{change.type}</strong>{" "}
        <span>
          {change.key}
          {change.embeddedKey ? `.${change.embeddedKey?.toString()}` : ""}
        </span>
      </div>
    );
  } else if (change.changes) {
    return (
      <div style={{ marginLeft: `${level * 10}px` }}>
        <strong>{change.type}</strong>{" "}
        <span>
          {change.key}
          {change.embeddedKey ? `.${change.embeddedKey?.toString()}` : ""}:{" "}
          {"{"}{" "}
        </span>
        {change.changes.map((c) => (
          <Change level={level + 1} key={c.key + c.type} change={c} />
        ))}
        <p>{"}"}</p>
      </div>
    );
  } else {
    if (change.type === Operation.ADD) {
      return (
        <div style={{ marginLeft: `${level * 10}px` }}>
          <strong>{change.type}</strong>{" "}
          <span>
            {change.key}
            {change.embeddedKey
              ? `.${change.embeddedKey?.toString()}`
              : ""} = {JSON.stringify(change.value)}
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: `${level * 10}px` }}>
          <strong>{change.type}</strong>{" "}
          <span>
            {change.key}
            {change.embeddedKey ? `.${change.embeddedKey?.toString()}` : ""}
          </span>{" "}
          <p>
            {JSON.stringify(change.oldValue)} {"->"}{" "}
            {JSON.stringify(change.value)}
          </p>
        </div>
      );
    }
  }
};

export default ReservationHistoryDrawer;
