import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { ReservationState } from "../../../../../../api-contracts/reservations";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (state: ReservationState) => void | Promise<void>;
}

export const ConfirmReservationDialog = ({
  open,
  onOpenChange,
  onProceed,
}: Props) => {
  const { t } = useTranslation();

  const handleUpdateState = async (
    state: "confirmed" | "unconfirmed" | "preliminary" | "guaranteed",
  ) => {
    onOpenChange(false);
    onProceed(state);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("save-reservation-as")}
            </DialogTitle>

            <div className=" mt-4 flex space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={() => handleUpdateState("unconfirmed")}
              >
                {t("unconfirmed")}
              </Button>
              <Button
                variant="primary"
                onClick={() => handleUpdateState("confirmed")}
              >
                {t("confirmed")}
              </Button>
              <Button
                variant="primary"
                onClick={() => handleUpdateState("guaranteed")}
              >
                {t("guaranteed")}
              </Button>
              <Button
                variant="primary"
                onClick={() => handleUpdateState("preliminary")}
              >
                {t("preliminary")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmReservationDialog;
