import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import { ArrivalsCardHeader } from "./components/arrivals-card-header";

import {
  CategoryTypeEnum,
  ReceptionContextProvider,
} from "../reception-context";
import { TableContainer } from "./components/arrivals-table-container";
import { useProfileContext } from "@context/profile-context";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@shared/types/navigation";

const ArrivalsPage = ({ tab }: { tab: CategoryTypeEnum }) => {
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const tabItems = [
    {
      value: "all",
      label: t("all"),
      to: `/${module}/${ROUTES.ARRIVALS}`,
    },
    {
      value: "hotel",
      label: t("hotel"),
      to: `/${module}/${ROUTES.ARRIVALS_HOTEL}`,
    },
    {
      value: "area",
      label: t("area"),
      to: `/${module}/${ROUTES.ARRIVALS_AREA}`,
    },
    {
      value: "hostel",
      label: t("hostel"),
      to: `/${module}/${ROUTES.ARRIVALS_HOSTEL}`,
    },
  ];
  return (
    <ReceptionContextProvider categoryType={tab} page="arrivals">
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <CategoryTabs tab={tab} tabItems={tabItems} />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <ArrivalsCardHeader />
          <TableContainer />
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};

export default ArrivalsPage;
