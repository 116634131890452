import { useGetAllocableBookablesWithCategories } from "@api/bookables";
import { Space } from "@components/space";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { Input } from "@primitives/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { addDays, eachDayOfInterval, format, subDays } from "date-fns";
import { Minus, Plus } from "lucide-react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CategoryTypeEnum, useReceptionContext } from "../reception-context";
import { useCreateReservation } from "@api/reservations";
import {
  Booking,
  BookingGuestPayload,
  CreateReservation,
} from "../../../../../api-contracts/reservations";
import { useGetBedTypes } from "@api/bed-types";
import { CategoryType } from "../../../../../api-contracts/categories";
import {
  CalculateAllocableBookablesWithCategoriesResponse,
  Child,
} from "../../../../../api-contracts/bookables/allocable";
import { Loading } from "@primitives/loading";
import { getAgeType } from "@shared/utils/helpers";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import { TimeslotPayload } from "../../../../../api-contracts/reservations/timeslots";

const ICON_STROKE_WIDTH = 1.5;
export const WalkInSideSheet = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { date, categoryType } = useReceptionContext();

  const [numberOfNights, setNumberOfNights] = useState<number>(1);
  const [numberOfAdults, setNumberOfAdults] = useState<number>(1);
  const [children, setChildren] = useState<Child[]>([]);
  const [selectedBookable, setSelectedBookable] = useState<{
    categoryId: string;
    bookableId: string;
  }>({ categoryId: "", bookableId: "" });

  const startDate = format(date, "yyyy-MM-dd");
  const endDate = format(addDays(date, numberOfNights), "yyyy-MM-dd");

  const { mutateAsync: handleCreateReservation } = useCreateReservation();

  const shouldEnableGetAllocable = (): boolean => {
    const hasChildren = children.length > 0;
    const allChildrenHasBedPreference =
      hasChildren && children.every((child) => child.bedPreference !== "");

    return !hasChildren || allChildrenHasBedPreference;
  };
  const shouldEnableCreateReservation: boolean =
    selectedBookable.categoryId.length > 0;

  const mapCategoryTypeToQuery = (
    categoryType: CategoryTypeEnum,
  ): CategoryType[] => {
    // TODO: Make it possible to support campr
    switch (categoryType) {
      case CategoryTypeEnum.ALL:
        return ["room", "area", "bed", "dormitory"];
      case CategoryTypeEnum.HOTEL:
        return ["room"];
      case CategoryTypeEnum.AREA:
        return ["area"];
      case CategoryTypeEnum.HOSTEL:
        return ["bed", "dormitory"];
      default:
        return ["room", "area", "bed", "dormitory"];
    }
  };
  const categoryTypeQuery: CategoryType[] =
    mapCategoryTypeToQuery(categoryType);

  const {
    data: bookablesGroupedWithCategories,
    isLoading: bookablesIsLoading,
  } = useGetAllocableBookablesWithCategories({
    variables: {
      startDate,
      endDate,
      adults: numberOfAdults,
      children,
      types: categoryTypeQuery,
    },
    enabled: shouldEnableGetAllocable(),
  });

  useEffect(() => {
    if (bookablesGroupedWithCategories) {
      setSelectedBookable({ categoryId: "", bookableId: "" });
    }
  }, [bookablesGroupedWithCategories]);

  const createReservation = async () => {
    const numTeenagers = children.filter(
      (child) => getAgeType(child.age) === "teenager",
    ).length;
    const numInfants = children.filter(
      (child) => getAgeType(child.age) === "infant",
    ).length;
    const numChildren = children.filter(
      (child) => getAgeType(child.age) === "child",
    ).length;

    const dates = eachDayOfInterval({
      start: startDate,
      end: subDays(endDate, 1),
    });

    const slots: TimeslotPayload[] = dates.map((date) => {
      return {
        startDate: format(date, "yyyy-MM-dd"),
        startTime: "14:00",
        priceOverrideAmount: null,
        priceAdjustmentPercent: null,
      };
    });

    const booking: Booking = {
      slots,
      categoryId: selectedBookable.categoryId,
      bookableId: selectedBookable.bookableId,
      numAdults: numberOfAdults,
      numChildren,
      numInfants,
      numTeenagers,
      guests: [
        ...children.map((child) => {
          return {
            bedPreferenceId: child.bedPreference,
            ageType: getAgeType(child.age),
            checkin: startDate,
            checkout: endDate,
          } as BookingGuestPayload;
        }),
        ...Array.from(
          { length: numberOfAdults },
          () =>
            ({
              bedPreferenceId: null,
              ageType: "adult",
              checkin: startDate,
              checkout: endDate,
            }) as BookingGuestPayload,
        ),
      ],
    };

    const reservation: CreateReservation = {
      confirmationSphereId: null,
      noteIds: undefined,
      holderId: null,
      targetGroupId: null,
      travelAgencyId: null,
      guestOrganizationId: null,
      bookings: [booking],
      langCode: null,
      cardNumber: null,
    };
    try {
      await handleCreateReservation(reservation);
      toast({
        title: t("Reservation created - Should navigate to payment"),
        variant: "success",
        description: "TODO",
      });
      queryClient.invalidateQueries();
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return (
    <DefaultSideSheet
      title={t("walk-in")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className="h-full overflow-y-scroll rounded-md bg-secondary-card-backplate p-4 text-primary-text">
        <div>
          <Counter
            numberOfNights={numberOfNights}
            setNumberOfNights={setNumberOfNights}
          />
        </div>
        <div className="my-4">
          <Guests
            numberOfAdults={numberOfAdults}
            setNumberOfAdults={setNumberOfAdults}
            children={children}
            setChildren={setChildren}
          />
        </div>
        <div className="my-4">
          {bookablesIsLoading ? (
            <Loading />
          ) : (
            <BookableSelector
              bookables={bookablesGroupedWithCategories}
              isLoading={bookablesIsLoading}
              selectedBookable={selectedBookable}
              setSelectedBookable={setSelectedBookable}
              shouldEnable={shouldEnableGetAllocable()}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          disabled={!shouldEnableCreateReservation}
          onClick={() => createReservation()}
        >
          {t("create-reservation")}
        </Button>
      </div>
    </DefaultSideSheet>
  );
};

const Counter = ({
  numberOfNights,
  setNumberOfNights,
}: {
  numberOfNights: number;
  setNumberOfNights: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const onIncrease = () => {
    setNumberOfNights((prev) => prev + 1);
  };
  const onDecrease = () => {
    setNumberOfNights((prev) => (prev > 1 ? prev - 1 : 1));
  };
  return (
    <>
      <p className="text-sm font-extrabold">{t("number-of-nights")}</p>

      <div className="border-main-component-color mt-1 flex w-[130px] items-center justify-center rounded-lg border bg-primary-card-backplate">
        <Button size={"icon"} variant={"ghost"} onClick={() => onDecrease()}>
          <Minus strokeWidth={ICON_STROKE_WIDTH} />
        </Button>
        <span className="mx-1 flex min-w-[40px] items-center justify-center rounded-lg border p-1">
          {numberOfNights}
        </span>
        <Button size={"icon"} variant={"ghost"} onClick={() => onIncrease()}>
          <Plus strokeWidth={ICON_STROKE_WIDTH} />
        </Button>
      </div>
    </>
  );
};

const Guests = ({
  numberOfAdults,
  setNumberOfAdults,
  children,
  setChildren,
}: {
  numberOfAdults: number;
  setNumberOfAdults: Dispatch<SetStateAction<number>>;
  children: Child[];
  setChildren: Dispatch<SetStateAction<Child[]>>;
}) => {
  const { t } = useTranslation();
  const { data: bedData } = useGetBedTypes();

  const numberOfChildren = children.length;

  const onAdultsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNumberOfAdults(Number(event.target.value));
  };

  const onChildrenChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNumberOfChildren = Number(event.target.value);

    setChildren((prevChildren) => {
      const updatedChildren = [...prevChildren];

      if (newNumberOfChildren > prevChildren.length) {
        for (let i = prevChildren.length; i < newNumberOfChildren; i++) {
          updatedChildren.push({ age: 0, bedPreference: "" });
        }
      } else if (newNumberOfChildren < prevChildren.length) {
        updatedChildren.length = newNumberOfChildren;
      }
      return updatedChildren;
    });
  };

  return (
    <div className="flex flex-col ">
      <p className="text-sm font-extrabold">{t("guests")}</p>

      <div className="mt-1 flex ">
        <div className="flex w-full flex-col">
          <p className="text-xs">{t("number-of-adults")}</p>
          <Input
            min={1}
            type="number"
            placeholder={t("number-of-adults")}
            onChange={onAdultsChange}
            value={numberOfAdults === 0 ? "" : numberOfAdults}
          />
        </div>

        <Space w={16} />

        <div className="w-full">
          <p className="text-xs">{t("number-of-children")}</p>
          <Input
            min={0}
            type="number"
            placeholder={t("number-of-children")}
            onChange={onChildrenChange}
            value={numberOfChildren === 0 ? "" : numberOfChildren}
          />
        </div>
      </div>

      <div className="flex-grow">
        {children.map((child, index) => (
          <div key={index} className="my-4 flex flex-col">
            <p className="text-sm font-extrabold">
              {t("child-{{count}}", { count: index + 1 })}
            </p>
            <div className="mt-1 flex items-center">
              <div className="flex w-1/2 flex-col">
                <p className="text-xs">{t("age")}</p>
                <Select
                  value={String(child.age)}
                  onValueChange={(value) => {
                    const newAge = Number(value);
                    setChildren((prevChildren) => {
                      const updatedChildren = [...prevChildren];
                      updatedChildren[index] = {
                        ...updatedChildren[index],
                        age: newAge,
                      };
                      return updatedChildren;
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("age")} />
                  </SelectTrigger>
                  <SelectContent className="z-[200]">
                    {Array.from({ length: 18 }, (_, index) => {
                      const value = String(index);
                      return (
                        <SelectItem key={value} value={value}>
                          {value}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>

              <Space w={16} />

              <div className="w-1/2 flex-col">
                <p className="text-xs">
                  {t("bed-preference")}
                  {<span className="ml-1 text-status-error">*</span>}
                </p>
                <Select
                  value={child.bedPreference}
                  onValueChange={(value) => {
                    setChildren((prevChildren) => {
                      const updatedChildren = [...prevChildren];
                      updatedChildren[index] = {
                        ...updatedChildren[index],
                        bedPreference: value,
                      };
                      return updatedChildren;
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("bed-preference")} />
                  </SelectTrigger>
                  <SelectContent className="z-[200] flex flex-col">
                    {bedData &&
                      bedData.map((bed) => (
                        <SelectItem value={String(bed.id)} key={bed.id}>
                          {bed.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const BookableSelector = ({
  bookables,
  isLoading,
  selectedBookable,
  setSelectedBookable,
  shouldEnable,
}: {
  bookables: CalculateAllocableBookablesWithCategoriesResponse | undefined;
  isLoading: boolean;
  selectedBookable: { categoryId: string; bookableId: string };
  setSelectedBookable: Dispatch<
    SetStateAction<{ categoryId: string; bookableId: string }>
  >;
  shouldEnable: boolean;
}) => {
  const { t } = useTranslation();

  const handleValueChange = (bookableId: string) => {
    const foundCategory = bookables?.categories.find((category) =>
      category.bookables.some((bookable) => bookable.id === bookableId),
    );

    if (foundCategory) {
      setSelectedBookable({
        categoryId: foundCategory.id,
        bookableId: bookableId,
      });
    }
  };

  const findSelectedBookable = (selectedBookableId: string) => {
    const foundBookable = bookables?.categories
      .flatMap((category) => category.bookables)
      .find((bookable) => bookable.id === selectedBookableId);

    return foundBookable;
  };

  const foundSelectedBookable = findSelectedBookable(
    selectedBookable.bookableId,
  );

  return (
    <>
      <p className="mb-1 text-sm font-extrabold">
        {t("unit")}
        <span className="ml-1 font-normal text-status-error">*</span>
      </p>

      <Select onValueChange={handleValueChange}>
        <SelectTrigger disabled={!shouldEnable}>
          <>
            <SelectValue placeholder={t("choose-unit")}>
              {foundSelectedBookable?.name ? (
                foundSelectedBookable.name
              ) : (
                <p className="text-secondary-text">{t("choose-unit")}</p>
              )}
              {isLoading && (
                <div className="relative">
                  <div className="absolute">
                    <Loading />
                  </div>
                </div>
              )}
            </SelectValue>
          </>
        </SelectTrigger>
        <SelectContent className="z-[200]">
          {bookables?.categories.map((groupedBookable) => {
            return (
              <SelectGroup key={groupedBookable.id}>
                <SelectLabel className="py-2 text-sm font-extrabold">
                  {groupedBookable.name}
                </SelectLabel>
                {groupedBookable.bookables.map((bookable) => {
                  return (
                    <SelectItem value={bookable.id} key={bookable.id}>
                      {bookable.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            );
          })}
        </SelectContent>
      </Select>
    </>
  );
};
