import { GetPriceRulesCategoryOverride } from "../../../../../../api-contracts/price-rules";
import { EventFor } from "@shared/types/props.ts";
import { RefObject } from "react";
import { concat } from "lodash";

export const getCategoryOverrideSorted = (
  override: GetPriceRulesCategoryOverride,
) => {
  const list = [
    {
      quantity: override.adults,
      tooltip: "adults",
      display: "override-adults-display",
      displayItem: { adults: override.adults },
    },
    {
      quantity: override.teenagers,
      tooltip: "teenagers",
      display: "override-teenagers-display",
      displayItem: { teenagers: override.teenagers },
    },
    {
      quantity: override.children,
      tooltip: "children",
      display: "override-children-display",
      displayItem: { children: override.children },
    },
    {
      quantity: override.infants,
      tooltip: "infants",
      display: "override-infants-display",
      displayItem: { infants: override.infants },
    },
  ];

  const values = list.filter((it) => it.quantity > 0);
  const nonValues = list.filter((it) => it.quantity === 0);

  return concat(values, nonValues);
};

export const toCurrencyFormat = (item: number | null, language: string) => {
  if (item === null || item === undefined) {
    return null;
  }

  return new Intl.NumberFormat(language === "sv-se" ? "sv-SE" : "en-GB", {
    maximumFractionDigits: 2,
  }).format(item);
};

export const onScroll = (
  e: EventFor<"section", "onScroll">,
  gridRef: RefObject<HTMLDivElement>,
) => {
  const container = gridRef.current as HTMLDivElement;
  const el = e.target as HTMLDivElement;

  const { scrollLeft } = el;
  const header = container.querySelector("[data-fixed]") as HTMLDivElement;
  header?.classList.toggle("shadow-2xl", scrollLeft > 0);
};

export const onKeyDown = (
  e: EventFor<"div", "onKeyDown">,
  gridRef: RefObject<HTMLDivElement>,
) => {
  const el = e.target as HTMLButtonElement;
  const container = gridRef.current as HTMLDivElement;
  if (!container || !el.dataset.row || !el.dataset.col) return;

  const row = Number(el.dataset.row),
    col = Number(el.dataset.col);

  if (e.key === "ArrowUp") {
    if (row === 0) return;

    const up = container.querySelector(
      `[data-row="${row - 1}"][data-col="${col}"]`,
    ) as HTMLButtonElement;
    up?.focus();
  }
  if (e.key === "ArrowDown") {
    const down = container.querySelector(
      `[data-row="${row + 1}"][data-col="${col}"]`,
    ) as HTMLButtonElement;
    down?.focus();
  }
  if (e.key === "ArrowLeft") {
    const to = col - 1;
    if (to <= 1) return; // weird scroll stuff happens if we go here

    const left = container.querySelector(
      `[data-row="${row}"][data-col="${col - 1}"]`,
    ) as HTMLButtonElement;
    left?.focus();
  }
  if (e.key === "ArrowRight") {
    const right = container.querySelector(
      `[data-row="${row}"][data-col="${col + 1}"]`,
    ) as HTMLButtonElement;
    right?.focus();
  }
};
