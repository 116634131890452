import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { FilterIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Checkbox } from "@primitives/checkbox.tsx";
import { usePriceRulesPageContext } from "@pages/booking/price-rules/price-rules-context.tsx";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Button } from "@primitives/button.tsx";
import { SearchBar } from "@primitives/search-bar.tsx";

export const PriceRuleFilter = () => {
  const {
    sharedState: { priceRules, filter, activeFilters },
    sharedFunctions: { setFilter, setFilterDefault, setActiveFilters },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [barList, setBarList] = useState<string[]>(
    priceRules.rules.map(({ name }) => name),
  );

  useEffect(() => {
    search === ""
      ? setBarList(priceRules.rules.map(({ name }) => name))
      : setBarList((prev) =>
          priceRules.rules
            .filter(({ name }) => name.includes(search))
            .map(({ name }) => name),
        );
  }, [priceRules.rules, search, setBarList]);

  const getCheckedStatus = (value: "active" | "inactive") => {
    return filter.status.filter((it) => it === value).length > 0;
  };

  const setCheckedStatus = (
    value: "active" | "inactive",
    checked: CheckedState,
  ) => {
    setFilter((prev) => {
      return {
        ...prev,
        status: !checked
          ? prev.status.filter((it) => it !== value)
          : [...prev.status, value],
      };
    });
  };

  const getActiveChecked = (value: string) => {
    return activeFilters.includes(value);
  };

  const setActiveChecked = (value: string, checked: CheckedState) => {
    setActiveFilters((prev) =>
      !checked ? prev.filter((it) => it !== value) : [...prev, value],
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    {
      setSearch(e.target.value.toUpperCase());
    }
  };

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={() => setOpen(false)}>
        <DropdownMenu.Trigger asChild>
          <Button
            variant="outline"
            className="flex gap-2"
            onClick={() => setOpen(true)}
          >
            <FilterIcon size={18} />
            <span>{t("show")}</span>
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex gap-2 p-2">
              <Checkbox
                value={"status"}
                checked={getActiveChecked("status")}
                onCheckedChange={(checked) =>
                  setActiveChecked("status", checked)
                }
              />
              <span>{t("status")}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem className="flex gap-2 p-2">
                  <Checkbox
                    value={"status-active"}
                    checked={getCheckedStatus("active")}
                    onCheckedChange={(checked) =>
                      setCheckedStatus("active", checked)
                    }
                  />
                  <span>{t("active")}</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex gap-2 p-2">
                  <Checkbox
                    value={"status-inactive"}
                    checked={getCheckedStatus("inactive")}
                    onCheckedChange={(checked) =>
                      setCheckedStatus("inactive", checked)
                    }
                  />
                  <span>{t("inactive")}</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>

          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex gap-2 p-2">
              <Checkbox
                value={"category"}
                checked={getActiveChecked("categories")}
                onCheckedChange={(checked) =>
                  setActiveChecked("categories", checked)
                }
              />
              <span>{t("category")}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {priceRules.categories.map((cat) => (
                  <DropdownMenuItem className="flex gap-2" key={cat.id}>
                    <Checkbox
                      value={cat.name}
                      checked={filter.categories.some((id) => id === cat.id)}
                      onCheckedChange={(checked) =>
                        setFilter((prev) => {
                          return {
                            ...prev,
                            categories: checked
                              ? [...prev.categories, cat.id]
                              : prev.categories.filter((it) => it !== cat.id),
                          };
                        })
                      }
                    />
                    <span>
                      {t(cat.name)}({cat.short})
                    </span>
                  </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator />
                <div
                  onClick={() =>
                    setFilter((prev) => {
                      return {
                        ...prev,
                        categories: priceRules.categories.map(({ id }) => id),
                      };
                    })
                  }
                  className="flex cursor-pointer justify-center text-sm  font-bold hover:bg-secondary-card-backplate "
                >
                  {t("select-all")}
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>

          <DropdownMenuSub onOpenChange={() => setSearch("")}>
            <DropdownMenuSubTrigger className="flex gap-2 p-2">
              <Checkbox
                value={"BAR"}
                checked={getActiveChecked("bar")}
                onCheckedChange={(checked) => setActiveChecked("bar", checked)}
              />
              <span>{t("BAR")}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <SearchBar
                  value={search}
                  onChange={onChange}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <DropdownMenuSeparator />
                <div className="max-h-[200px] overflow-y-auto">
                  {barList.map((rule, i) => (
                    <DropdownMenuItem
                      className="flex gap-2"
                      key={`${rule}-${i}`}
                    >
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        value={rule}
                        checked={filter.bar.some((name) => name === rule)}
                        onCheckedChange={(checked) =>
                          setFilter((prev) => {
                            return {
                              ...prev,
                              bar: checked
                                ? [...prev.bar, rule]
                                : prev.bar.filter((name) => name !== rule),
                            };
                          })
                        }
                      />
                      <span>{t(rule)}</span>
                    </DropdownMenuItem>
                  ))}
                </div>
                <DropdownMenuSeparator />
                <div
                  onClick={() =>
                    setFilter((prev) => {
                      return {
                        ...prev,
                        bar: priceRules.rules.map(({ name }) => name),
                      };
                    })
                  }
                  className="flex cursor-pointer justify-center p-2  text-sm font-bold hover:bg-secondary-card-backplate "
                >
                  {t("select-all")}
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenu.Separator />
          <div
            onClick={() => setFilterDefault()}
            className="flex cursor-pointer justify-center p-2 text-sm font-bold hover:bg-secondary-card-backplate"
          >
            {t("reset")}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};
