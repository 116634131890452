import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { Input } from "@primitives/input.tsx";
import PricingGrid from "@pages/booking/price-rules/components/pricing-grid.tsx";
import { Button } from "@primitives/button.tsx";
import { t } from "i18next";

type PriceProps = {
  open: boolean;
  title: string;
  onSubmit: (event: any) => void;
  display: PriceRuleActionType;
};
export const PriceRulesSheet = ({
  title,
  open,
  onSubmit,
  display,
}: PriceProps) => {
  const {
    sharedState: { bar, priceRules },
    sharedFunctions: { setActionType, setBar },
  } = usePriceRulesPageContext();

  return (
    <Sheet
      open={open}
      onOpenChange={() => setActionType(PriceRuleActionType.DEFAULT)}
    >
      <SheetContent className="flex h-full w-[690px] flex-col p-4">
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <form
          onSubmit={onSubmit}
          className=" flex h-[calc(100%-60px)] flex-col justify-between"
        >
          <div className="bg-secondary-card-backplate p-4">
            <Input
              placeholder={`${t("name")}*`}
              value={bar.name}
              onChange={(e) =>
                setBar((prev) => {
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                })
              }
            />
          </div>
          <div className=" flex-1 overflow-y-auto bg-secondary-card-backplate p-4">
            <PricingGrid
              readOnly={true}
              categories={priceRules.categories}
              display={display}
            />
          </div>
          <div className=" mt-4 flex flex-shrink-0 items-center justify-end ">
            <Button type="submit" disabled={!bar.name}>
              {t("save")}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
};
