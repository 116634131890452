import { cn } from "@utils/css.ts";
import { ChevronDown, ChevronsDownUp, ChevronsUpDown } from "lucide-react";
import { createRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseProps, PropsOf, WithAsProps } from "@shared/types/props.ts";
import {
  getCategoryOverrideSorted,
  onKeyDown,
  onScroll,
  toCurrencyFormat,
} from "@pages/booking/price-rules/utils/price-rules-utils.ts";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { GetPriceRuleCategory } from "../../../../../../api-contracts/price-rules";
import { PriceRulesEditGrid } from "@pages/booking/price-rules/components/price-rules-edit-grid.tsx";
import { PriceRulesDisplayGrid } from "@pages/booking/price-rules/components/price-rules-display-grid.tsx";
import { Tooltip } from "@primitives/tooltip.tsx";
import { Collapsible } from "@primitives/collapsible";

export const PricingGrid = ({
  readOnly,
  categories,
  display,
}: {
  readOnly: boolean;
  categories: GetPriceRuleCategory[];
  display: PriceRuleActionType;
}) => {
  const {
    sharedState: { priceRules, showStatus, expanded },
    sharedFunctions: { setExpanded },
  } = usePriceRulesPageContext();
  const { t, i18n } = useTranslation();
  const gridRef = createRef<HTMLDivElement>();
  const bcIds = priceRules.categories.map((x) => x.id);

  const onExpand = (id: string | null) => {
    if (!id) {
      // expand all if none are expanded
      if (expanded?.length === 0) {
        setExpanded(bcIds);
        return;
      }

      // collapse all if all are expanded
      setExpanded([]);
      return;
    }

    setExpanded((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id],
    );
  };

  const getBasePrice = (c: GetPriceRuleCategory) => {
    return c.overrides.find(
      (it) =>
        it.adults === 1 &&
        it.infants === 0 &&
        it.teenagers === 0 &&
        it.children === 0,
    )?.price;
  };

  return (
    <div
      className="grid grid-cols-[1fr_3fr]"
      ref={gridRef}
      onKeyDown={(e) => onKeyDown(e, gridRef)}
    >
      <div data-fixed className="sticky">
        <GridRow
          className={cn(
            "grid-cols-[12rem_1fr]",
            !readOnly && showStatus && "h-28",
          )}
        >
          <GridItem
            key={`0-0`}
            data-row={0}
            data-col={0}
            className={cn(
              "cursor-pointer",
              !readOnly && showStatus && "h-28 items-start pt-5",
            )}
            onClick={onExpand.bind(null, null)}
          >
            <span className="truncate text-[15px] text-secondary-text">
              {t("category")}
            </span>
            {expanded?.length ? (
              <ChevronsDownUp className="ml-3 h-4 w-4 shrink-0 stroke-gray-500" />
            ) : (
              <ChevronsUpDown className="ml-3 h-4 w-4 shrink-0 stroke-gray-500" />
            )}
          </GridItem>

          <GridItem
            key={`0-1`}
            data-row={0}
            data-col={1}
            className={cn(
              "bg-screen-background",
              !readOnly && showStatus && "h-28 items-start pt-5",
            )}
          >
            <span className="truncate text-[15px] text-secondary-text">
              {t("minimum-rate")}
            </span>
          </GridItem>
        </GridRow>

        {categories.map((c, rowIdx) => (
          <div key={`bt-${c.id}-row-${rowIdx}`}>
            <GridRow
              className={cn("grid-cols-[12rem_1fr]", rowIdx % 2 === 0 && "")}
              data-row={rowIdx + 1}
            >
              <GridItem
                data-row={rowIdx + 1}
                data-col={0}
                className={(c.overrides.length > 0 && "cursor-pointer") || ""}
                onClick={(e) => {
                  onExpand.bind(null, c.id).apply(e);
                  e.preventDefault();
                }}
              >
                <span className="truncate text-[15px]">{c.short}</span>
                {c.overrides.length > 0 && (
                  <ChevronDown
                    className={cn(
                      "ml-3 h-4 w-4 shrink-0 stroke-gray-500 transition-transform",
                      expanded.includes(c.id) ? "rotate-180" : "rotate-0",
                    )}
                  />
                )}
              </GridItem>
              <GridItem
                data-row={rowIdx + 1}
                data-col={1}
                className={cn(
                  "flex grid-cols-[10rem_1fr] justify-end gap-2 bg-screen-background",
                )}
              >
                <p>
                  {toCurrencyFormat(
                    getBasePrice(c) ?? c.minimumRate,
                    i18n.language,
                  )}
                </p>
                <p className="text-xs text-secondary-text">SEK</p>
              </GridItem>
            </GridRow>

            <Collapsible.Root open={expanded.includes(c.id)}>
              <Collapsible.Content
                style={{
                  minWidth: `${c.overrides.length}rem`,
                }}
              >
                {c.overrides.map((o, i) => (
                  <GridRow
                    key={`bt-${o.id}-row-${rowIdx}-col-${i}`}
                    className="grid-cols-[12rem_1fr]"
                  >
                    <GridItem>
                      {getCategoryOverrideSorted(o).map((sorted, i) => (
                        <div key={i}>
                          {sorted.quantity > 0 && (
                            <Tooltip content={t(sorted.tooltip)}>
                              <div className="cursor-default rounded py-1 text-center">
                                {t(sorted.display, sorted.displayItem)}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      ))}
                    </GridItem>
                    <GridItem className="flex justify-end gap-2 bg-secondary-card-backplate">
                      <p className="text-base">
                        {toCurrencyFormat(o.price, i18n.language)}
                      </p>
                      {o.price && (
                        <p className="text-xs text-secondary-text">SEK</p>
                      )}
                    </GridItem>
                  </GridRow>
                ))}
              </Collapsible.Content>
            </Collapsible.Root>
          </div>
        ))}
      </div>

      <main
        className="w-full overflow-x-scroll"
        onScroll={(e) => onScroll(e, gridRef)}
      >
        {display === PriceRuleActionType.ADD ||
        display === PriceRuleActionType.EDIT ? (
          <PriceRulesEditGrid />
        ) : (
          <PriceRulesDisplayGrid />
        )}
      </main>
    </div>
  );
};

export default PricingGrid;

export const GridRow = ({ className, hidden, ...props }: BaseProps) =>
  hidden ? null : (
    <div
      className={cn(
        "grid h-16 auto-cols-[8rem] grid-flow-col border-b",
        className,
      )}
      {...props}
    />
  );

interface GridItemProps extends WithAsProps, PropsOf<"div"> {}
export const GridItem = forwardRef(
  ({ as: Comp = "div", className, ...props }: GridItemProps, ref) => (
    <Comp
      ref={ref}
      className={cn(
        "flex h-16 w-full items-center justify-between px-4",
        "border-b bg-transparent",
        "-outline-offset-1 focus:rounded focus:outline focus:outline-1 focus:outline-gray-500",
        "transition-colors duration-75 ease-in-out",
        className,
      )}
      {...props}
    />
  ),
);

export const GridItemInput = forwardRef<HTMLInputElement, BaseProps>(
  ({ className, ...props }, ref) => (
    <input
      ref={ref}
      className={cn(
        "flex h-16 w-full items-center justify-between px-4",
        "border-b border-r bg-transparent",
        "-outline-offset-2 focus:outline focus:outline-2 focus:outline-purple",
        "transition-colors duration-75 ease-in-out",
        className,
      )}
      {...props}
    />
  ),
);

GridItemInput.displayName = "GridItemInput";
