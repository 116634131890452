import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { useEffect, useMemo, useState } from "react";
import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isSameDay,
  subDays,
} from "date-fns";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { Label } from "@primitives/label";
import { useGetPriceRules } from "@api/price-rules";
import { TimeSlot } from "../../../../../../api-contracts/reservations/timeslots";
import { uniqueId } from "lodash";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (updatedBooking: GetBooking) => void;
  booking: GetBooking;
}

export const BookingChangePriceCodeDrawer = ({
  open,
  onOpenChange,
  onProceed,
  booking,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [priceOption, setPriceOption] = useState<"all" | "remaining">(
    "remaining",
  );
  const [changeFrom, setChangeFrom] = useState<string | Date>(
    priceOption === "all" ? changedBooking.checkin : new Date(),
  );

  useEffect(() => {
    setChangedBooking(booking);

    handlePriceOptionChange(priceOption);
  }, [booking, open]);

  const handlePriceOptionChange = (option: "all" | "remaining") => {
    setPriceOption(option);
    const changeFrom = option === "all" ? changedBooking.checkin : new Date();
    setChangeFrom(changeFrom);

    if (option === "all") {
      updateState(
        changedBooking.slots.map((s) => {
          return {
            date: s.start,
            priceRuleId: s.priceRuleId,
            priceOverrideAmount: null,
            priceAdjustmentPercent: null,
          };
        }),
      );
    } else {
      updateState(
        changedBooking.slots.map((s) => {
          const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);
          if (oldSlot && isAfter(oldSlot.start, subDays(changeFrom, 1))) {
            return {
              date: s.start,
              priceRuleId: s.priceRuleId,
              priceOverrideAmount: null,
              priceAdjustmentPercent: null,
            };
          } else {
            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount: oldSlot?.priceOverrideAmount || null,
              priceAdjustmentPercent: oldSlot?.priceAdjustmentPercent || null,
            };
          }
        }),
      );
    }
  };

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({});

  const updateState = (
    rules: {
      date: string | Date;
      priceRuleId: string | null;
      priceOverrideAmount: number | null;
      priceAdjustmentPercent: number | null;
    }[],
  ) => {
    const checkin = changedBooking.checkin;
    const checkout = changedBooking.checkout;

    const dates = eachDayOfInterval({
      start: checkin,
      end: subDays(checkout, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      const rule = rules.find((r) => isSameDay(date, r.date));
      const priceRuleId = rule?.priceRuleId || null;
      const priceOverrideAmount = rule?.priceOverrideAmount || null;
      const priceAdjustmentPercent = rule?.priceAdjustmentPercent || null;

      const pr = priceRules?.rules.find(
        (r) => priceRuleId && r.id === priceRuleId,
      );
      const priceRule = pr?.entries.find(
        (e) => e.categoryId === changedBooking.category.id,
      );
      const priceRuleOverride = priceRule?.overrides.find(
        (o) =>
          o.adults === changedBooking.guestCount.adults &&
          o.children === changedBooking.guestCount.children &&
          o.teenagers === changedBooking.guestCount.teenagers &&
          o.infants === changedBooking.guestCount.infants,
      );

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || "new" + uniqueId(),
        price: price,
        start: format(date, "yyyy-MM-dd"),
        end: format(addDays(date, 1), "yyyy-MM-dd"),
        priceRuleId: priceRuleId,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setChangedBooking({
      ...changedBooking,
      slots: newSlots,
    });
  };

  const total = useMemo(() => {
    return changedBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [changedBooking]);

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("change-price-code")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">
                {t("change-price-code")}
              </h3>

              <RadioGroup
                className="mb-4"
                value={priceOption}
                onValueChange={(v) =>
                  handlePriceOptionChange(v as "all" | "remaining")
                }
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="all"
                    id="change-the-price-code-for-all-nights"
                  />
                  <Label htmlFor="change-the-price-code-for-all-nights">
                    {t("change-the-price-code-for-all-nights")}
                  </Label>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem
                    value="remaining"
                    id="change-the-price-code-for-all-remaining-nights"
                  />
                  <Label htmlFor="change-the-price-code-for-all-remaining-nights">
                    {t("change-the-price-code-for-all-remaining-nights")}
                  </Label>
                </div>
              </RadioGroup>

              <Label htmlFor="booking-change-price-code">
                {t("change-price-code-for-all-nights")}
              </Label>
              <Select
                value={
                  changedBooking.slots
                    .filter((slot) =>
                      isAfter(slot.start, subDays(changeFrom, 1)),
                    )
                    .every(
                      (s) =>
                        s.priceRuleId ===
                        changedBooking.slots[changedBooking.slots.length - 1]
                          ?.priceRuleId,
                    )
                    ? changedBooking.slots[changedBooking.slots.length - 1]
                        ?.priceRuleId || undefined
                    : undefined
                }
                onValueChange={(value) => {
                  updateState(
                    changedBooking.slots.map((s) => ({
                      date: s.start,
                      priceRuleId: isAfter(s.start, subDays(changeFrom, 1))
                        ? value
                        : s.priceRuleId,
                      priceAdjustmentPercent: isAfter(
                        s.start,
                        subDays(changeFrom, 1),
                      )
                        ? null
                        : s.priceAdjustmentPercent,
                      priceOverrideAmount: isAfter(
                        s.start,
                        subDays(changeFrom, 1),
                      )
                        ? null
                        : s.priceOverrideAmount,
                    })),
                  );
                }}
              >
                <SelectTrigger
                  className=" w-full"
                  id="booking-change-price-code"
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {priceRules?.rules
                    .filter((r) => r.active)
                    .map((r) => (
                      <SelectItem key={r.id} value={r.id} className=" text-xs">
                        {t(r.name)}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>

              <div className="mt-2">
                {changedBooking.slots
                  .filter((slot) => isAfter(slot.start, subDays(changeFrom, 1)))
                  .map((slot) => (
                    <div
                      key={slot.id}
                      className=" flex items-center justify-between border-t border-border-color py-2"
                    >
                      <p className=" text-sm font-bold">
                        {format(slot.start, "d MMMM")}
                      </p>
                      <div className=" w-1/2">
                        <Select
                          value={slot.priceRuleId || undefined}
                          onValueChange={(value) => {
                            updateState(
                              changedBooking.slots.map((s, j) => ({
                                date: s.start,
                                priceRuleId: isSameDay(slot.start, s.start)
                                  ? value || null
                                  : s.priceRuleId,
                                priceAdjustmentPercent: isAfter(
                                  slot.start,
                                  subDays(changeFrom, 1),
                                )
                                  ? null
                                  : s.priceAdjustmentPercent,
                                priceOverrideAmount: isAfter(
                                  slot.start,
                                  subDays(changeFrom, 1),
                                )
                                  ? null
                                  : s.priceOverrideAmount,
                              })),
                            );
                          }}
                        >
                          <SelectTrigger className=" w-full" id="price-code">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {priceRules?.rules
                              .filter((r) => r.active)
                              .map((r) => (
                                <SelectItem
                                  key={r.id}
                                  value={r.id}
                                  className=" text-xs"
                                >
                                  {t(r.name)}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() => {
                  onProceed(changedBooking);
                  onOpenChange(false);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default BookingChangePriceCodeDrawer;
