import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useGetAllocableBookables } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { format } from "date-fns";
import { GetBooking } from "../../../../../../api-contracts/reservations/index";
import { AssetType } from "../../../../../../api-contracts/assets/index";
import { AllocableBookable } from "../../../../../../api-contracts/bookables/allocable";

export const BookableSelectDialog: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  booking: GetBooking;
  onProceed: (bookable: AllocableBookable) => void;
}> = ({ open, onOpenChange, booking, onProceed }) => {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState<string>(booking.category.id);
  const [bookable, setBookable] = useState<AllocableBookable | undefined>(
    booking.bookable || undefined,
  );

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    isRefetching: categoriesRefetching,
  } = useGetCategories({
    variables: { categoryTypes: ["room", "area", "bed", "dormitory"] },
  });

  const {
    data: bookablesData,
    isLoading: bookablesLoading,
    isRefetching: bookablesRefetching,
  } = useGetAllocableBookables({
    variables: {
      categoryId,
      startDate: format(booking.checkin, "yyyy-MM-dd"),
      endDate: format(booking.checkout, "yyyy-MM-dd"),
    },
  });

  const handleProceed = () => {
    if (bookable) {
      onProceed(bookable);
      onOpenChange(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" block h-screen !max-h-full sm:!h-[310px] sm:!w-[500px] sm:!max-w-full">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("assign-unit")}
            </DialogTitle>

            <div className=" flex-grow">
              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("category")}
              </p>
              <Select value={categoryId} onValueChange={setCategoryId}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {categoriesData?.map((c) => (
                    <SelectItem key={c.id} value={c.id} className=" text-xs">
                      {c.short}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("unit")}
              </p>
              <Select
                value={bookable?.id}
                onValueChange={(value) =>
                  setBookable(
                    bookablesData?.bookables.find((b) => b.id === value),
                  )
                }
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {bookablesData?.bookables?.map((b) => (
                    <SelectItem key={b.id} value={b.id} className=" text-xs">
                      {b.name}
                    </SelectItem>
                  ))}
                  {bookablesData?.bookables.length === 0 && (
                    <p className="p-2 text-center text-sm font-normal text-secondary-text">
                      {t("no-units-available")}
                    </p>
                  )}
                </SelectContent>
              </Select>
            </div>

            <div className=" mt-6 flex justify-end space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={!bookable}
                onClick={handleProceed}
              >
                {t("assign")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
