import { AlertDialog } from "@primitives/alert-dialog";
import { FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { format } from "date-fns";
import { enGB, sv } from "date-fns/locale";

interface UndoNoShowProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onProceed: () => null;
  onOpenChange: React.Dispatch<SetStateAction<boolean>>;
  proceedBtnText: string;
  onDiscard?: () => void;
  booking: ReceptionBooking;
}

export const UndoNoShowDialog: FC<UndoNoShowProps> = ({
  isOpen = false,
  title = "Undo no-show",
  description = "Choose from which date you wish to check in",
  onProceed,
  onOpenChange,
  proceedBtnText,
  onDiscard,
  booking,
}) => {
  const { t, i18n } = useTranslation();
  const arrivalDate = new Date(booking.arrivalDate);
  const today = new Date();
  const locale = i18n.language === "sv-se" ? sv : enGB;

  const getNoShowDays = (arrivalDate: Date, today: Date) => {
    const noShowDays = [];
    let currentDate = new Date(arrivalDate);

    while (currentDate <= today) {
      noShowDays.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return noShowDays;
  };
  const noShowDays = getNoShowDays(arrivalDate, today);

  const [selectedDate, setSelectedDate] = useState<Date>(noShowDays[0]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  const handleProceed = () => {
    onProceed();
  };

  const formatNoShowDateLabel = (
    date: Date,
    index: number,
    totalDays: number,
  ) => {
    if (index === 0) {
      return `${format(date, "EEEE, d MMMM", { locale })} (${t("original-arrival-date")})`;
    }
    if (index === totalDays - 1) {
      return `${t("today")}, ${format(date, "d MMMM", { locale })}`;
    }
    return format(date, "EEEE, d MMMM", { locale });
  };

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
          <AlertDialog.Description>{description}</AlertDialog.Description>
        </AlertDialog.Header>
        <Select
          value={selectedDate.toISOString()}
          onValueChange={(value) => handleDateChange(new Date(value))}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {noShowDays.map((date, index) => (
              <SelectItem key={date.toISOString()} value={date.toISOString()}>
                {formatNoShowDateLabel(date, index, noShowDays.length)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          {onDiscard && (
            <AlertDialog.Cancel onClick={() => onDiscard()}>
              {t("discard")}
            </AlertDialog.Cancel>
          )}
          {/* If payment remains btn text should be "Go to payment"? */}
          <AlertDialog.Action onClick={handleProceed}>
            {proceedBtnText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
