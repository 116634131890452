import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import {
  differenceInCalendarDays,
  format,
  isSameDay,
  max,
  min,
} from "date-fns";
import { Info, Lock, LockOpen, Tag } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  BookingGuest,
  BookingState,
  GetBooking,
  GetReservation,
} from "../../../../../../api-contracts/reservations";
import { enGB, sv } from "date-fns/locale";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { assetTypeToRoute } from "@pages/settings/bookables";
import { useProfileContext } from "@context/profile-context";
import NotesSelect from "./notes-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { Checkbox } from "@primitives/checkbox";
import TitleSelect from "./title-select";
import { useDebounce } from "@hooks/use-debounce";
import { useGetGuests } from "@api/guests";
import { PopoverContent, PopoverRoot } from "@primitives/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { PopoverAnchor } from "@radix-ui/react-popover";
import DatePicker from "@primitives/date-picker";
import { Guest, GuestOverview } from "../../../../../../api-contracts/guests";
import DiscountDialog from "./discount-dialog";
import { ConfirmDialog } from "@components/confirm-dialog";
import DebitBookingDialog from "./debit-booking-dialog";
import CancelReasonDialog from "./cancel-reason-dialog";
import { useToast } from "@hooks/use-toast";
import { useCancelBooking } from "@api/reservations";
import BookingChangeOptionsDialog from "./booking-change-options-dialog";
import BookingChangeDrawer from "./booking-change-drawer";
import { BookableSelectDialog } from "./bookable-select-dialog";
import BookingChangeOptionsCheckedInDialog from "./booking-change-options-checkedin-dialog";
import BookingChangeAssetDrawer from "./booking-change-asset-drawer";
import BookingChangeDepartureDrawer from "./booking-change-departure-drawer";
import BookingChangePriceCodeDrawer from "./booking-change-price-code-drawer";
import { useGetPriceRules } from "@api/price-rules";
import BookingChangeGuestsDialog from "./booking-change-guests-dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import TagsSelect from "./tags-select";

export const bookingStateToColor = (state?: BookingState) => {
  switch (state) {
    case "planned":
      return "booking-tentative";
    case "cancelled":
      return "booking-cancelled";
    case "aborted":
      return "booking-aborted";
    case "checked-in":
      return "booking-checked-in";
    case "checked-out":
      return "booking-checked-out";
    case "no-show":
      return "status-error";
    default:
      return "status-disabled";
  }
};

interface Props {
  reservation: GetReservation;
  booking: GetBooking;
  isEditing: boolean;
  holder?: Guest;
  changesPending: boolean;
  onChange: (b: GetBooking, save?: boolean) => Promise<void>;
  onNewBooking: (b: GetBooking) => void;
  dismissChanges: () => void;
}

const ReservationBooking = ({
  reservation,
  booking,
  isEditing,
  holder,
  changesPending,
  onChange,
  onNewBooking,
  dismissChanges,
}: Props) => {
  const [t, i18n] = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedGuestIndices, setSelectedGuestIndices] = useState<number[]>(
    [],
  );
  const [numGuests, setNumGuests] = useState<{
    adults: number;
    teenagers: number;
    children: number;
    infants: number;
  }>({ adults: 0, children: 0, teenagers: 0, infants: 0 });

  const [showDiscountDialog, setShowDiscountDialog] = useState<boolean>(false);
  const [cancelBookingOpen, setCancelBookingOpen] = useState<boolean>(false);
  const [debitCancelOpen, setDebitCancelOpen] = useState<boolean>(false);
  const [debitOpen, setDebitOpen] = useState<boolean>(false);
  const [cancelReasonOpen, setCancelReasonOpen] = useState<boolean>(false);
  const [changeOptionsOpen, setChangeOptionsOpen] = useState<boolean>(false);
  const [changeOptionsCheckedInOpen, setChangeOptionsCheckedInOpen] =
    useState<boolean>(false);
  const [bookingChangeDrawerOpen, setBookingChangeDrawerOpen] = useState<
    "asset" | "price" | "dates" | "all"
  >();
  const [changeAssetOpen, setChangeAssetOpen] = useState<boolean>(false);
  const [changeDepartureOpen, setChangeDepartureOpen] =
    useState<boolean>(false);
  const [changePriceCodeOpen, setChangePriceCodeOpen] =
    useState<boolean>(false);
  const [changeGuestsOpen, setChangeGuestsOpen] = useState<boolean>(false);
  const [bookableSelectOpen, setBookableSelectOpen] = useState<boolean>(false);

  const { toast } = useToast();

  const cancelBooking = useCancelBooking();

  const { module } = useProfileContext();

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({});

  useEffect(() => {
    setNumGuests({
      adults: booking.guests.filter((g) => g.ageType === "adult").length,
      teenagers: booking.guests.filter((g) => g.ageType === "teenager").length,
      children: booking.guests.filter((g) => g.ageType === "child").length,
      infants: booking.guests.filter((g) => g.ageType === "infant").length,
    });
  }, [booking]);

  const handleSelectGuest = (index: number) => {
    if (selectedGuestIndices?.includes(index)) {
      setSelectedGuestIndices((old) => old?.filter((i) => i !== index));
    } else {
      setSelectedGuestIndices((old) => [...old, index]);
    }
  };

  const handleRemoveSelectedGuests = () => {
    onChange({
      ...booking,
      guests: booking.guests.filter(
        (_, i) => !selectedGuestIndices.includes(i),
      ),
    });
    setSelectedGuestIndices([]);
  };

  const handlePopulateHolder = (index: number) => {
    onChange({
      ...booking,
      guests: booking.guests.map((g, i) =>
        index === i ? { ...g, ...holder } : g,
      ),
    });
  };

  const handleToggleLocked = () => {
    if (booking.bookable) {
      onChange({
        ...booking,
        bookable: {
          ...booking.bookable,
          lockedToBookable: !booking.bookable.lockedToBookable,
        },
      });
    }
  };

  const columns: ColumnDef<BookingGuest>[] = [
    {
      cell: (row) =>
        t(row.row.original.ageType) +
        " " +
        (booking.guests
          .filter((g) => g.ageType === row.row.original.ageType)
          .findIndex((g) => g === row.row.original) +
          1),
      accessorKey: "ageType",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("guest")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "ageType",
    },
    {
      cell: (row) => {
        return (
          <Checkbox
            checked={Boolean(holder?.id && row.row.original.id === holder?.id)}
            disabled={
              !isEditing ||
              !holder?.id ||
              Boolean(
                holder?.id && booking.guests.find((g) => g.id === holder.id),
              )
            }
            onCheckedChange={(checked) => handlePopulateHolder(row.row.index)}
          />
        );
      },
      accessorFn: (row) => holder?.id && row.id === holder?.id,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("booker")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "holder",
    },
    {
      cell: (row) => {
        if (isEditing) {
          return (
            <div className=" min-w-[100px]">
              <TitleSelect
                value={row.row.original.title || undefined}
                onValueChange={(v) => {
                  booking.guests[row.row.index] = {
                    ...booking.guests[row.row.index],
                    title: v || null,
                  };
                  onChange({ ...booking, guests: [...booking.guests] });
                }}
              />
            </div>
          );
        } else {
          return row.row.original.title?.name;
        }
      },
      accessorKey: "title",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("title")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "title",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<string>(row.row.original.name ?? "");

        useEffect(() => {
          if (row.row.original.name !== value) {
            setValue(row.row.original.name ?? "");
          }
        }, [row.row.original.name]);

        const onBlur = () => {
          if (value !== booking.guests[row.row.index].name) {
            booking.guests[row.row.index] = {
              ...booking.guests[row.row.index],
              name: value,
            };
            onChange({ ...booking, guests: [...booking.guests] });
          }
        };

        if (isEditing) {
          return (
            <Input
              className=" min-w-[150px] "
              value={value || ""}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
            />
          );
        } else {
          return row.row.original.name;
        }
      },
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("first-name")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "name",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<string>(
          row.row.original.surname ?? "",
        );
        const debouncedValue = useDebounce(value, 500);
        const [focus, setFocus] = useState<boolean>(false);
        const [open, setOpen] = useState<boolean>();
        const [blockBlur, setBlockBlur] = useState<boolean>(false);

        useEffect(() => {
          if (row.row.original.surname !== value) {
            setValue(row.row.original.surname ?? "");
          }
        }, [row.row.original.surname]);

        const {
          data: guestsData,
          isLoading: guestsIsLoading,
          isRefetching: guestsIsRefetching,
        } = useGetGuests({
          variables: {
            limit: 20,
            page: 1,
            name: row.row.original.name || undefined,
            surname: debouncedValue,
            email: row.row.original.email || undefined,
            phone: row.row.original.phone || undefined,
          },
          enabled: focus && isEditing && debouncedValue.length > 2,
        });

        useEffect(() => {
          if (guestsData?.length && focus && isEditing) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }, [guestsData, focus, isEditing]);

        const onBlur = () => {
          if (!blockBlur && value !== booking.guests[row.row.index].surname) {
            booking.guests[row.row.index] = {
              ...booking.guests[row.row.index],
              surname: value,
            };
            onChange({ ...booking, guests: [...booking.guests] });
          }
          setFocus(false);
        };

        const handleGuestSelect = (g: GuestOverview) => {
          booking.guests[row.row.index] = {
            ...booking.guests[row.row.index],
            ...g,
          };
          onChange({ ...booking, guests: [...booking.guests] });
        };

        if (isEditing) {
          return (
            <PopoverRoot
              open={open && value.length > 2}
              onOpenChange={(o) => {
                setBlockBlur(false);
                setOpen(o);
              }}
            >
              <PopoverAnchor asChild>
                <Input
                  className=" min-w-[150px] "
                  value={value || ""}
                  onChange={(e) => setValue(e.target.value)}
                  onFocus={() => setFocus(true)}
                  onBlur={onBlur}
                />
              </PopoverAnchor>
              <PopoverContent
                align="start"
                avoidCollisions={false}
                className="p-0"
                onOpenAutoFocus={(e) => e.preventDefault()}
                onMouseEnter={() => setBlockBlur(true)}
                onMouseLeave={() => setBlockBlur(false)}
              >
                <div className=" rounded bg-primary-color px-3 py-2">
                  <p className=" text-sm font-bold text-white">
                    {t("matchings")}
                  </p>
                </div>
                <Command>
                  <CommandList>
                    <CommandEmpty>{t("no-guests-found")}</CommandEmpty>
                    <CommandGroup>
                      {guestsData?.map((g) => (
                        <CommandItem
                          key={g.id}
                          value={g.id}
                          onSelect={() => handleGuestSelect(g)}
                        >
                          <div>
                            <p className=" text-sm font-extrabold">
                              {g.name} {g.surname}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.phone}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.email}
                            </p>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </PopoverRoot>
          );
        } else {
          return row.row.original.surname;
        }
      },
      accessorKey: "surname",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("surname")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "surname",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<string | undefined>(
          row.row.original.email || undefined,
        );
        const debouncedValue = useDebounce(value, 500);
        const [focus, setFocus] = useState<boolean>(false);
        const [open, setOpen] = useState<boolean>();
        const [blockBlur, setBlockBlur] = useState<boolean>(false);

        useEffect(() => {
          if (row.row.original.email !== value) {
            setValue(row.row.original.email || undefined);
          }
        }, [row.row.original.email]);

        const {
          data: guestsData,
          isLoading: guestsIsLoading,
          isRefetching: guestsIsRefetching,
        } = useGetGuests({
          variables: {
            limit: 20,
            page: 1,
            name: row.row.original.name || undefined,
            surname: row.row.original.surname || undefined,
            phone: row.row.original.phone || undefined,
            email: debouncedValue,
          },
          enabled:
            focus &&
            isEditing &&
            debouncedValue !== undefined &&
            debouncedValue?.length > 2,
        });

        const onBlur = () => {
          if (!blockBlur && value !== booking.guests[row.row.index].email) {
            booking.guests[row.row.index] = {
              ...booking.guests[row.row.index],
              email: value || null,
            };
            onChange({ ...booking, guests: [...booking.guests] });
          }
          setFocus(false);
        };

        useEffect(() => {
          if (guestsData?.length && focus && isEditing) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }, [guestsData, focus, isEditing]);

        const handleGuestSelect = (g: GuestOverview) => {
          booking.guests[row.row.index] = {
            ...booking.guests[row.row.index],
            ...g,
          };
          onChange({ ...booking, guests: [...booking.guests] });
        };

        if (isEditing) {
          return (
            <PopoverRoot
              open={open && value !== undefined && value.length > 2}
              onOpenChange={(o) => {
                setBlockBlur(false);
                setOpen(o);
              }}
            >
              <PopoverAnchor asChild>
                <Input
                  className=" min-w-[150px] "
                  value={value || ""}
                  onChange={(e) => setValue(e.target.value)}
                  onFocus={() => setFocus(true)}
                  onBlur={onBlur}
                />
              </PopoverAnchor>
              <PopoverContent
                align="start"
                avoidCollisions={false}
                className="p-0"
                onOpenAutoFocus={(e) => e.preventDefault()}
                onMouseEnter={() => setBlockBlur(true)}
                onMouseLeave={() => setBlockBlur(false)}
              >
                <div className=" rounded bg-primary-color px-3 py-2">
                  <p className=" text-sm font-bold text-white">
                    {t("matchings")}
                  </p>
                </div>
                <Command>
                  <CommandList>
                    <CommandEmpty>{t("no-guests-found")}</CommandEmpty>
                    <CommandGroup>
                      {guestsData?.map((g) => (
                        <CommandItem
                          key={g.id}
                          value={g.id}
                          onSelect={() => handleGuestSelect(g)}
                        >
                          <div>
                            <p className=" text-sm font-extrabold">
                              {g.name} {g.surname}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.phone}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.email}
                            </p>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </PopoverRoot>
          );
        } else {
          return row.row.original.email;
        }
      },
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("email")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "email",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<string | undefined>(
          row.row.original.phone || undefined,
        );
        const debouncedValue = useDebounce(value, 500);
        const [focus, setFocus] = useState<boolean>(false);
        const [open, setOpen] = useState<boolean>();
        const [blockBlur, setBlockBlur] = useState<boolean>(false);

        useEffect(() => {
          if (row.row.original.phone !== value) {
            setValue(row.row.original.phone || undefined);
          }
        }, [row.row.original.phone]);

        const {
          data: guestsData,
          isLoading: guestsIsLoading,
          isRefetching: guestsIsRefetching,
        } = useGetGuests({
          variables: {
            limit: 20,
            page: 1,
            name: row.row.original.name || undefined,
            surname: row.row.original.surname || undefined,
            email: row.row.original.email || undefined,
            phone: debouncedValue,
          },
          enabled:
            focus &&
            isEditing &&
            debouncedValue !== undefined &&
            debouncedValue?.length > 2,
        });

        const onBlur = () => {
          if (!blockBlur && value !== booking.guests[row.row.index].phone) {
            booking.guests[row.row.index] = {
              ...booking.guests[row.row.index],
              phone: value || null,
            };
            onChange({ ...booking, guests: [...booking.guests] });
          }
          setFocus(false);
        };

        useEffect(() => {
          if (guestsData?.length && focus && isEditing) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }, [guestsData, focus, isEditing]);

        const handleGuestSelect = (g: GuestOverview) => {
          booking.guests[row.row.index] = {
            ...booking.guests[row.row.index],
            ...g,
          };
          onChange({ ...booking, guests: [...booking.guests] });
        };

        if (isEditing) {
          return (
            <PopoverRoot
              open={open && value !== undefined && value.length > 2}
              onOpenChange={(o) => {
                setBlockBlur(false);
                setOpen(o);
              }}
            >
              <PopoverAnchor asChild>
                <Input
                  className=" min-w-[150px] "
                  value={value || ""}
                  onChange={(e) => setValue(e.target.value)}
                  onFocus={() => setFocus(true)}
                  onBlur={onBlur}
                />
              </PopoverAnchor>
              <PopoverContent
                align="start"
                avoidCollisions={false}
                className="p-0"
                onOpenAutoFocus={(e) => e.preventDefault()}
                onMouseEnter={() => setBlockBlur(true)}
                onMouseLeave={() => setBlockBlur(false)}
              >
                <div className=" rounded bg-primary-color px-3 py-2">
                  <p className=" text-sm font-bold text-white">
                    {t("matchings")}
                  </p>
                </div>
                <Command>
                  <CommandList>
                    <CommandEmpty>{t("no-guests-found")}</CommandEmpty>
                    <CommandGroup>
                      {guestsData?.map((g) => (
                        <CommandItem
                          key={g.id}
                          value={g.id}
                          onSelect={() => handleGuestSelect(g)}
                        >
                          <div>
                            <p className=" text-sm font-extrabold">
                              {g.name} {g.surname}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.phone}
                            </p>
                            <p className=" text-xs font-normal text-secondary-text">
                              {g.email}
                            </p>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </PopoverRoot>
          );
        } else {
          return row.row.original.phone;
        }
      },
      accessorKey: "phone",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("phone")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "phone",
    },
    {
      cell: (row) => row.row.original.countryCode,
      accessorKey: "country",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("country")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "country",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<string | undefined>(
          row.row.original.passportNumber || undefined,
        );

        useEffect(() => {
          if (row.row.original.passportNumber !== value) {
            setValue(row.row.original.passportNumber || undefined);
          }
        }, [row.row.original.passportNumber]);

        const onBlur = () => {
          if (value !== booking.guests[row.row.index].passportNumber) {
            booking.guests[row.row.index] = {
              ...booking.guests[row.row.index],
              passportNumber: value || null,
            };
            onChange({ ...booking, guests: [...booking.guests] });
          }
        };

        if (isEditing) {
          return (
            <Input
              className=" min-w-[150px] "
              value={value || ""}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
            />
          );
        } else {
          return row.row.original.passportNumber;
        }
      },
      accessorKey: "passportNumber",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("passport-number")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "passportNumber",
    },
    {
      cell: (row) => {
        const [value, setValue] = useState<Date | undefined>(
          row.row.original.checkin
            ? new Date(row.row.original.checkin)
            : undefined,
        );

        useEffect(() => {
          if (
            (!value && row.row.original.checkin) ||
            (value &&
              row.row.original.checkin &&
              !isSameDay(value, row.row.original.checkin))
          ) {
            setValue(new Date(row.row.original.checkin));
          }
        }, [row.row.original.checkin]);

        if (isEditing) {
          const bookingCheckout = new Date(booking.checkout);
          const guestCheckout = row.row.original.checkout
            ? new Date(row.row.original.checkout)
            : new Date(bookingCheckout);
          bookingCheckout.setDate(bookingCheckout.getDate() - 1);
          guestCheckout.setDate(guestCheckout.getDate() - 1);
          const disabledAfter = min([bookingCheckout, guestCheckout]);
          const disabledBefore = new Date(booking.checkin);
          const daysDisabledMatcher = {
            after: disabledAfter,
            before: disabledBefore,
          };

          return (
            <div className=" min-w-[200px]">
              <DatePicker
                clearable={false}
                calendarProps={{
                  disabled: daysDisabledMatcher,
                }}
                value={value}
                onValueChange={(v) => {
                  booking.guests[row.row.index] = {
                    ...booking.guests[row.row.index],
                    checkin: v ? format(v, "yyyy-MM-dd") : undefined,
                  };
                  onChange({ ...booking, guests: [...booking.guests] });
                }}
              />
            </div>
          );
        } else {
          return (
            row.row.original.checkin &&
            format(row.row.original.checkin, "yyyy-MM-dd")
          );
        }
      },
      accessorKey: "checkin",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("arrival")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "checkin",
    },
    {
      cell: (row) => {
        if (isEditing) {
          const bookingCheckin = new Date(booking.checkin);
          const guestCheckin = row.row.original.checkin
            ? new Date(row.row.original.checkin)
            : new Date(bookingCheckin);
          bookingCheckin.setDate(bookingCheckin.getDate() + 1);
          guestCheckin.setDate(guestCheckin.getDate() + 1);
          const disabledBefore = max([bookingCheckin, guestCheckin]);
          const disabledAfter = new Date(booking.checkout);
          const daysDisabledMatcher = {
            after: disabledAfter,
            before: disabledBefore,
          };
          return (
            <div className=" min-w-[200px]">
              <DatePicker
                clearable={false}
                calendarProps={{
                  disabled: daysDisabledMatcher,
                }}
                value={
                  row.row.original.checkout
                    ? new Date(row.row.original.checkout)
                    : undefined
                }
                onValueChange={(v) => {
                  booking.guests[row.row.index] = {
                    ...booking.guests[row.row.index],
                    checkout: v ? format(v, "yyyy-MM-dd") : undefined,
                  };
                  onChange({ ...booking, guests: [...booking.guests] });
                }}
              />
            </div>
          );
        } else {
          return (
            row.row.original.checkout &&
            format(row.row.original.checkout, "yyyy-MM-dd")
          );
        }
      },
      accessorKey: "checkout",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("departure")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "checkout",
    },
    {
      cell: (row) => "TODO",
      accessorKey: "link",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("link-to-guest-portal")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "link",
    },
  ];

  if (isEditing) {
    columns.unshift({
      cell: (row) => (
        <Checkbox
          className="ml-2"
          checked={selectedGuestIndices.includes(row.row.index)}
          onCheckedChange={() => handleSelectGuest(row.row.index)}
        />
      ),
      accessorKey: "selected",
      header: () => {
        return (
          <Checkbox
            checked={selectedGuestIndices.length === booking.guests.length}
            onCheckedChange={() =>
              selectedGuestIndices.length === booking.guests.length
                ? setSelectedGuestIndices([])
                : setSelectedGuestIndices(booking.guests.map((_, i) => i))
            }
          />
        );
      },
      size: 1,
      id: "selected",
    });
  }

  const guestsTable = useReactTable({
    data: booking.guests,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const handleCancelBooking = async ({
    reason,
    note,
    sendConfirmation,
  }: {
    reason?: string;
    note?: string;
    sendConfirmation: boolean;
  }) => {
    try {
      await cancelBooking.mutateAsync({
        reservationId: reservation.id,
        bookingId: booking.id,
        reason,
        note,
        sendConfirmation,
      });
      onChange(
        {
          ...booking,
          state: {
            value: "cancelled",
            reason,
            note,
          },
        },
        true,
      );
      setCancelReasonOpen(false);
    } catch (err) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
  };

  const totalPrice = useMemo(() => {
    return booking.slots?.reduce((acc, slot) => acc + slot.price, 0) || 0;
  }, [booking]);

  const fullPrice = useMemo(() => {
    return booking.slots?.reduce((acc, slot) => acc + slot.fullPrice, 0) || 0;
  }, [booking]);

  const adjustedPrice = useMemo(() => {
    return (
      booking.slots?.reduce(
        (acc, slot) => acc + (slot.priceOverrideAmount || slot.fullPrice),
        0,
      ) || 0
    );
  }, [booking]);

  const priceAdjustmentPercent = useMemo(() => {
    return (
      (booking.slots?.reduce(
        (acc, slot) => acc + (slot.priceAdjustmentPercent || 0),
        0,
      ) || 0) / (booking.slots.length || 1)
    );
  }, [booking]);

  return (
    <>
      <div className=" mt-8 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4">
        <div className=" flex flex-nowrap justify-between">
          <div className=" flex flex-nowrap items-center space-x-2">
            <p className=" text-sm font-bold">
              {format(booking.checkin, "d MMMM", {
                locale: i18n.language === "sv-se" ? sv : enGB,
              })}{" "}
              -{" "}
              {format(booking.checkout, "d MMMM", {
                locale: i18n.language === "sv-se" ? sv : enGB,
              })}{" "}
              ({differenceInCalendarDays(booking.checkout, booking.checkin)}{" "}
              {t("nights-lower")})
            </p>
            <div className=" flex flex-nowrap items-center space-x-2 pl-8">
              <p className=" text-sm font-bold">{booking.category.name}</p>
              {booking.bookable?.id ? (
                <>
                  <Link
                    to={`/${module}/${assetTypeToRoute(booking.bookable.types[0])}/${booking.bookable.id}`}
                  >
                    <Button variant="secondary">{booking.bookable.name}</Button>
                  </Link>
                  <Button
                    variant={
                      booking.bookable.lockedToBookable
                        ? "primary"
                        : "secondary"
                    }
                    onClick={handleToggleLocked}
                    disabled={!isEditing}
                  >
                    {booking.bookable.lockedToBookable ? (
                      <Lock size={16} />
                    ) : (
                      <LockOpen size={16} />
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  variant="secondary"
                  disabled={!isEditing}
                  onClick={() => setBookableSelectOpen(true)}
                >
                  {t("assign-unit")}
                </Button>
              )}
            </div>
            <div
              className={`ml-8 flex items-center space-x-2 p-3 bg-${bookingStateToColor(booking.state.value)}-100`}
            >
              <p
                className={` text-xs font-extrabold text-${bookingStateToColor(booking.state.value)}`}
              >
                {t(booking.state.value)}
              </p>
              {(booking.state.note || booking.state.reason) && (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Info
                        size={18}
                        className={`text-${bookingStateToColor(booking.state.value)}`}
                      />
                    </TooltipTrigger>
                    <TooltipContent
                      className=" min-w-80 p-4 text-primary-text"
                      align="start"
                    >
                      {booking.state.reason && (
                        <>
                          <h4 className=" text-lg font-extrabold">
                            {t("reason")}
                          </h4>
                          <p className=" mb-4 text-sm font-normal text-secondary-text">
                            {t(booking.state.reason)}
                          </p>
                        </>
                      )}
                      {booking.state.note && (
                        <>
                          <h4 className=" text-lg font-extrabold">
                            {t("note")}
                          </h4>
                          <p className=" text-sm font-normal text-secondary-text">
                            {booking.state.note}
                          </p>
                        </>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>
          <div className=" flex flex-nowrap space-x-2">
            {["planned", "no-show"].includes(booking.state.value) && (
              <Button
                variant="destructive"
                disabled={!isEditing}
                onClick={() => setCancelBookingOpen(true)}
              >
                {t("cancel-the-booking")}
              </Button>
            )}
            <Button variant="secondary" onClick={() => setDebitOpen(true)}>
              {t("charge")}
            </Button>
            {!["cancelled", "aborted", "checked-out"].includes(
              booking.state.value,
            ) && (
              <Button
                onClick={() => setChangeOptionsOpen(true)}
                variant="primary"
                disabled={!isEditing}
              >
                {t("change")}
              </Button>
            )}
            {!["cancelled", "aborted", "checked-out"].includes(
              booking.state.value,
            ) && (
              <Button
                onClick={() => setChangeOptionsCheckedInOpen(true)}
                variant="primary"
                disabled={!isEditing}
              >
                {t("change-2")}
              </Button>
            )}
          </div>
        </div>

        <div className=" mt-4 flex flex-nowrap justify-between">
          <div className=" flex flex-nowrap overflow-x-auto">
            <div className=" mr-2 mt-2 flex min-h-[150px] min-w-[150px] flex-col justify-between rounded-lg bg-amber-500 p-2">
              <div className=" flex justify-between space-x-2">
                <div>
                  <p className=" text-sm font-bold">{t("total")}</p>
                  <p className=" text-xs font-normal">
                    {new Intl.NumberFormat(
                      i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                      { maximumFractionDigits: 2 },
                    ).format(totalPrice)}{" "}
                    SEK
                  </p>
                  {fullPrice !== adjustedPrice && (
                    <p className=" text-xs font-normal text-status-error">
                      ({adjustedPrice - fullPrice > 0 ? "+" : ""}
                      {new Intl.NumberFormat(
                        i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                        { maximumFractionDigits: 2 },
                      ).format(adjustedPrice - fullPrice)}{" "}
                      SEK)
                    </p>
                  )}
                  {priceAdjustmentPercent !== 0 && (
                    <p className=" text-xs font-normal text-status-error">
                      (-{" "}
                      {new Intl.NumberFormat(
                        i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                        { maximumFractionDigits: 2 },
                      ).format(priceAdjustmentPercent)}{" "}
                      %)
                    </p>
                  )}
                </div>
                <div className=" h-fit rounded-lg bg-primary-button-backplate p-2 opacity-50">
                  <Tag size={14} />
                </div>
              </div>
              <div>
                {isEditing && (
                  <Button
                    variant="secondary"
                    size="sm"
                    className=" truncate"
                    onClick={() => setShowDiscountDialog(true)}
                  >
                    <Tag size={14} className="mr-1" />
                    {t("change-price")}
                  </Button>
                )}
              </div>
            </div>

            {booking.slots?.map((slot) => (
              <div
                key={slot.id}
                className=" mr-2 mt-2 flex min-h-[150px] min-w-[150px] flex-col justify-between rounded-lg bg-primary-card-backplate p-2"
              >
                <div className=" flex justify-between space-x-2">
                  <div>
                    <p className=" text-sm font-bold">
                      {format(slot.start, "d MMMM")}
                    </p>
                    <p className=" text-xs font-normal">
                      {new Intl.NumberFormat(
                        i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                        { maximumFractionDigits: 2 },
                      ).format(slot.price)}{" "}
                      SEK
                    </p>
                  </div>
                  <div className=" h-fit rounded-lg bg-primary-button-backplate p-2 opacity-50">
                    <Tag size={14} />
                  </div>
                </div>

                <div>
                  <p className=" text-xs font-normal text-secondary-text">
                    {priceRules?.rules.find((r) => r.id === slot.priceRuleId)
                      ?.name || "X"}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className=" flex w-[250px] flex-col space-y-2 pl-2">
            <TagsSelect
              disabled={!isEditing}
              isEditing={isEditing}
              value={booking.tags}
              onValueChange={(v) => {
                onChange({ ...booking, tags: v });
              }}
            />
            <NotesSelect
              disabled={!isEditing}
              value={booking.notes}
              onValueChange={(v) => {
                onChange({ ...booking, notes: v });
              }}
              noteType="booking"
            />
            <Input placeholder={t("todo")} className="w-[250px]" />
          </div>
        </div>

        <div className=" mt-4">
          <h3 className=" text-sm font-bold">{t("guests")}</h3>
          <div className=" mt-2 rounded-lg bg-primary-card-backplate pt-4">
            {!isEditing && (
              <p className=" mx-4 text-sm font-bold">
                {[
                  numGuests.adults
                    ? `${numGuests.adults} ${t("adults")}`
                    : null,
                  numGuests.teenagers
                    ? `${numGuests.teenagers} ${t("teenagers")}`
                    : null,
                  numGuests.children
                    ? `${numGuests.children} ${t("children")}`
                    : null,
                  numGuests.infants
                    ? `${numGuests.infants} ${t("infants")}`
                    : null,
                ]
                  .filter((n) => n)
                  .join(", ")}
              </p>
            )}
            {isEditing && (
              <div className=" mx-4 mb-2 flex items-center space-x-2">
                <div className=" max-w-[180px]">
                  <Select
                    value={String(numGuests.adults)}
                    onValueChange={(v) => {
                      setNumGuests({ ...numGuests, adults: Number(v) });
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("adults")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        { length: booking.category.capacity.adults.max + 1 },
                        (v, k) => k,
                      )
                        .filter(
                          (v) => v >= booking.category.capacity.adults.min,
                        )
                        .map((v) => (
                          <SelectItem
                            key={v}
                            value={String(v)}
                            className=" text-xs"
                          >
                            {String(v)} {t("adults")}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className=" max-w-[180px]">
                  <Select
                    value={String(numGuests.teenagers)}
                    onValueChange={(v) => {
                      setNumGuests({ ...numGuests, teenagers: Number(v) });
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("teenagers")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        { length: booking.category.capacity.teenagers.max + 1 },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("teenagers")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className=" max-w-[180px]">
                  <Select
                    value={String(numGuests.children)}
                    onValueChange={(v) => {
                      setNumGuests({ ...numGuests, children: Number(v) });
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("children")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        { length: booking.category.capacity.children.max + 1 },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("children")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className=" max-w-[180px]">
                  <Select
                    value={String(numGuests.infants)}
                    onValueChange={(v) => {
                      setNumGuests({ ...numGuests, infants: Number(v) });
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("infants")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        { length: booking.category.capacity.infants.max + 1 },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("infants")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                {(numGuests.adults !== booking.guestCount.adults ||
                  numGuests.children !== booking.guestCount.children ||
                  numGuests.infants !== booking.guestCount.infants ||
                  numGuests.teenagers !== booking.guestCount.teenagers) && (
                  <Button onClick={() => setChangeGuestsOpen(true)}>
                    {t("update-guests")}
                  </Button>
                )}
              </div>
            )}

            <div className="pb-2">
              <Table>
                <TableHeader>
                  {guestsTable.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>

                <TableBody>
                  {guestsTable.getRowModel().rows?.length ? (
                    guestsTable.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className=" cursor-pointer text-primary-text"
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className={isEditing ? "p-2" : "p-4"}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center text-primary-text"
                      >
                        {t("no-guests-added")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>

            {selectedGuestIndices.length > 0 && (
              <div className=" border-t border-border-color p-4">
                <Button variant="primary" onClick={handleRemoveSelectedGuests}>
                  {t("remove-marked")} ({selectedGuestIndices.length})
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <DiscountDialog
        open={showDiscountDialog}
        onOpenChange={setShowDiscountDialog}
        booking={booking}
        onBookingChange={(booking) => onChange(booking)}
      />
      <ConfirmDialog
        proceedBtnText={t("cancel-booking")}
        title={t("cancel-booking")}
        description={t(
          "are-you-sure-you-wish-to-cancel-{{booker}}'s-booked-{{unit}}-with-arrival-{{arrival}},-{{nights}}-nights?",
          {
            booker: [holder?.title?.name, holder?.name, holder?.surname]
              .filter((n) => n)
              .join(" "),
            unit: booking.bookable?.name || t("not-assigned"),
            arrival: format(booking.checkin, "PPP", {
              locale: i18n.language === "sv-se" ? sv : enGB,
            }),
            nights: differenceInCalendarDays(booking.checkout, booking.checkin),
          },
        )}
        onOpenChange={setCancelBookingOpen}
        isOpen={cancelBookingOpen}
        onProceed={() => setDebitCancelOpen(true)}
      />
      <DebitBookingDialog
        reservation={reservation}
        booking={booking}
        open={debitCancelOpen}
        onOpenChange={setDebitCancelOpen}
        onProceed={() => setCancelReasonOpen(true)}
        proceedLabel={t("next")}
        changesPending={changesPending}
        onSave={async () => await onChange(booking, true)}
        dismissChanges={dismissChanges}
      />
      <DebitBookingDialog
        reservation={reservation}
        booking={booking}
        open={debitOpen}
        onOpenChange={setDebitOpen}
        onProceed={() => {}}
        proceedLabel={t("save")}
        changesPending={changesPending}
        onSave={async () => await onChange(booking, true)}
        dismissChanges={dismissChanges}
      />
      <CancelReasonDialog
        open={cancelReasonOpen}
        onOpenChange={setCancelReasonOpen}
        onProceed={handleCancelBooking}
      />
      <BookingChangeOptionsDialog
        open={changeOptionsOpen}
        onOpenChange={setChangeOptionsOpen}
        onProceed={(option) => setBookingChangeDrawerOpen(option)}
      />
      <BookingChangeOptionsCheckedInDialog
        open={changeOptionsCheckedInOpen}
        onOpenChange={setChangeOptionsCheckedInOpen}
        onProceed={(option) => {
          switch (option) {
            case "asset":
              setChangeAssetOpen(true);
              break;
            case "departure":
              setChangeDepartureOpen(true);
              break;
            case "price":
              setChangePriceCodeOpen(true);
              break;
          }
        }}
      />
      <BookingChangeDrawer
        open={!!bookingChangeDrawerOpen}
        availableOptions={bookingChangeDrawerOpen}
        onOpenChange={(open) =>
          setBookingChangeDrawerOpen(open ? bookingChangeDrawerOpen : undefined)
        }
        onProceed={(updatedBooking) =>
          onChange({
            ...updatedBooking,
          })
        }
        booking={booking}
      />
      <BookingChangeAssetDrawer
        open={!!changeAssetOpen}
        onOpenChange={(open) => setChangeAssetOpen(open)}
        onProceed={(updatedBooking, newBooking) => {
          onChange({
            ...updatedBooking,
          });
          onNewBooking(newBooking);
        }}
        booking={booking}
      />
      <BookingChangeDepartureDrawer
        open={!!changeDepartureOpen}
        onOpenChange={(open) => setChangeDepartureOpen(open)}
        onProceed={(updatedBooking) => {
          onChange({
            ...updatedBooking,
          });
        }}
        booking={booking}
      />
      <BookingChangePriceCodeDrawer
        open={!!changePriceCodeOpen}
        onOpenChange={(open) => setChangePriceCodeOpen(open)}
        onProceed={(updatedBooking) => {
          onChange({
            ...updatedBooking,
          });
        }}
        booking={booking}
      />
      <BookableSelectDialog
        open={bookableSelectOpen}
        onOpenChange={setBookableSelectOpen}
        onProceed={(bookable) =>
          onChange({
            ...booking,
            bookable: { ...bookable, lockedToBookable: false },
          })
        }
        booking={booking}
      />
      <BookingChangeGuestsDialog
        numGuests={numGuests}
        open={changeGuestsOpen}
        onOpenChange={setChangeGuestsOpen}
        onProceed={(booking) =>
          onChange({
            ...booking,
          })
        }
        booking={booking}
      />
    </>
  );
};

export default ReservationBooking;
