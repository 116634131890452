import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { ConfirmDialog } from "@components/confirm-dialog";
import { useTranslation } from "react-i18next";
import { AllocateRoomDialog } from "./allocate-room-dialog";
import { PatchBookingRequest } from "../../../../../../api-contracts/reservations";
import { UndoNoShowDialog } from "./undo-no-show-dialog";

interface CheckInDialogManagerProps {
  selectedBooking: {
    booking: ReceptionBooking | null;
    isOpen: boolean;
    dialogType:
      | "confirm"
      | "chooseBookable"
      | "checkInAssignedRoom"
      | "paymentRemaining"
      | "undoCheckIn"
      | "undoNoShow"
      | null;
  };
  onCheckIn: (bookingId: string) => void;
  onUndoCheckIn: (bookingId: string) => void;
  closeDialogs: () => void;
  onPatchBooking: (
    booking: ReceptionBooking,
    patchData: PatchBookingRequest,
  ) => void;
}

export const CheckInDialogManager = ({
  selectedBooking,
  onCheckIn,
  onUndoCheckIn,
  closeDialogs,
  onPatchBooking,
}: CheckInDialogManagerProps) => {
  const { t } = useTranslation();

  const { booking, isOpen, dialogType } = selectedBooking;

  if (!booking) return;
  return (
    <>
      {dialogType === "confirm" && booking && isOpen && (
        <ConfirmDialog
          title={t("confirm-check-in")}
          description={t(
            "are-you-sure-to-check-in-{{name}}-{{surname}}-in-{{unit}}?",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
              bookable: booking.bookable.name,
            },
          )}
          isOpen={isOpen}
          onProceed={() => {
            onCheckIn(booking.bookingId);
            closeDialogs();
          }}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-in")}
        />
      )}

      {dialogType === "undoCheckIn" && booking && isOpen && (
        <ConfirmDialog
          title={t("undo-check-in")}
          description={t(
            "are-you-sure-to-undo-check-in-for-{{name}}-{{surname}}",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
            },
          )}
          isOpen={isOpen}
          onProceed={() => {
            onUndoCheckIn(booking.bookingId);
            closeDialogs();
          }}
          onOpenChange={closeDialogs}
          proceedBtnText={t("undo-check-in")}
        />
      )}

      {dialogType === "paymentRemaining" && booking && isOpen && (
        <ConfirmDialog
          title={t("payment-remains")}
          description={t("payment-before-checking-description")}
          isOpen={isOpen}
          onProceed={closeDialogs}
          onOpenChange={closeDialogs}
          proceedBtnText={t("go-to-payment")}
        />
      )}

      {dialogType === "checkInAssignedRoom" && booking && isOpen && (
        <AllocateRoomDialog
          booking={booking}
          title={t("allocate-unit")}
          description={t("allocate-unit-for-{{name}}-{{surname}}", {
            name: booking.holder.name,
            surname: booking.holder.surname,
          })}
          isOpen={isOpen}
          onCheckIn={onCheckIn}
          onPatchBooking={onPatchBooking}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-in")}
        />
      )}

      {dialogType === "chooseBookable" && booking && isOpen && (
        <AllocateRoomDialog
          booking={booking}
          title={t("allocate-unit")}
          description={t("allocate-unit-for-{{name}}-{{surname}}", {
            name: booking.holder.name,
            surname: booking.holder.surname,
          })}
          isOpen={isOpen}
          onCheckIn={undefined}
          onPatchBooking={onPatchBooking}
          onOpenChange={closeDialogs}
          proceedBtnText={t("assign")}
        />
      )}
      {dialogType === "undoNoShow" && booking && isOpen && (
        <UndoNoShowDialog
          booking={booking}
          title={t("check-in")}
          description={t(
            "choose-from-what-date-to-check-in-{{name}}-{{surname}}",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
            },
          )}
          isOpen={isOpen}
          onProceed={() => null}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-in")}
        />
      )}
    </>
  );
};
