interface Guests {
  adults: number;
  teenagers: number;
  children: number;
  infants: number;
}

export const formatGuests = (
  guests: Guests,
  language: string = "en",
): string => {
  const acceptedLanguage = language === "sv-se" ? "sv-se" : "en";

  const labels = {
    "sv-se": { adults: "V", teenagers: "T", children: "B", infants: "S" },
    en: { adults: "A", teenagers: "T", children: "C", infants: "I" },
  };

  const parts: string[] = [];

  if (guests.adults > 0) {
    parts.push(`${guests.adults}(${labels[acceptedLanguage].adults})`);
  }

  if (guests.children > 0) {
    parts.push(`${guests.children}(${labels[acceptedLanguage].children})`);
  }

  if (guests.teenagers > 0) {
    parts.push(`${guests.teenagers}(${labels[acceptedLanguage].teenagers})`);
  }

  if (guests.infants > 0) {
    parts.push(`${guests.infants}(${labels[acceptedLanguage].infants})`);
  }

  return parts.join(" ");
};

export const formatPrice = (price: string) => {
  const svString = (
    <p>
      {price} <span className="text-secondary-text">SEK</span>
    </p>
  );
  return svString;
};
