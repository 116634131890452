import { PriceRulesSheet } from "@pages/booking/price-rules/components/price-rules-sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { useTranslation } from "react-i18next";
import { useCreatePriceRule } from "@api/price-rules.ts";
import { useToast } from "@hooks/use-toast";

export const AddPriceRules = () => {
  const {
    sharedState: { actionType, bar, priceRules },
    sharedFunctions: { setActionType, setPriceRules },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();
  const { toast } = useToast();

  const { mutate } = useCreatePriceRule();

  const onSubmit = (event: any) => {
    event.preventDefault();
    bar &&
      mutate(bar, {
        onSuccess(rule) {
          setActionType(PriceRuleActionType.DEFAULT);
          setPriceRules((prev) => {
            return { ...prev, rules: [...prev.rules, rule] };
          });
          toast({
            variant: "success",
            title: t("saved-succesfully", { name: rule.name }),
            className: "text-status-success",
          });
        },
        onError(error) {
          toast({
            variant: "destructive",
            title:
              t("request-failed-with") +
              ": " +
              t(decodeURIComponent((error as any)?.message || t("no-message"))),
            className: "text-status-error",
          });
        },
      });
  };

  return (
    <PriceRulesSheet
      open={actionType === PriceRuleActionType.ADD}
      title={t("price-rules-new-bar")}
      onSubmit={onSubmit}
      display={PriceRuleActionType.ADD}
    />
  );
};
