import {
  GridItem,
  GridRow,
} from "@pages/booking/price-rules/components/pricing-grid.tsx";
import { Input } from "@primitives/input.tsx";
import { usePriceRulesPageContext } from "@pages/booking/price-rules/price-rules-context.tsx";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { Collapsible } from "@primitives/collapsible";
import {
  CreatePriceRuleRequest,
  PriceRuleEntry,
} from "../../../../../../api-contracts/price-rules";
import { cn } from "@utils/css.ts";
import { CopyPlus } from "lucide-react";

export const PriceRulesEditGrid = () => {
  const {
    sharedState: { expanded, priceRules, bar },
    sharedFunctions: { setBar },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();
  const [selectedOverrides, setSelectedOverrides] = useState<
    { id: string; cat: string }[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const handleInputChange = (value: string) => {
    const parsedNumber = value.replace(/\D/g, "");
    setBar((prevBar) => {
      let updatedBar = { ...prevBar };

      selectedCategories.forEach((cat) => {
        const categoryEntry = updatedBar.entries.find(
          (entry) => entry.categoryId === cat,
        );
        updatedBar = handleEntry(updatedBar, cat, parsedNumber, categoryEntry);
      });
      // Update overrides
      selectedOverrides.forEach((it) => {
        const categoryEntry = updatedBar.entries.find(
          (entry) => entry.categoryId === it.cat,
        );
        updatedBar = handleOverride(
          updatedBar,
          it.id,
          it.cat,
          categoryEntry,
          parsedNumber,
        );
      });

      // Update categories

      return updatedBar;
    });
  };

  const handleEntry = (
    prevBar: CreatePriceRuleRequest,
    catId: string,
    value: string | number,
    categoryEntry: PriceRuleEntry | undefined,
  ) => {
    if (categoryEntry) {
      return {
        ...prevBar,
        entries: prevBar.entries.map((entry) =>
          entry.categoryId === catId
            ? { ...entry, price: Number(value) }
            : entry,
        ),
      };
    }
    return {
      ...prevBar,
      entries: [
        ...prevBar.entries,
        {
          categoryId: catId,
          price: Number(value),
          overrides: [],
        },
      ],
    };
  };

  const handleOverride = (
    prevBar: CreatePriceRuleRequest,
    oId: string,
    catId: string,
    categoryEntry: PriceRuleEntry | undefined,
    value: string | number,
  ) => {
    const overrideFound = categoryEntry?.overrides?.find(
      ({ overrideId }) => overrideId === oId,
    );
    const updatedOverrides = categoryEntry?.overrides?.map((override) =>
      override.overrideId === oId
        ? { ...override, price: Number(value) }
        : override,
    );

    if (categoryEntry) {
      return {
        ...prevBar,
        entries: prevBar.entries.map((entry) =>
          entry.categoryId === catId
            ? {
                ...entry,
                overrides:
                  overrideFound && updatedOverrides
                    ? updatedOverrides
                    : [
                        ...(entry.overrides ?? []),
                        {
                          overrideId: oId,
                          price: Number(value),
                        },
                      ],
              }
            : entry,
        ),
      };
    }

    return {
      ...prevBar,
      entries: [
        ...prevBar.entries,
        {
          categoryId: catId,
          price: null,
          overrides: [{ overrideId: oId, price: Number(value) }],
        },
      ],
    };
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    catId: string,
    oId?: string,
  ) => {
    if (e.key === "Tab") {
      if (!oId) {
        setSelectedCategories([catId]);
        setSelectedOverrides([]);
      }
      if (oId) {
        setSelectedOverrides([{ cat: catId, id: oId }]);
        setSelectedCategories([]);
      }
    }
  };

  const getBarCategoryValue = (bar: CreatePriceRuleRequest, cId: string) => {
    const found = bar?.entries.find(({ categoryId }) => categoryId === cId);
    const hasOverrides = found?.overrides && found.overrides.length > 0;

    return {
      hasOverrides: hasOverrides,
      price: found?.price?.toString() ?? "",
    };
  };
  const getBarOverrideValue = (catId: string, oId: string) => {
    return (
      bar?.entries
        .find(({ categoryId }) => categoryId === catId)
        ?.overrides?.find(({ overrideId }) => overrideId === oId)
        ?.price.toString() ?? ""
    );
  };

  const handlePaste = async () => {
    const pasted = await navigator.clipboard.readText().then((r) => r);
    handleInputChange(pasted);
  };

  const onSelect = (
    e: React.MouseEvent<HTMLInputElement>,
    catId: string,
    oId?: string,
  ) => {
    if (!oId) {
      if (e.shiftKey) {
        e.preventDefault();
        e.currentTarget.focus();
        if (!selectedCategories.includes(catId)) {
          setSelectedCategories((prev) => [...prev, catId]);
        }
      } else {
        e.currentTarget.focus();
        setSelectedCategories([catId]);
        setSelectedOverrides([]);
      }
    }

    if (oId) {
      if (e.shiftKey) {
        e.preventDefault();
        e.currentTarget.focus();
        if (!selectedOverrides.some((item) => item.id === oId)) {
          setSelectedOverrides((prev) => [...prev, { id: oId, cat: catId }]);
        }
      } else {
        e.currentTarget.focus();
        setSelectedOverrides([{ id: oId, cat: catId }]);
        setSelectedCategories([]);
      }
    }
  };

  return (
    <>
      <GridRow className="auto-cols-[12rem]">
        <GridItem className="text-secondary-text">
          {t("price-rules-new-bar")}
        </GridItem>
        <GridItem className="text-secondary-text">{t("copy")}</GridItem>
      </GridRow>

      {priceRules.categories.map((c, rowIdx) => (
        <div key={`bt-${c.id}-row-${rowIdx}`}>
          <GridRow className="auto-cols-[12rem]">
            <GridItem>
              <Input
                onKeyUp={(e) => handleKeyDown(e, c.id)}
                className={cn(
                  selectedCategories.includes(c.id) &&
                    "border-2 border-gray-400",
                )}
                onPaste={() => handlePaste()}
                id={c.id}
                name={"entry"}
                required={!getBarCategoryValue(bar, c.id).hasOverrides}
                onMouseDown={(e) => onSelect(e, c.id)}
                value={getBarCategoryValue(bar, c.id).price}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              ></Input>
            </GridItem>
            <GridItem>
              <CopyPlus
                className="cursor-pointer"
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    getBarCategoryValue(bar, c.id).price.toString(),
                  );
                }}
              />
            </GridItem>
          </GridRow>

          <Collapsible.Root open={expanded.includes(c.id)}>
            <Collapsible.Content>
              {c.overrides.map((o, i) => (
                <GridRow
                  key={`bt-${o.id}-row-${i}`}
                  className="auto-cols-[12rem]"
                >
                  <GridItem className="flex justify-end gap-2">
                    <Input
                      className={cn(
                        selectedOverrides.map(({ id }) => id).includes(o.id) &&
                          "border-2 border-gray-400",
                      )}
                      onPaste={() => handlePaste()}
                      name="overrides"
                      id={`row-${rowIdx}-${i}`}
                      required={
                        getBarCategoryValue(bar, c.id).hasOverrides ?? false
                      }
                      value={getBarOverrideValue(c.id, o.id)}
                      onMouseDown={(e) => onSelect(e, c.id, o.id)}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onKeyUp={(e) => handleKeyDown(e, c.id, o.id)}
                      onChange={(e) => handleInputChange(e.target.value)}
                    />
                  </GridItem>
                  <GridItem>
                    <CopyPlus
                      className="cursor-pointer"
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          getBarOverrideValue(c.id, o.id),
                        );
                      }}
                    />
                  </GridItem>
                </GridRow>
              ))}
            </Collapsible.Content>
          </Collapsible.Root>
        </div>
      ))}
    </>
  );
};
